import { useTheme } from '@mui/material/styles';

export const SRSSchoolIcon = () => {
	const theme = useTheme();
	var colorFill = theme.palette.primary.dark;

	return (
		<svg width="61" height="55" viewBox="0 0 61 46" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path fill-rule="evenodd" clip-rule="evenodd" d="M 34.089 12.689 C 34.089 14.657 32.493 16.253 30.525 16.253 C 28.556 16.253 26.96 14.657 26.96 12.689 C 26.96 10.72 28.556 9.124 30.525 9.124 C 32.493 9.124 34.089 10.72 34.089 12.689 Z M 31.953 11.126 C 32.161 11.259 32.221 11.534 32.088 11.741 L 30.258 14.592 L 28.679 13.445 C 28.48 13.301 28.435 13.022 28.58 12.823 C 28.724 12.624 29.003 12.58 29.202 12.724 L 30.018 13.316 L 31.338 11.26 C 31.471 11.053 31.746 10.993 31.953 11.126 Z" fill={colorFill}/>
			<path fill-rule="evenodd" clip-rule="evenodd" d="M 31.416 1.105 C 31.416 0.613 31.017 0.214 30.525 0.214 C 30.033 0.214 29.634 0.613 29.634 1.105 L 29.634 4.167 L 18.047 11.189 C 18.042 11.192 18.036 11.196 18.031 11.199 C 18.027 11.201 18.024 11.203 18.021 11.205 L 15.36 12.818 C 14.939 13.073 14.805 13.621 15.06 14.042 C 15.315 14.463 15.863 14.597 16.284 14.342 L 17.604 13.542 L 17.604 25.917 L 14.473 27.865 C 14.465 27.87 14.456 27.875 14.448 27.88 L 10.004 30.645 C 9.587 30.905 9.459 31.455 9.719 31.872 C 9.979 32.29 10.528 32.418 10.946 32.158 L 14.04 30.233 L 14.04 42.986 C 14.04 43.97 14.837 44.768 15.822 44.768 L 25.624 44.768 C 26.608 44.768 27.406 43.97 27.406 42.986 L 27.406 32.293 L 33.644 32.293 L 33.644 42.986 C 33.644 43.97 34.441 44.768 35.426 44.768 L 45.228 44.768 C 46.212 44.768 47.01 43.97 47.01 42.986 L 47.01 30.235 L 50.103 32.159 C 50.52 32.419 51.07 32.292 51.33 31.874 C 51.59 31.456 51.462 30.906 51.044 30.646 L 46.601 27.882 C 46.593 27.876 46.585 27.871 46.576 27.866 L 43.445 25.918 L 43.445 13.542 L 44.766 14.342 C 45.187 14.597 45.735 14.463 45.99 14.042 C 46.245 13.621 46.11 13.073 45.69 12.818 L 43.029 11.205 C 43.02 11.2 43.011 11.194 43.002 11.189 L 31.416 4.167 L 31.416 1.105 Z M 19.386 12.462 L 30.525 5.711 L 41.663 12.462 L 41.663 24.809 L 31.466 18.463 C 30.89 18.104 30.16 18.104 29.583 18.463 L 19.386 24.808 L 19.386 12.462 Z M 30.525 19.976 L 42.072 27.162 C 42.08 27.167 42.088 27.172 42.097 27.177 L 45.228 29.126 L 45.228 42.986 L 35.426 42.986 L 35.426 32.293 C 35.426 31.308 34.628 30.511 33.644 30.511 L 27.406 30.511 C 26.422 30.511 25.624 31.308 25.624 32.293 L 25.624 42.986 L 15.822 42.986 L 15.822 29.124 L 18.953 27.176 C 18.961 27.171 18.969 27.166 18.978 27.161 L 30.525 19.976 Z" fill={colorFill}/>
			<path d="M 0 27.837 C 0 27.521 0.11 27.23 0.293 27.001 L 5.305 19.873 C 5.792 19.372 6.238 19.372 6.638 19.372 C 6.653 19.372 6.668 19.372 6.683 19.372 L 14.703 19.372 C 15.441 19.372 16.04 19.97 16.04 20.708 L 16.04 23.382 C 16.04 23.845 15.803 24.254 15.445 24.494 L 9.599 28.948 C 9.315 29.174 8.869 29.174 8.522 29.173 C 8.504 29.173 8.484 29.174 8.465 29.174 L 1.666 29.174 L 1.594 29.174 C 1.244 29.175 0.876 29.176 0.569 28.932 C 0.225 28.69 0 28.29 0 27.837 Z" fill={colorFill}/>
			<path d="M 60.714 27.001 C 60.898 27.23 61.008 27.521 61.008 27.837 C 61.008 28.29 60.783 28.69 60.438 28.932 C 60.132 29.176 59.764 29.175 59.414 29.174 L 59.342 29.174 L 52.542 29.174 C 52.523 29.174 52.504 29.173 52.485 29.173 C 52.139 29.174 51.693 29.174 51.409 28.948 L 45.563 24.494 C 45.204 24.254 44.968 23.845 44.968 23.382 L 44.968 20.708 C 44.968 19.97 45.567 19.372 46.305 19.372 L 54.325 19.372 C 54.34 19.372 54.354 19.372 54.369 19.372 C 54.77 19.372 55.216 19.372 55.702 19.873 L 60.714 27.001 Z" fill={colorFill}/>
			<path d="M 58.376 43.877 C 58.376 44.615 57.778 45.214 57.04 45.214 L 49.911 45.214 C 49.173 45.214 48.574 44.615 48.574 43.877 L 48.574 36.748 C 48.574 36.01 49.173 35.412 49.911 35.412 C 50.806 35.412 51.491 34.966 52.041 34.258 C 52.602 33.535 52.931 32.637 53.051 32.052 C 53.165 31.429 53.71 30.956 54.366 30.956 L 57.04 30.956 C 57.374 30.956 57.68 31.079 57.914 31.282 C 57.957 31.319 57.998 31.359 58.036 31.402 C 58.247 31.638 58.376 31.95 58.376 32.293 L 58.376 43.877 Z" fill={colorFill}/>
			<path d="M 2.673 43.877 C 2.673 44.615 3.272 45.214 4.01 45.214 L 11.139 45.214 C 11.877 45.214 12.475 44.615 12.475 43.877 L 12.475 36.748 C 12.475 36.01 11.877 35.412 11.139 35.412 C 10.244 35.412 9.558 34.966 9.009 34.258 C 8.448 33.535 8.119 32.637 7.998 32.052 C 7.885 31.429 7.339 30.956 6.683 30.956 L 4.01 30.956 C 3.675 30.956 3.37 31.079 3.135 31.282 C 3.092 31.319 3.052 31.359 3.014 31.402 C 2.802 31.638 2.673 31.95 2.673 32.293 L 2.673 43.877 Z" fill={colorFill}/>
		</svg>
	);
};
