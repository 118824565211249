import { ColorLog } from "./color-log.util";

export enum BrowserStorageType {
  LOCAL = "local",
  SESSION = "session",
  COOKIE = "cookie",
  NONE = "none",
  ALL = "all",
}

export declare enum BrowserCacheLocation {
  LocalStorage = "local-storage",
  SessionStorage = "session-storage",
  MemoryStorage = "memory-storage",
}

function convertToBoolean(input: string): boolean | undefined {
  try {
    return JSON.parse(input.toLowerCase());
  } catch (e) {
    return undefined;
  }
}

/**
 * Browser Storage Service
 * Provides methods to get, set, remove, clear local, session or cookie storage items.
 */
export class BrowserStorageService {
  private colorLog: ColorLog;

  constructor() {
    if (!this.colorLog) {
      this.colorLog = new ColorLog();
    }
  }

  public verbose: boolean = convertToBoolean(
    process.env.REACT_APP_USER_CONTEXT_LOG_ENABLED
  );

  /**
   * Set item in given storage type
   * @param type
   * @param key
   * @param value
   */
  setItem(type: BrowserStorageType, key: string, value: any, expirationDays: number = 7, path: string = '/') {
    var message = `setItem(): Storage set for key [${key}]...`;
    try {
      switch (type) {
        case BrowserStorageType.LOCAL:
          localStorage.setItem(key, JSON.stringify(value));
          this.storageLog(type, message);
          break;
        case BrowserStorageType.SESSION:
          sessionStorage.setItem(key, JSON.stringify(value));
          this.storageLog(type, message);
          break;
        case BrowserStorageType.COOKIE:
          const date = new Date();
          date.setTime(date.getTime() + expirationDays * 24 * 60 * 60 * 1000);
          const expires = `expires=${date.toUTCString()}`;
          document.cookie = `${key}=${value}; ${expires}; path=${path};`;
          break;
        default:
          this.storageLog(
            type,
            `setItem(): Storage not implemented for ${type} currently.`,
            true
          );
          break;
      }
    } catch (error) {
      this.storageLog(type, `setItem(): ${error}`, true);
    }
  }

  /**
   * Get item from given storage type
   * @param type
   * @param key
   * @returns
   */
  getItem(type: BrowserStorageType, key: string): any {
    var message = `getItem(): Storage retreived for key [${key}]...`;
    var storageValue: any;

    try {
      switch (type) {
        case BrowserStorageType.LOCAL:
          storageValue = JSON.parse(localStorage.getItem(key));
          break;

        case BrowserStorageType.SESSION:
          storageValue = JSON.parse(sessionStorage.getItem(key));
          break;

        case BrowserStorageType.COOKIE:
          const cookieString = document.cookie;
          const cookies = cookieString.split(';').map(cookie => cookie.trim());
        
          for (const cookie of cookies) {
            const [cookieKey, cookieValue] = cookie.split('=');
        
            if (cookieKey === key) {
              storageValue = cookieValue;
            }
          }
          if (storageValue == undefined || storageValue == '') {
            storageValue = null;
          }
          break;

        default:
          this.storageLog(
            type,
            `getItem(): Storage not implemented for ${type} currently.`,
            true
          );
          break;
      }
    } catch (error) {
      this.storageLog(type, `getItem(): ${error}`, true);
    }

    // Logging
    if (storageValue) {
      this.storageLog(
        type,
        `${message}\n${JSON.stringify(storageValue, null, "\t")}`
      );
    } else {
      this.storageLog(type, `${message}\nItem not found.`);
    }

    return storageValue;
  }

  /**
   * Remove item for given storage type
   * @param type
   * @param key
   */
  removeItem(type: BrowserStorageType, key: string, path: string = '/') {
    var message = `removeItem(): Storage removed for key [${key}]...`;

    try {
      switch (type) {
        case BrowserStorageType.LOCAL:
          localStorage.removeItem(key);
          this.storageLog(type, message);
          break;

        case BrowserStorageType.SESSION:
          sessionStorage.removeItem(key);
          this.storageLog(type, message);
          break;

        case BrowserStorageType.COOKIE:
          document.cookie = `${key}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=${path};`;
          break;

        default:
          this.storageLog(
            type,
            `removeItem(): Storage not implemented for ${type} currently.`,
            true
          );
          break;
      }
    } catch (error) {
      this.storageLog(type, `removeItem(): ${error}`, true);
    }
  }

  private storageLog(
    type: BrowserStorageType,
    message: string,
    isError: boolean = false
  ) {
    var service = "BrowserStorageService";
    var serviceType = type.toUpperCase();
    var logMessage = `[${serviceType}]: ${message}`;

    if (this.verbose) {
      this.colorLog.log(
        logMessage,
        isError ? "error" : "info",
        "BROWSER_STORAGE"
      );
    }
  }
  /**
   * Clear storage for give storage type
   * @param type
   */
  public clear(type: BrowserStorageType) {
    try {
      var message = "clear(): Storage cleared...";

      switch (type) {
        case BrowserStorageType.ALL:
          sessionStorage.clear();
          this.storageLog(BrowserStorageType.SESSION, message);

          localStorage.clear();
          this.storageLog(BrowserStorageType.LOCAL, message);
          break;

        case BrowserStorageType.LOCAL:
          localStorage.clear();
          this.storageLog(type, message);
          break;

        case BrowserStorageType.SESSION:
          sessionStorage.clear();
          this.storageLog(type, message);
          break;

        case BrowserStorageType.COOKIE:
          const cookies = document.cookie.split(';');

          for (const cookie of cookies) {
            const [cookieKey] = cookie.split('=');
            this.removeItem(BrowserStorageType.COOKIE, cookieKey);
          }
          break;

        default:
          this.storageLog(
            type,
            `clear(): Storage not implemented for ${type} currently.`,
            true
          );
          break;
      }
    } catch (error) {
      this.storageLog(type, `clear(): ${error}`, true);
    }
  }
}
