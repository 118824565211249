import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'selectedSchool',
	initialState: {
		value: null,
	},
	reducers: {
		updateSchoolSelection: (state, action) => {
			state.value = action.payload;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateSchoolSelection } = slice.actions;

export default slice.reducer;
