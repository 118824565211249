import { useEffect, type FC, type ReactNode } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'src/store';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';
import { appLogPrefixes } from 'src/config/app-config';

interface AuthGuardProps {
	children: ReactNode;
}

/**
 * 
 * @param props 
 * @returns 
 */
const StartupGuard: FC<AuthGuardProps> = (props) => {
	const { children } = props; 

	const location = useLocation();
	const appStartupState = useSelector((state) => state.global.appStartup.state);
	const navigate = useNavigate();

	useEffect(() => {
		if (appStartupState !== 'Complete') {
			useLog("Startup Gaurd Activated", ColorEnum.WARNING, appLogPrefixes.guard)
			navigate('/', {replace:true}); 
		}  
	}, [appStartupState]);

	return <>{children}</>; 
};

export default StartupGuard;
