import _ from 'lodash';
import * as yup from 'yup';

// reference links
// https://blog.shahednasser.com/how-to-create-and-validate-forms-in-react-using-formik-and-yup/
// https://stackoverflow.com/questions/61118132/how-to-validate-each-character-in-firstname-with-yup
// https://stackoverflow.com/questions/20690499/concrete-javascript-regular-expression-for-accented-characters-diacritics
// https://stackoverflow.com/a/74342078
// https://github.com/jquense/yup/issues/927
// https://stackoverflow.com/a/4685515 (for regex 10 digits only)
// https://technotrampoline.com/articles/validating-an-object-or-array-of-similarly-shaped-objects-with-yup/ (Validating an object or array of similarly-shaped objects with Yup)

const NameAlphabetsOnly = (value) =>
	/^[a-zA-ZàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçčšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆČŠŽ∂ð ,.'-]{2,40}$/.test(
		value
	);

const TenDigitsOnly = (value) => /^\(\d{3}\)\s\d{3}-\d{4}/g.test(value);

const PhoneExtensionDigitsOnly = (val) => {
	const value = val || '';
	if (`${value}`.trim() !== '') {
		return /^\d{0,5}$/.test(value); // not more than five digits
	}
	return true;
};

const FIELD_VALIDATION_RELATED = {
	PERSON_NAME: {
		INVALID_MSG: 'Invalid name. Numbers and special characters are not allowed. Minimum required is 2 letters.',
		RULE_NAME: 'PersonName',
	},
	PHONE: {
		INVALID_MSG: 'Invalid phone number. Accepts format (123) 456-7890.',
		REQUIRED_MSG: 'Phone Number is required',
		RULE_NAME: 'TenDigitPhoneNumber',
	},
	PHONE_EXTENSION: {
		INVALID_MSG: 'Invalid extension number. Accepts not more than five digits.',
		RULE_NAME: 'PhoneExtensionNumber',
	},
};

// schema declaration..
export const designatedContactSchema = yup.object().shape({
	firstName: yup
		.string()
		.required('First Name is required')
		.test(
			FIELD_VALIDATION_RELATED.PERSON_NAME.RULE_NAME,
			FIELD_VALIDATION_RELATED.PERSON_NAME.INVALID_MSG,
			NameAlphabetsOnly
		),
	lastName: yup
		.string()
		.required('Last Name is required')
		.test(
			FIELD_VALIDATION_RELATED.PERSON_NAME.RULE_NAME,
			FIELD_VALIDATION_RELATED.PERSON_NAME.INVALID_MSG,
			NameAlphabetsOnly
		),
	cohortsList: yup.array(), // commented as cohort is not required to be selected for a designated contact .min(1, 'Please select at least one option').required('At least one cohort is required'),
	email: yup.string().email('Invalid email format').required('Email is required'),
	confirmEmail: yup
		.string()
		.email('Invalid email format')
		.when('email', (email, field) => {
			if (email) {
				return field
					.required('Confirm Email is required')
					.oneOf([yup.ref('email')], 'Email and Confirm Email do not match.');
			}
		}),
	phone: yup
		.string()
		.required(FIELD_VALIDATION_RELATED.PHONE.REQUIRED_MSG)
		.test(FIELD_VALIDATION_RELATED.PHONE.RULE_NAME, FIELD_VALIDATION_RELATED.PHONE.INVALID_MSG, TenDigitsOnly)
		.nullable(),
	phoneExtension: yup
		.string()
		.test(
			FIELD_VALIDATION_RELATED.PHONE_EXTENSION.RULE_NAME,
			FIELD_VALIDATION_RELATED.PHONE_EXTENSION.INVALID_MSG,
			PhoneExtensionDigitsOnly
		),
});

export const designatedContactSaveSchema = yup.object().shape({
	firstName: yup.string(),
	lastName: yup.string(),
	cohortsList: yup.array(),
	email: yup.string(),
	confirmEmail: yup.string().when('email', (email, field) => {
		if (email) {
			return field.oneOf([yup.ref('email')], 'Email and Confirm Email do not match.');
		}
	}),
	phone: yup.string().nullable(),
	phoneExtension: yup.string(),
});

export const infoStepFormSchema = yup.object().shape({
	additionalEmail: yup.string().nullable().email('Invalid email format'),
	designatedContacts: yup.array().min(1, 'At least one contact is required').of(designatedContactSchema),
	memo: yup.string().nullable().max(15000, 'Maximum 15000 characters allowed'),
	cohortGroupsAssignment: yup
		.string()
		.test(
			'cohortsassignment',
			'All available Cohorts must be assigned to at least one designated contact',
			async function (value, context) {
				// Custom validation logic goes here
				let AreAllCohortsCoverted = true;
				const dataObject = context.parent;
				const cohortGroups = dataObject.applicableCohortGroupIds;
				cohortGroups?.forEach((cohortGroup) => {
					var cohortdesignatecontact = _.find(dataObject.designatedContacts, (designatedContact) =>
						_.includes(designatedContact.cohortsList, cohortGroup)
					);
					if (cohortdesignatecontact == undefined) {
						AreAllCohortsCoverted = false;
						return AreAllCohortsCoverted;
					}
				});
				return AreAllCohortsCoverted;
			}
		),
});

export const infoStepFormSaveSchema = yup.object().shape({
	additionalEmail: yup.string(),
	designatedContacts: yup.array().of(designatedContactSaveSchema),
	memo: yup.string(),
});

// form initialization..
export const infoStepFormModel = {
	additionalEmail: '',
	designatedContacts: [
		{
			firstName: '',
			lastName: '',
			cohortsList: [],
			email: '',
			confirmEmail: '',
			phone: '',
			phoneExtension: '',
		},
	],
};
