import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'userAppCurrentStates',
	initialState: {
		schoolsFacilitiesSchoolYear: '',
	},
	reducers: {
		updateUserAppCurrentStates: (state, action) => {
			state.schoolsFacilitiesSchoolYear = action.payload.schoolsFacilitiesSchoolYear;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateUserAppCurrentStates } = slice.actions;

export default slice.reducer;
