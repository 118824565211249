import { useIsAuthenticated, useMsal } from "@azure/msal-react"; 
import { InteractionStatus } from "@azure/msal-browser";
import SignInButton, { SignInButtonProps } from "./sign-in-button";
import SignOutButton from "./sign-out-button";
import { FC } from "react";

export interface SignInSignOutButtonProps extends SignInButtonProps{}


const SignInSignOutButton: FC<SignInSignOutButtonProps> = (props) => {

    const {isSidebar} = props;
    const { inProgress } = useMsal();
    const isAuthenticated = useIsAuthenticated();

    if (isAuthenticated) {
        return <SignOutButton />;
    } else if (inProgress !== InteractionStatus.Startup && inProgress !== InteractionStatus.HandleRedirect) {
        // inProgress check prevents sign-in button from being displayed briefly after returning from a redirect sign-in. Processing the server response takes a render cycle or two
        return <SignInButton isSidebar={isSidebar} />;
    } else {
        return null;
    }
}

export default SignInSignOutButton;