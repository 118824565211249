import yup from './yupUtil';

import {
	validateZeroEnrollmentReason,
	validateToBeLessThanOrEqualToFieldValue,
	validateToBeExactlyEqualToFieldValue,
	validateVaccineRangeToFieldValue
} from './commonFormValidation';

// schema declaration..
export const firstGradeStepFormSchema = yup.object().shape({
	studentMissingCount: validateVaccineRangeToFieldValue('sumMissingVaccines', 5, true),
	sumMissingVaccines: yup.number(),
	sumStudentsEnteredCount: validateToBeExactlyEqualToFieldValue('enrolledStudentCount'),
	enrolledStudentCount: yup.number().required('Enrollment count is required').integer('Only whole numbers can be entered'),
	zeroEnrollmentReason: validateZeroEnrollmentReason('enrolledStudentCount'),
	reqVaccineDosesCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	permMedicalExamCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	otherIEPServicesCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	otherIndependentStudyCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	otherHomeBasedPrivateSchoolCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	condMissingCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	tempMedicalExempCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	mmrCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	polioCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	dtapCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	hepBCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	overDueCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	varicellaCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	metadata: yup.object().shape({
		submittedDate: yup.string(),
		revisedDate: yup.string()
	}),
});

// form initialization..
export const firstGradeStepFormModel = {
	enrolledStudentCount: '',
	zeroEnrollmentReason: '',
	reqVaccineDosesCount: 0,
	permMedicalExamCount: 0,
	otherIEPServicesCount: 0,
	polioCount: 0,
	dtapCount: 0,
	condMissingCount: 0,
	mmrCount: 0,
	tempMedicalExempCount: 0,
	otherIndependentStudyCount: 0,
	otherHomeBasedPrivateSchoolCount: 0,
	hepBCount: 0,
	overDueCount: 0,
	varicellaCount: 0,
	totalStudentEnrolled: 0,
	sumStudentsEnteredCount: 0,
	studentMissingCount: 0,
	sumMissingVaccines: 0,
	metadata: {
		submittedDate: "",
		revisedDate: ""
	}
};
