// Generated with util/create-slice.js
import React from 'react';

import {
  createSlice,
  PayloadAction,
  createEntityAdapter,
  EntityState,
  EntityAdapter,
} from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk, RootState } from '../../../store';

export interface ReportingDashboard {
  status: string | undefined; 
}

// interface GlobalApp {
//     isLoaded?: boolean;
//     backDropProps: DynamicBackdropProps | null;
//   }

//   interface GlobalAppState extends GlobalApp {
//   }

//   const initialState: GlobalAppState = {
//     isLoaded: false,
//     backDropProps: { title: '', visible: false },
//   };

export interface ReportingDashboardState extends ReportingDashboard {
  allItemsLoaded: boolean | null;
  isLoading: boolean;
  isUpdating: boolean;
  statusMessage: string | undefined;
  error: any;
}

export const initialState: ReportingDashboardState = {
  status: undefined, 
  allItemsLoaded: false,
  isLoading: false,
  isUpdating: false,
  statusMessage: undefined,
  error: null,
};
 
const slice = createSlice({
  name: 'ReportingDashboard',
  initialState,
  reducers: {
    getReportingDashboards(state: ReportingDashboardState) { 
      console.log(`Fetching ReportingDashboard state`);
      state = { ...state, statusMessage: 'fetching' };
      return state;
    },
    getReportingDashboardsSuccess(state: ReportingDashboardState, action: PayloadAction<any[]>) {
      state = {
        ...state,
        isLoading: false,
        error: null,
        statusMessage: 'success',
      }; 
      console.log(`State for ReportingDashboards adapter success`); 
      return state;
    },
    getReportingDashboardsFailure(state: ReportingDashboardState, action: PayloadAction<string>) {
      console.log(
        `Error State for adapter ${JSON.stringify(action.payload, null, '	')}`
      );
      state = {
        ...state,
        isLoading: false,
        error: action.payload,
        statusMessage: 'failure',
      };
      return state;
    },
  },
});
 

// Entity Actions
export const { actions } = slice;

// Entity Reducer
export const reducer = slice.reducer;

export const selectReportingDashboardsLoaded = (state: { ReportingDashboards: { allItemsLoaded: any; }; }) => state.ReportingDashboards.allItemsLoaded;

export default slice;
