import { AppThunk, RootState } from 'src/store';
import { actions } from './school-cohort.reducer';

import {
	sRSFeatureActions,
	sRSFeatureRequests,
	DashboardApiFactory,
	SchoolsDetailApiFactory,
	SchoolInformationCohortViewModel,
} from '@the-mcorp/elevenfiftynine-srs-lib';
import { hooks } from '@the-mcorp/core-ui-lib';
import { apiUtils } from 'src/utils/api.utils';
import { STEP_STATUSES, SRS_APP_CONSTANTS } from 'src/constants';
import { displayBackdrop, hideBackdrop } from '../global';
import { ca } from 'date-fns/locale';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';
import { appLogPrefixes } from 'src/config/app-config';

/**
 *
 * @param schoolCode
 * @param debug
 * @returns
 */
export const saveSchoolCohort =
	(
		completeReportData: any,
		currentFormData,
		activeStep,
		isSubmit,
		user,
		debug: boolean = false
	): AppThunk =>
	async (dispatch) => {
		let activeStepDisplayName: string = '';

		switch (activeStep) {
			case SRS_APP_CONSTANTS.childCare.type:
				activeStepDisplayName = SRS_APP_CONSTANTS.childCare.displayName;
				break;
			case SRS_APP_CONSTANTS.kindergarten.type:
				activeStepDisplayName = SRS_APP_CONSTANTS.kindergarten.displayName;
				break;
			case SRS_APP_CONSTANTS.firstGrade.type:
				activeStepDisplayName = SRS_APP_CONSTANTS.firstGrade.displayName;
				break;
			case SRS_APP_CONSTANTS.seventhGrade.type:
				activeStepDisplayName = SRS_APP_CONSTANTS.seventhGrade.displayName;
				break;
			case SRS_APP_CONSTANTS.seventhGrade.type:
				activeStepDisplayName = SRS_APP_CONSTANTS.seventhGrade.displayName;
				break;
		}

		try {
			const schoolRequests = sRSFeatureRequests.schoolsDetail;
			var calls = [];
			calls.push(
				dispatch(
					schoolRequests.fetchUpdate_SchoolInformationCohort_Update<RootState>(
						SchoolsDetailApiFactory,
						{
							requestBody: {
								id: completeReportData.id,
								entity: {
									id: completeReportData.id,
									createdBy: completeReportData.createdBy,
									createdDate: completeReportData.createdDate,
									updatedDate: completeReportData.updatedDate,
									schoolCode: completeReportData.schoolCode,
									schoolInformationId: completeReportData.schoolInformationId,									
									academicYear: completeReportData.academicYear,
									cohort: completeReportData.cohort,
									condMissingCount: completeReportData.condMissingCount,
									cohortId: completeReportData.id,
									condMissingVaricelleCount: completeReportData.condMissingVaricelleCount,
									dtapCount: completeReportData.dtapCount,
									enrolledStudentCount: completeReportData.enrolledStudentCount,
									facilityType: completeReportData.facilityType,
									hepBCount: completeReportData.hepBCount,
									hibCount: completeReportData.hibCount,
									homeBasedTdapCount: completeReportData.homeBasedTdapCount,
									homeBasedVaricelleCount: completeReportData.homeBasedVaricelleCount,
									iepTdapCount: completeReportData.iepTdapCount,
									iepVaricelleCount: completeReportData.iepVaricelleCount,
									indepStudyTdapCount: completeReportData.indepStudyTdapCount,
									indepStudyVaricelleCount: completeReportData.indepStudyVaricelleCount,
									mmrCount: completeReportData.mmrCount,
									otherHomeBasedPrivateSchoolCount: completeReportData.otherHomeBasedPrivateSchoolCount,
									otherIEPServicesCount: completeReportData.otherIEPServicesCount,
									otherIndependentStudyCount: completeReportData.otherIndependentStudyCount,
									overDueCount: completeReportData.overDueCount,
									permMedicalExamCount: completeReportData.permMedicalExamCount,
									permMedicalTdapCount: completeReportData.permMedicalTdapCount,
									permMedicalVaricelleCount: completeReportData.permMedicalVaricelleCount,
									polioCount: completeReportData.polioCount,
									reqMetTdapCount: completeReportData.reqMetTdapCount,
									reqMetVaricelleCount: completeReportData.reqMetVaricelleCount,
									tempMedicalExempCount: completeReportData.tempMedicalExempCount,
									reqNotMetTdapCount: completeReportData.reqNotMetTdapCount,
									reqNotMetVaricelleCount: completeReportData.reqNotMetVaricelleCount,
									reqVaccineDosesCount: completeReportData.reqVaccineDosesCount,
									zeroEnrollmentReason: completeReportData.zeroEnrollmentReason,
									tempMedicalExempTdapCount: completeReportData.tempMedicalExempTdapCount,
									tempMedicalExempVaricelleCount: completeReportData.tempMedicalExempVaricelleCount,
									varicellaCount: completeReportData.varicellaCount,
									updatedBy: user.username,
									status: isSubmit ? STEP_STATUSES.COMPLETED : STEP_STATUSES.IN_PROGRESS,
								},
							},
						},
						undefined,
						'',
						true
					)
				)
			);

			dispatch(actions.saveSchoolCohort());
			dispatch(displayBackdrop(`${isSubmit ? 'Submitting' : 'Saving'} ${activeStepDisplayName} Data`, 'load'));
			try {
				const res = await Promise.all(calls)
				.then(async function(serviceErrors) {

					if(serviceErrors && serviceErrors.length > 0){
						useLog(`Service Errors: ${ JSON.stringify(serviceErrors, null, '\t')}`,ColorEnum.ERROR, appLogPrefixes.services);

						serviceErrors.forEach((valueOfResult) => {
							const resultType:string = valueOfResult.type; 
							if(resultType && resultType.toLowerCase().includes('fault')){  
								throw new Error('Service Error'); 
							}  
						});
					} 

					if (isSubmit) {
						await dispatch(
							schoolRequests.fetchGetAll_SchoolInformationCohort_SchoolInformationCohortBySchoolCode<RootState>(
								SchoolsDetailApiFactory,
								{
									requestBody: { entity: { schoolCode: currentFormData.school.code } },
								},
								undefined,
								'',
								true
							)
						);
					}

					dispatch(actions.saveSchoolCohortSuccess([]));
				})
				.catch((err) => { 
					throw err;  
				});
				 
			} catch (err) { 
				throw err;  
			}
		} catch (err) {
			dispatch(hideBackdrop());
			useLog(`Error saving School Cohort -> ${err}`, ColorEnum.ERROR, appLogPrefixes.services); 
			dispatch(actions.saveSchoolCohortFailure(err));
		}
	};
