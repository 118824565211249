import type { Direction, Theme } from '@mui/material';
import { createTheme as createMuiTheme, responsiveFontSizes } from '@mui/material/styles';
import { baseThemeOptions } from './base-theme-options';
import { darkThemeOptions } from './dark-theme-options';
import { lightThemeOptions } from './light-theme-options';

interface Neutral {
  100: string;
  200: string;
  300: string;
  400: string;
  500: string;
  600: string;
  700: string;
  800: string;
  900: string;
  111: string;
}

declare module '@mui/material/styles' {
	interface Palette {
		neutral?: Neutral;
		chart_secondary?: string;
	}

	interface userGreeting {
		text: string;
		background: string;
	}

	interface PaletteOptions {
		neutral?: Neutral;
		highlight?: string;
		emphasis?: string;
		chart_secondary?: string;
		accent?: string;
		userGreeting?: userGreeting;
	}

	interface PaletteColor {
		accent?: string;
	}

	interface TypeText {
		main?: string;
    contrastText?: string;
	}

  interface TypeBackground {
    highlight?: string;
  }
}

interface ThemeConfig {
  direction?: Direction;
  responsiveFontSizes?: boolean;
  mode: 'light' | 'dark';
}

export const createTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    baseThemeOptions,
    config.mode === 'dark' ? darkThemeOptions : lightThemeOptions,
    {
      direction: config.direction
    }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

export const createCustomTheme = (config: ThemeConfig): Theme => {
  let theme = createMuiTheme(
    baseThemeOptions,
    config.mode === 'dark' ? darkThemeOptions : lightThemeOptions,
    {
      direction: config.direction
    }
  );

  if (config.responsiveFontSizes) {
    theme = responsiveFontSizes(theme);
  }

  return theme;
};

// export const createCustomTheme = (config: ThemeConfig = {}): Theme => {
//   let themeOptions = themesOptions[config.theme];

//   if (!themeOptions) {
//     console.warn(new Error(`The theme ${config.theme} is not valid`));
//     themeOptions = themesOptions[THEMES.LIGHT];
//   }

//   let theme = createTheme(
//     merge(
//       {},
//       baseOptions,
//       themeOptions,
//       {
//         ...(
//           config.roundedCorners && {
//             shape: {
//               borderRadius: 16
//             }
//           }
//         )
//       },
//       {
//         direction: config.direction
//       }
//     )
//   );

//   if (config.responsiveFontSizes) {
//     theme = responsiveFontSizes(theme);
//   }

//   return theme;
// };

