// components/SuccessSnackbar.js or whatever you wanna call it
// import { useDispatch, useSelector } from "react-redux";
import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import { useDispatch, useSelector } from 'src/store';
import { clearSnackbar } from 'src/store/slices/global/global-app.slice';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import Slide, { SlideProps } from '@mui/material/Slide';
import { useTheme } from '@mui/material/styles';

export default function GlobalSnackbar() {
	const dispatch = useDispatch();

	const theme = useTheme();

	const { snackbarMessage, snackbarSeverity, snackbarOpen, autoHideDuration, snackbarOrigin } = useSelector(
		(state) => state.global.snackbarProps
	);

	function handleClose() {
		dispatch(clearSnackbar());
	}

	/**
	 *
	 * @param props
	 * @returns
	 */
	function SlideTransition(props: SlideProps) {
		return <Slide {...props} direction='up' />;
	}

	/**
	 *
	 */
	const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(props, ref) {
		return <MuiAlert elevation={6} ref={ref} variant='filled' {...props} />;
	});

	return (
		<Snackbar
			anchorOrigin={{
				vertical: snackbarOrigin ? snackbarOrigin.vertical : 'bottom',
				horizontal: snackbarOrigin ? snackbarOrigin.horizontal : 'right',
			}}
			open={snackbarOpen}
			autoHideDuration={autoHideDuration}
			onClose={handleClose}
			message={snackbarMessage}
			TransitionComponent={SlideTransition}
			// TransitionProps={{ easing: { enter: 'easeIn', exit: 'sharp' } }}
			key={snackbarOrigin ? snackbarOrigin.vertical + snackbarOrigin.horizontal : 'bottomright'}
			// action={[
			//   <IconButton
			//     key="close"
			//     aria-label="close"
			//     color="inherit"
			//     onClick={handleClose}
			//   >
			//     <Icon>close</Icon>
			//   </IconButton>
			// ]}
		>
			<Alert onClose={handleClose} severity={snackbarSeverity} sx={{ width: '100%' }}>
				{snackbarMessage}
			</Alert>
		</Snackbar>
	);
}
