// Generated with util/create-slice.js
import { reducer } from './user-school-assignment.reducer';
import * as effects from './user-school-assignment.effects';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { sRSFeatureEntityAdapters } from '@the-mcorp/elevenfiftynine-srs-lib';

export { reducer, effects };

const addUserSchoolBatchMessageSelector = createSelector(
	[(state: RootState) => state.userSchoolAssignmentBatch],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

/**
 * Export all selectors
 */
export const addUserSchoolBatchSelectors = {
	dataMessage: {
		addUserSchoolBatchMessageSelector,
	},
	data: {},
};
