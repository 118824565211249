import { useRef } from 'react';
import type { FC } from 'react';
import { Button, ButtonBase, Stack, Tooltip, Typography } from '@mui/material';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import { useNavigate } from 'react-router-dom';
import Badge from '@mui/icons-material/Badge';
import Notifications from '@mui/icons-material/Notifications';

export interface ExternaLinkIconsProps {}

const ExternaLinkIcons: FC<ExternaLinkIconsProps> = (props) => {
	const anchorRef = useRef<HTMLButtonElement | null>(null);
	const externalLink = `${process.env.REACT_APP_FRONTEND_CAIR_HUB_URL}${process.env.REACT_APP_FRONTEND_CAIR_HUB_URL_REDIRECT_ROUTE}`;
	const iconColor = 'secondary';
	const navigate = useNavigate();

	const handleNavigate = (): void => {
		navigate(externalLink, { relative: 'path', replace: true });
	};

	return (
		<>
			<Tooltip title={`CAIR Hub Applications`}>
				<Button
					href={externalLink}
					target='_self'
					rel='noreferrer'
					size='small'
					endIcon={<AutoAwesomeMosaicIcon color={iconColor} sx={{ height: 30, width: 30, margin: '0 0.75rem' }} />}>
					<Typography sx={{ fontWeight: '500' }} color='textSecondary'>
						CAIR Hub
					</Typography>
				</Button>
			</Tooltip>
		</>
	);
};

export default ExternaLinkIcons;
