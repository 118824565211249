import { ThemeOptions, alpha, backdropClasses, paperClasses } from '@mui/material';
import '@fontsource/poppins';
import '@fontsource/poppins/500.css'; // Specify weight
import '@fontsource/poppins/600.css'; // Specify weight
import { borderColor, palette } from '@mui/system';

const divider = '#A1B3D0';

export const baseThemeOptions: ThemeOptions = {
	breakpoints: {
		values: {
			xs: 0,
			sm: 600,
			md: 1000,
			lg: 1315,
			xl: 1920,
		},
	},
	components: {
		MuiAvatar: {
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 600,
					letterSpacing: 0,
				},
			},
		},
		MuiButton: {
			defaultProps: {
				disableElevation: true,
			},
			styleOverrides: {
				root: {
					textTransform: 'none',
				},
				sizeSmall: {
					padding: '6px 16px',
				},
				sizeMedium: {
					padding: '8px 20px',
				},
				sizeLarge: {
					padding: '11px 24px',
				},
				textSizeSmall: {
					padding: '7px 12px',
				},
				textSizeMedium: {
					padding: '9px 16px',
				},
				textSizeLarge: {
					padding: '12px 16px',
				},
			},
		},
		MuiButtonBase: {
			defaultProps: {
				disableRipple: true,
			},
		},
		MuiCard: {
			styleOverrides: {
				root: {
					[`&.${paperClasses.elevation1}`]: {
						// boxShadow: '0px 5px 22px rgba(0, 0, 0, 0.04), 0px 0px 0px 0.5px rgba(0, 0, 0, 0.03)',
						borderColor: divider,
					},
				},
			},
		},
		MuiCardActions: {
			styleOverrides: {
				root: {
					padding: '16px 24px',
				},
			},
		},
		MuiCardContent: {
			styleOverrides: {
				root: {
					padding: '6px',
					'&:last-child': {
						paddingBottom: '6px',
					},
				},
			},
		},
		// MuiCardContent: {
		//   styleOverrides: {
		//     root: {
		//       padding: '32px 24px',
		//       '&:last-child': {
		//         paddingBottom: '32px 0px'
		//       }
		//     }
		//   }
		// },
		MuiCardHeader: {
			defaultProps: {
				titleTypographyProps: {
					variant: 'h6',
				},
				subheaderTypographyProps: {
					variant: 'body2',
				},
			},
			styleOverrides: {
				root: {
					padding: '24px 24px 16px',
				},
			},
		},
		MuiCheckbox: {
			defaultProps: {
				color: 'primary',
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					fontWeight: 500,
					padding: '0 .3rem',
				},
			},
		},
		MuiCssBaseline: {
			styleOverrides: {
				'*': {
					boxSizing: 'border-box',
				},
				html: {
					MozOsxFontSmoothing: 'grayscale',
					WebkitFontSmoothing: 'antialiased',
					display: 'flex',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
				},
				body: {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					minHeight: '100%',
					width: '100%',
				},
				'#__next': {
					display: 'flex',
					flex: '1 1 auto',
					flexDirection: 'column',
					height: '100%',
					width: '100%',
				},
				'#nprogress': {
					pointerEvents: 'none',
				},
				'#nprogress .bar': {
					backgroundColor: '#5048E5',
					height: 3,
					left: 0,
					position: 'fixed',
					top: 0,
					width: '100%',
					zIndex: 2000,
				},
			},
		},
		MuiIconButton: {
			styleOverrides: {
				root: {
					borderRadius: 8,
					padding: 8,
				},
				sizeSmall: {
					padding: 4,
				},
			},
		},
		MuiLinearProgress: {
			styleOverrides: {
				root: {
					borderRadius: 8,
					overflow: 'hidden',
				},
			},
		},
		MuiLink: {
			defaultProps: {
				underline: 'none',
			},
		},
		MuiListItemIcon: {
			styleOverrides: {
				root: {
					marginRight: '16px',
					'&.MuiListItemIcon-root': {
						minWidth: 'unset',
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				input: {
					fontWeight: 500,
					backgroundColor: 'white',
					borderRadius: '.5rem',
				},
			},
		},
		MuiPaper: {
			styleOverrides: {
				root: {
					backgroundImage: 'none',
				},
			},
		},
		MuiPopover: {
			defaultProps: {
				elevation: 16,
			},
		},
		MuiRadio: {
			defaultProps: {
				color: 'primary',
			},
		},
		MuiSwitch: {
			defaultProps: {
				color: 'primary',
			},
		},
		MuiTab: {
			styleOverrides: {
				root: {
					fontSize: 14,
					fontWeight: 500,
					lineHeight: 1.71,
					minWidth: '4.0rem',
					paddingLeft: 0,
					paddingRight: 0,
					textTransform: 'uppercase',
					'& + &': {
						marginLeft: 24,
					},
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					padding: '15px 16px',
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					borderBottom: 'none',
					'& .MuiTableCell-root': {
						borderBottom: 'none',
						fontSize: '12px',
						fontWeight: 600,
						lineHeight: 1,
						letterSpacing: 0.5,
						textTransform: 'uppercase',
					},
					'& .MuiTableCell-paddingCheckbox': {
						paddingTop: 4,
						paddingBottom: 4,
					},
				},
			},
		},
	},
	direction: 'ltr',
	shape: {
		borderRadius: 8,
	},

  typography: {
    fontSize: 14,
    fontFamily: '"Poppins", "Inter"',

    button: {
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
      fontWeight: 400,
      lineHeight: 1.5,
    },
    body2: {
      fontSize: "0.875rem",
      fontWeight: 400,
      lineHeight: 1.57,
    },

    subtitle1: {
      fontSize: "1rem",
      fontWeight: 700,
      lineHeight: 1,
    },
    subtitle2: {
      fontSize: "0.875rem",
      fontWeight: 500,
      lineHeight: 1.57,
    },
    overline: {
      fontSize: "0.75rem",
      fontWeight: 600,
      letterSpacing: "0.5px",
      lineHeight: 2.5,
      textTransform: "uppercase",
    },
    caption: {
      fontSize: "0.75rem",
      fontWeight: 400,
      lineHeight: 1.66,
    },
    h1: {
      fontSize: "3rem",
      fontWeight: 600,
    },
    h2: {
      fontSize: "2.6rem",
      fontWeight: 400,
      lineHeight: 1,
    },
    h3: {
      fontSize: "2.5rem",
      fontWeight: 300,
      lineHeight: 1.01,
    },
    h4: {
      fontWeight: 700,
      fontSize: "2rem",
      lineHeight: 1.375,
    },
    h5: {
      fontWeight: 600,
      fontSize: "1.2rem",
      lineHeight: 1.375,
    },

    h6: {
      fontWeight: 600,
      fontSize: "1.05rem",
      lineHeight: 1.375,
      textTransform: "capitalize",
    },
  },
  zIndex: {
    appBar: 1200,
    drawer: 1100,
  },
};
