import { createSvgIcon } from '@mui/material/utils';

export const DashboardIcon = createSvgIcon(
<svg width="24" height="24" viewBox="0 0 18 18" fill="currentColor" preserveAspectRatio="xMidYMin" 
  xmlns="http://www.w3.org/2000/svg">
  <path clipRule="evenodd" d="M1.85649 0.666504H6.6184C7.27588 0.666504 7.80887 1.1995 7.80887 1.85698V6.61889C7.80887 7.27637 7.27588 7.80936 6.6184 7.80936H1.85649C1.19901 7.80936 0.666016 7.27637 0.666016 6.61889V1.85698C0.666016 1.1995 1.19901 0.666504 1.85649 0.666504ZM11.3803 10.1903H16.1422C16.7997 10.1903 17.3327 10.7233 17.3327 11.3808V16.1427C17.3327 16.8002 16.7997 17.3332 16.1422 17.3332H11.3803C10.7228 17.3332 10.1898 16.8002 10.1898 16.1427V11.3808C10.1898 10.7233 10.7228 10.1903 11.3803 10.1903ZM11.3803 0.666504H16.1422C16.7997 0.666504 17.3327 1.1995 17.3327 1.85698V6.61889C17.3327 7.27637 16.7997 7.80936 16.1422 7.80936H11.3803C10.7228 7.80936 10.1898 7.27637 10.1898 6.61889V1.85698C10.1898 1.1995 10.7228 0.666504 11.3803 0.666504ZM1.85649 10.1903H6.6184C7.27588 10.1903 7.80887 10.7233 7.80887 11.3808V16.1427C7.80887 16.8002 7.27588 17.3332 6.6184 17.3332H1.85649C1.19901 17.3332 0.666016 16.8002 0.666016 16.1427V11.3808C0.666016 10.7233 1.19901 10.1903 1.85649 10.1903Z" />
</svg>,
'DashboardIcon'
);



