import * as yup from 'yup';

import { SRS_APP_CONSTANTS } from '../../../constants';
import { infoStepFormSchema, infoStepFormSaveSchema, infoStepFormModel } from './informationStepForm';
import { childCareStepFormSchema, childCareStepFormModel, childCareStepFormIntValues } from './childCareStepForm';
import {
	kindergartenStepFormSchema,
	kindergartenStepFormModel,
	kindergartenStepIntValues,
} from './kindergartenStepForm';
import { firstGradeStepFormSchema, firstGradeStepFormModel } from './firstGradeStepForm';
import { seventhGradeStepFormSchema, seventhGradeStepIntValues, seventhGradeStepFormModel, seventhGradeStepFormSchemaWithVaricelle, seventhGradeStepFormModelWithVaricelle } from './seventhGradeStepForm';
import { eighthGradeStepFormSchema, eighthGradeStepFormModel } from './eighthGradeStepForm';

const getFormDetailsByStepName = (stepName, year , type: string = 'schema') => {
	let result;
	const isSchema = type === 'schema';

	switch (stepName) {
		case SRS_APP_CONSTANTS.information.type:
			result = isSchema ? infoStepFormSchema : infoStepFormModel;
			break;
		case SRS_APP_CONSTANTS.childCare.type:
			result = isSchema ? childCareStepFormSchema : childCareStepFormModel;
			break;
		case SRS_APP_CONSTANTS.kindergarten.type:
			result = isSchema ? kindergartenStepFormSchema : kindergartenStepFormModel;
			break;
		case SRS_APP_CONSTANTS.firstGrade.type:
			result = isSchema ? firstGradeStepFormSchema : firstGradeStepFormModel;
			break;
		case SRS_APP_CONSTANTS.seventhGrade.type:
			if(year >= +process.env.REACT_APP_VARICELLA_EXCLUDE_START_YEAR)
			result = isSchema ? seventhGradeStepFormSchema : seventhGradeStepFormModel;
			else
			result = isSchema ? seventhGradeStepFormSchemaWithVaricelle : seventhGradeStepFormModelWithVaricelle;
			break;
		case SRS_APP_CONSTANTS.eighthGrade.type:
			result = isSchema ? eighthGradeStepFormSchema : eighthGradeStepFormModel;
			break;
		case SRS_APP_CONSTANTS.viewPrintReport.type:
			result = isSchema ? null : { foo: 'bar' };
			break;
		default:
			break;
	}

	return result;
};

export const getCurrentStepValidationSchema = (stepName: string, schoolYear: number) => {
	try {
		let result = {};
		result[stepName] = getFormDetailsByStepName(stepName, schoolYear, 'schema');

		return yup.object().shape(result);
	} catch (err) {
		throw new Error('getCurrentStepValidationSchema function call failed!');
	}
};

export const getCurrentStepInitialValues = (stepName: string = SRS_APP_CONSTANTS.information.type) => {
	try {
		let result = {};
		result[stepName] = getFormDetailsByStepName(stepName, 'initialValues');
		return result;
	} catch (err) {
		throw new Error('getCurrentStepInitialValues function call failed!');
	}
};

export const getCurrentStepDataValidation = (stepName: string) => {
	try {
		let result = {};
		var yubobject: any;
		switch (stepName) {
			case SRS_APP_CONSTANTS.childCare.type:
				yubobject = childCareStepFormIntValues;
				break;
			case SRS_APP_CONSTANTS.kindergarten.type:
				yubobject = kindergartenStepIntValues;
				break;
			case SRS_APP_CONSTANTS.seventhGrade.type:
				yubobject = seventhGradeStepIntValues;
				break;
			case SRS_APP_CONSTANTS.information.type:
				yubobject = infoStepFormSaveSchema;
				break;
			default:
				break;
		}
		if (yubobject != null) {
			result[stepName] = yubobject;

			return yup.object().shape(result);
		} else {
			return null;
		}
	} catch (err) {
		throw new Error('getCurrentStepDataValidation function call failed!');
	}
};
