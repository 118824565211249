import { AlertColor, Button } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { FC } from 'react';
import { useLog } from 'src/hooks/use-log';
import { appLogPrefixes } from 'src/config/app-config';
import { ColorEnum } from 'src/utils/color-log.util';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import { sRSFeatureRequests, UserApiFactory } from '@the-mcorp/elevenfiftynine-srs-lib';
import { RootState, useDispatch } from 'src/store';
import { displayBackdrop, displaySnackbar } from 'src/store/slices/global';

/**
 * Renders a button for logging out (via MSAL redirect)
 */

const SignOutButton: FC = () => {
	const { instance } = useMsal();
	const dispatch = useDispatch();

	const postLogoutRedirectUri = `${process.env.REACT_APP_FRONTEND_CAIR_HUB_URL}${process.env.REACT_APP_AZURE_B2C_POST_LOGOUT_REDIRECT_ROUTE}`;

	const handleLogoutNavigation = async () => {
		useLog(`SignOut invoked. Redireting to: ${postLogoutRedirectUri}`, ColorEnum.INFO, appLogPrefixes.msalAuth);

		const userRequests = sRSFeatureRequests.user;

		dispatch(
			userRequests.fetchDelete_User_Logout<RootState>(UserApiFactory, { requestBody: null }, undefined, '', true)
		);
		instance.logoutRedirect({
			postLogoutRedirectUri: postLogoutRedirectUri,
		});
	};

	return (
		<Button color='secondary' onClick={handleLogoutNavigation} variant='outlined' endIcon={<SettingsIcon />}>
			Developer Log Out
		</Button>
	);
};

export default SignOutButton;
