// Generated with util/create-slice.js
import { reducer } from './startUp.reducer';
import * as effects from './startUp.effects';
import { createSelector } from '@reduxjs/toolkit';
import { sRSFeatureEntityAdapters } from '@the-mcorp/elevenfiftynine-srs-lib';
import { RootState } from 'src/store';

export { reducer, effects };

/***
 * Data Message selector for handling data success/failure
 */
 
const startupBatchMessageSelector = createSelector(
    [(state: RootState) => state.startUp],
    (stateSlice) => {
        return stateSlice.statusMessage;
    }
);
  
/***
 * API Selectors
 */
const lookupCountySelectors = sRSFeatureEntityAdapters.dataLookup.lookupCounty.getSelectors<RootState>(
    (state) => state.srsApi_DataLookup.lookupCounty
);

const lookupRegionSelectors = sRSFeatureEntityAdapters.dataLookup.lookupRegion.getSelectors<RootState>(
    (state) => state.srsApi_DataLookup.lookupRegion
);

const lookupDistrictSelectors = sRSFeatureEntityAdapters.dataLookup.lookupDistrict.getSelectors<RootState>(
    (state) => state.srsApi_DataLookup.lookupDistrict
);

const lookupCohortSelectors = sRSFeatureEntityAdapters.dataLookup.lookupCohort.getSelectors<RootState>(
    (state) => state.srsApi_DataLookup.lookupCohort
);

const lookupCohortGroupSelectors = sRSFeatureEntityAdapters.dataLookup.lookupCohortGroup.getSelectors<RootState>(
    (state) => state.srsApi_DataLookup.lookupCohortGroup
);

const lookupLocalHealthJurisdictionSelectors = sRSFeatureEntityAdapters.dataLookup.lookupHealthJurisdiction.getSelectors<RootState>(
    (state) => state.srsApi_DataLookup.lookupHealthJurisdiction
);

const userSchoolSelectors = sRSFeatureEntityAdapters.user.userSchool.getSelectors<RootState>(
    (state) => state.srsApi_User.userSchool
);

const userRolesSelectors = sRSFeatureEntityAdapters.user.userRoles.getSelectors<RootState>(
    (state) => state.srsApi_User.userRoles
);
  
const academicYearSelectors = sRSFeatureEntityAdapters.dataLookup.academicYear.getSelectors<RootState>(
	(state) => state.srsApi_DataLookup.academicYear
);
const dataRefreshDateSelectors = sRSFeatureEntityAdapters.schoolsDetail.schoolDataRefreshDetail.getSelectors<RootState>(
	(state) => state.srsApi_SchoolsDetail.schoolDataRefreshDetail
);
  
/**
 * Export all selectors
 */
export const startupBatchSelectors = {
	dataMessage: {
	  startupBatchMessageSelector,
	},
	data: {
	  lookupCohortGroupSelectors,
	  lookupCohortSelectors,
	  lookupCountySelectors,
	  lookupDistrictSelectors,
	  lookupLocalHealthJurisdictionSelectors,
	  lookupRegionSelectors,
	  userSchoolSelectors,
	  userRolesSelectors,
	  academicYearSelectors,
	  dataRefreshDateSelectors
	},
  };
  