import { Button } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { User as UserIcon } from '../../../icons/user';
 
 
import { FC } from 'react';
import { loginRequest } from 'src/config/msal-config';
import { ColorEnum } from 'src/utils/color-log.util';
import { appLogPrefixes } from 'src/config/app-config';
import { useLog } from 'src/hooks/use-log';
import SettingsIcon from '@mui/icons-material/Settings';
import SecurityIcon from '@mui/icons-material/Security';
import { useNavigate } from 'react-router';
/**
 * Renders a button for logging in (via MSAL redirect)
 */

export interface SignInButtonProps {
	isSidebar?: boolean;
}
 
const SignInButton: FC<SignInButtonProps> = (props) => {

  const { isSidebar } = props; 
	const { instance } = useMsal();
   const navigation = useNavigate()
	const handleLoginNavigation = async (): Promise<void> => {
 
		try {
		 
			useLog(`Login Redirect: ${JSON.stringify(loginRequest.scopes[0], null, '\t')}`, ColorEnum.INFO, appLogPrefixes.startup);
			instance.loginRedirect(loginRequest).then((event) =>{


			}
			
			).catch((e) => {
				console.log(e);
			});
		} catch (err) {
			console.error(err);
		}
	};

	if (isSidebar) {
		return (
			<Button size='small' onClick={handleLoginNavigation} variant='outlined'  endIcon={<SecurityIcon />}>
				<UserIcon fontSize='small' />
				Developer Login
			</Button>
		);
	} else {
		return (
			<Button color='primary' onClick={handleLoginNavigation} variant='outlined'  endIcon={<SecurityIcon />}>
				Developer Login
			</Button>
		);
	}
};

export default SignInButton;
