import type { FC } from 'react';
import PropTypes from 'prop-types';
import toast from 'react-hot-toast';
import LogoutIcon from '@mui/icons-material/Logout';
import { Avatar, Box, Button, Divider, Popover, Stack, Typography } from '@mui/material';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';
import { appLogPrefixes } from 'src/config/app-config';
import { UserApiFactory, sRSFeatureRequests } from '@the-mcorp/elevenfiftynine-srs-lib';
import { RootState, useDispatch } from 'src/store';
import { useMsal } from '@azure/msal-react';

interface AccountPopoverProps {
	anchorEl: null | Element;
	onClose?: () => void;
	open?: boolean;
	userinfo?: { username: string; userEmail: string };
}

export const AccountPopover: FC<AccountPopoverProps> = (props) => {
	const { anchorEl, onClose, open, ...other } = props;

	const dispatch = useDispatch();
	const { instance } = useMsal();
	const postLogoutRedirectUri = `${process.env.REACT_APP_FRONTEND_CAIR_HUB_URL}${process.env.REACT_APP_AZURE_B2C_POST_LOGOUT_REDIRECT_ROUTE}`;

	// Extract userinfo from other props
	const userinfo = other['userinfo'] !== undefined ? other['userinfo'] : { username: '', userEmail: '' };

	const handleLogout = async () => {
		useLog(`Logging out from account popover...`, ColorEnum.ERROR, appLogPrefixes.msalAuth);

		try {
			const userRequests = sRSFeatureRequests.user;

			dispatch(
				userRequests.fetchDelete_User_Logout<RootState>(UserApiFactory, { requestBody: null }, undefined, '', true)
			);
			instance.logoutRedirect({
				postLogoutRedirectUri: postLogoutRedirectUri,
			});
		} catch (error) {
			toast.error('Something went wrong!');
			useLog(`Error logging out from account popover:\n\t${error}`, ColorEnum.ERROR, appLogPrefixes.msalAuth);
		}
	};

	function stringToColor(string: string) {
		let hash = 0;
		let i: number;

		/* eslint-disable no-bitwise */
		for (i = 0; i < string.length; i += 1) {
			hash = string.charCodeAt(i) + ((hash << 5) - hash);
		}

		let color = '#';

		for (i = 0; i < 3; i += 1) {
			const value = (hash >> (i * 8)) & 0xff;
			color += `00${value.toString(16)}`.slice(-2);
		}
		/* eslint-enable no-bitwise */

		return color;
	}

	return (
		<Popover
			anchorEl={anchorEl}
			anchorOrigin={{
				horizontal: 'center',
				vertical: 47,
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'center',
			}}
			disableScrollLock
			onClose={onClose}
			open={!!open}
			sx={{ minWidth: 450 }}
			elevation={20}
			slotProps={{ paper: { sx: { outline: 'none', borderWidth: 0, borderRadius: 0, background: 'background.paper' } } }}
			{...other}>
			<Stack direction='column' spacing={2} sx={{ p: 2 }}>
				<Typography variant='subtitle1' paddingBottom={'1rem'}>
					SRS ACCOUNT
				</Typography>
				<Stack direction='row' spacing={2} sx={{ p: 0 }}>
					<Stack direction='column' justifyContent='center' alignItems='center'>
						<Avatar alt='Profile' src={''} sx={{ bgcolor: stringToColor(userinfo.username), width:'55px', height:'55px' }}>
							<Typography fontSize={'1.5rem'} fontWeight={'bolder'} color={'#FFF'}>	
								{`${userinfo.username.split(' ')[0][0].toUpperCase()}${userinfo.username.split(' ')[1][0].toUpperCase()}`}
							</Typography>
						</Avatar>
					</Stack>
					<Stack direction='column' spacing={1}>
						<Typography variant='body1' fontWeight={'bolder'}>
							{`${userinfo.username}`}
						</Typography>
						<Typography variant='body2'>
							{userinfo.userEmail}
						</Typography>
					</Stack>
				</Stack>
			</Stack>
			<Divider sx={{ borderColor: 'ButtonShadow' }} />
			<Box
				sx={{
					display: 'flex',
					p: 1,
					justifyContent: 'left',
				}}>
				<Button
					fullWidth
					color='inherit'
					onClick={handleLogout}
					size='large'
					startIcon={<LogoutIcon sx={{ marginRight: '1rem' }} />}
					sx={{ justifyContent: 'flex-start', paddingLeft:'22px' }}>
					<Typography variant='body1'>
						Log Out
					</Typography>
				</Button>
			</Box>
		</Popover>
	);
};

AccountPopover.propTypes = {
	anchorEl: PropTypes.any,
	onClose: PropTypes.func,
	open: PropTypes.bool,
};
