// Generated with util/create-slice.js
import React from 'react';

import { createSlice, PayloadAction, createEntityAdapter, EntityState, EntityAdapter } from '@reduxjs/toolkit';
import axios from 'axios';
import { AppThunk, RootState } from '../..';
import { hideBackdrop } from '../global';

export interface ReportingDashboard {
	status: string | undefined;
}

// interface GlobalApp {
//     isLoaded?: boolean;
//     backDropProps: DynamicBackdropProps | null;
//   }

//   interface GlobalAppState extends GlobalApp {
//   }

//   const initialState: GlobalAppState = {
//     isLoaded: false,
//     backDropProps: { title: '', visible: false },
//   };

export interface UserSchoolAssignmentState extends ReportingDashboard {
	allItemsLoaded: boolean | null;
	isLoading: boolean;
	isUpdating: boolean;
	statusMessage: string | undefined;
	error: any;
}

export const initialState: UserSchoolAssignmentState = {
	status: undefined,
	allItemsLoaded: false,
	isLoading: false,
	isUpdating: false,
	statusMessage: undefined,
	error: null,
};

const slice = createSlice({
	name: 'user-school-assignment-batch',
	initialState,
	reducers: {
		addUserSchoolBatch(state: UserSchoolAssignmentState) {
			console.log(`Save School Information state`);
			state = { ...state, statusMessage: 'fetching' };
			return state;
		},
		addUserSchoolsBatchSuccess(state: UserSchoolAssignmentState, action: PayloadAction<any[]>) {
			state = {
				...state,
				isLoading: false,
				error: null,
				statusMessage: 'success',
			};
			console.log(`State for Save Information Batch adapter success`);
			return state;
		},
		addUserSchoolsBatchFailure(state: UserSchoolAssignmentState, action: PayloadAction<string>) {
			console.log(`Error State for adapter ${JSON.stringify(action.payload, null, '	')}`);
			state = {
				...state,
				isLoading: false,
				error: action.payload,
				statusMessage: 'failure',
			};
			return state;
		},
	},
});

// Entity Actions
export const { actions } = slice;

// Entity Reducer
export const reducer = slice.reducer;

export default slice;
