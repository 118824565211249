import { Box, Button, CardContent, Container, Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { useMsal } from '@azure/msal-react';
import { User as UserIcon } from '../../../icons/user';
import { loginRequest } from '../../../config/msal-config';

import { FC } from 'react';
import { Helmet } from 'react-helmet-async';
import { appConfig } from 'src/config/app-config';
import { defaultAuthCardStyles } from 'src/pages/authentication';
import { CustomCard } from 'src/sections/dashboard/immunization-reporting-system/common/custom-card';
import { CSSObject } from '@emotion/styled';
import { AppLogo } from 'src/components/app-logo';
import { SrsLogo } from 'src/components/srs-logo';

/**
 * Renders a button for logging in (via MSAL redirect)
 */

export interface LoadingViewProps {
	statusTitle: string;
	statusDescription: string;
	hasCompleted?: boolean;
}

export const cardStyles: CSSObject = {
	height: '12em',
	border: '1px solid lightgray',
	backgroundColor: 'transparent',
};

const StatusView: FC<LoadingViewProps> = (props) => {
	const { statusTitle, statusDescription, hasCompleted = false } = props;
	const showLogo = process.env.REACT_APP_SHOW_1159_LOGO;
	const appVersion = process.env.REACT_APP_VERSION;

	return (
		<>
			<Helmet>
				<title>{statusTitle}</title>
			</Helmet>

			<Box
				sx={{
					alignItems: 'center',
					backgroundColor: 'background.paper',
					display: 'flex',
					flexDirection: 'column',
					height: '100vh',
					justifyContent: 'center',
					left: 0,
					p: 3,
					position: 'fixed',
					top: 0,
					width: '100vw',
					zIndex: 1400,
				}}>
				<Stack
					direction={'column'}
					rowGap={1}
					alignContent={'center'}
					justifyItems={'center'}
					justifyContent={'center'}>
					<Typography color='primary' variant='h4' justifyContent={'center'} textAlign={'center'} display={'flex'}>
						{statusTitle}
					</Typography>
					<Typography
						color='textSecondary'
						variant='body2'
						justifyContent={'center'}
						textAlign={'center'}
						display={'flex'}>
						{statusDescription}
					</Typography>
					<Box sx={{ width: '100%', mt: 1 }}>
						<LinearProgress />
					</Box>
					<AppLogo />
					{showLogo === 'true' && (
						<>
							<Stack
								direction={'column'}
								alignItems={'center'}
								gap={1}
								sx={{ mt: 5 }}
								alignContent={'center'}
								justifyItems={'center'}
								justifyContent={'center'}
								rowGap={0}>
								<SrsLogo width={80} />
							</Stack>
							<Typography
								color='textSecondary'
								variant='caption'
								justifyContent={'center'}
								textAlign={'center'}
								display={'flex'}>
								<Box textAlign={'center'}>
									<span style={{ fontSize: '11px', fontWeight: 400 }}>Powered by</span>
									{'  '}
									<span style={{ fontSize: '12px', fontWeight: 800 }}>1159.ai</span>
								</Box>
							</Typography>
						</>
					)}
				</Stack>
			</Box>
		</>
	);
};

export default StatusView;
