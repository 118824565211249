import type { FC } from 'react';
import { Box, Divider, Stack } from '@mui/material';
import AccountIcons from '../dashboard/navbar-content/account-icons';
import ExternalLinkIcons from '../dashboard/navbar-content/external-link-icons';
export interface NavbarViewProps {}

/**
 *
 * @param props
 * @returns
 */
const CommonNavbarView: FC<NavbarViewProps> = (props) => {
	return (
		<>
			<Box sx={{ ml: 2 }}>
				<Stack direction='row' spacing={0}>
					<ExternalLinkIcons />
					<AccountIcons />
				</Stack>
			</Box>
		</>
	);
};

export default CommonNavbarView;
