import { RouteObject } from 'react-router';
import { Suspense, lazy } from 'react';
import DefaultDashboardLayout from 'src/layouts/default-dasbhoard-layout';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/splash-screen';
import StartupGuard from 'src/components/guards/startup-guard';
import DefaultMainLayout from '../layouts/default-main-layout';

const Loadable = (Component: any) => (props: any) =>
	(
		<Suspense fallback={<SplashScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Pages
const AuthorizationRequired = Loadable(lazy(() => import('../pages/error/401')));
const AccessForbidden = Loadable(lazy(() => import('../pages/error/403')));
const NotFound = Loadable(lazy(() => import('../pages/error/404')));
const ServerError = Loadable(lazy(() => import('../pages/error/500')));
 

export const errorPageRoutes: RouteObject[] = [
	{
		path: '401',
		element: (
			<StartupGuard>
				<DefaultMainLayout>
					<AuthorizationRequired />
				</DefaultMainLayout>
			</StartupGuard>
		),
	},
	{
		path: '403',
		element: (
			<StartupGuard>
				<DefaultMainLayout>
					<AccessForbidden />
				</DefaultMainLayout>
			</StartupGuard>
		),
	},
	{
		path: '404',
		element: (
			<StartupGuard>
				<DefaultMainLayout>
					<NotFound />
				</DefaultMainLayout>
			</StartupGuard>
		),
	},
	{
		path: '500',
		element: (
			<StartupGuard>
				<DefaultMainLayout>
					<ServerError />
				</DefaultMainLayout>
			</StartupGuard>
		),
	}, 
];
