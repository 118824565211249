import { RouteObject } from 'react-router'; 
import { Suspense, lazy } from 'react'; 
import { SplashScreen } from 'src/components/splash-screen';
 

const AuthLoadable = (Component: any) => (props: any) =>
(
	<Suspense fallback={<SplashScreen />}>
		<Component {...props} />
	</Suspense>
);

// Auth pages   
const LoggedOutPage = AuthLoadable(lazy(() => import('../pages/authentication/logged-out')));

export const authorizationRoutes: RouteObject[] = [
	// {
	// 	path: 'auth', 
	// 	children: [ 
	// 		{
	// 			path: 'logout',
	// 			element: <LogoutPage />,
	// 		} 
	// 	], 
	// },
	// {
	// 	path: 'logout-page',
	// 	element: <LoggedOutPage />,
	// },
];
