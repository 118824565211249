import { createContext, useEffect, useReducer, useState } from 'react';
import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { useIsAuthenticated } from '@azure/msal-react';
// import { Auth0Client } from '@auth0/auth0-spa-js';
// import { auth0Config } from '../config';
// import type { User } from '../types/user';
import {
	EventType,
	InteractionRequiredAuthError,
	InteractionStatus,
	PublicClientApplication,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { User } from 'src/types/user';
import { authUtils } from 'src/utils/auth.utils';
import { loginRequest } from 'src/config/msal-config';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';
import { appLogPrefixes } from 'src/config/app-config';

import { UserRolesViewModel } from '@the-mcorp/elevenfiftynine-srs-lib';
import { useSelector } from 'react-redux';
import { startupBatchSelectors } from 'src/store/slices/start-up';
import GppBadIcon from '@mui/icons-material/GppBad';
import { useConfirmationDialog } from 'src/hooks/use-confirmation-dialog';
import { browserStore as BrowserStore, browserStore } from 'src';
import { BrowserStorageType } from 'src/utils/browser-storage.util';

let pcaClient: PublicClientApplication | null = null;

type AppState = {
	returnUrl?: string;
};

type AuthState = {
	idToken?: string;
	homeAccountId?: string;
	localAccountId?: string;
	environment?: string;
	tokenExpiry?: number;
	tokenAuthorized?: number;
};

interface State {
	isInitialized: boolean;
	isAuthenticated: boolean;
	auth: AuthState | null;
	user: User | null;
	roles: any[] | null;
}

interface AuthProviderProps {
	instance: PublicClientApplication;
	children: ReactNode;
}

enum ActionType {
	INITIALIZE = 'INITIALIZE',
	USER_ROLES_ADDED = 'USER_ROLES_ADDED',
	LOGIN = 'LOGIN',
	LOGOUT = 'LOGOUT',
}

type InitializeAction = {
	type: ActionType.INITIALIZE;
	payload: {
		isAuthenticated: boolean;
		user: User | null;
		auth: AuthState | null;
	};
};

type UserRolesAddedAction = {
	type: ActionType.USER_ROLES_ADDED;
	payload: {
		roles: any[] | null;
	};
};

type LoginAction = {
	type: ActionType.LOGIN;
	payload: {
		user: User;
		auth: AuthState | null;
	};
};

type LogoutAction = {
	type: ActionType.LOGOUT;
};

type Action = InitializeAction | UserRolesAddedAction | LoginAction | LogoutAction;

type Handler = (state: State, action: any) => State;

const initialState: State = {
	isAuthenticated: false,
	isInitialized: false,
	user: null,
	auth: null,
	roles: null,
};

const handlers: Record<ActionType, Handler> = {
	INITIALIZE: (state: State, action: InitializeAction): State => {
		const { isAuthenticated, user, auth } = action.payload;

		return {
			...state,
			isAuthenticated,
			isInitialized: true,
			user,
			auth,
		};
	},
	USER_ROLES_ADDED: (state: State, action: UserRolesAddedAction): State => {
		const { roles } = action.payload;
		return {
			...state,
			roles,
		};
	},
	LOGIN: (state: State, action: LoginAction): State => {
		const { user, auth } = action.payload;

		return {
			...state,
			isAuthenticated: true,
			user,
			auth,
		};
	},
	LOGOUT: (state: State): State => ({
		...state,
		isAuthenticated: false,
		user: null,
		auth: null,
	}),
};

const reducer = (state: State, action: Action): State =>
	handlers[action.type] ? handlers[action.type](state, action) : state;

/**
 *
 */
export interface AuthContextType extends State {
	platform: 'MSAL';
	// loginWithRedirect: (appState?: AppState) => Promise<void>;
	// handleRedirectCallback: () => Promise<AppState | undefined>;
	logout: () => Promise<void>;
}

/**
 *
 */
export const AuthContext = createContext<AuthContextType>({
	...initialState,
	platform: 'MSAL',
	// loginWithRedirect: () => Promise.resolve(),
	// handleRedirectCallback: () => Promise.resolve(undefined),
	logout: () => Promise.resolve(),
});

/**
 *
 * @param props
 * @returns
 */
export const AuthProvider: FC<AuthProviderProps> = (props) => {
	const { instance, children } = props;
	const [state, dispatch] = useReducer(reducer, initialState);
	// const [hasAuthError, setHasAuthError] = useState(false); // Deprecated - Leaving temporarily for reference
	const { inProgress } = useMsal();
	const userRoles = useSelector(startupBatchSelectors.data.userRolesSelectors.selectAll);

	const confirm = useConfirmationDialog();

	const isAuthenticated = useIsAuthenticated();

	// Handle Failure events
	useEffect(() => {
		const callbackId = instance.addEventCallback((event: any): void => {
			switch (event.eventType) {
				case EventType.LOGIN_FAILURE:
				case EventType.ACQUIRE_TOKEN_FAILURE:
				case EventType.SSO_SILENT_FAILURE:
					useLog(
						`MSAL Event: ${event.eventType}\nEvent Detail: ${JSON.stringify(event, null, '\t')}`,
						ColorEnum.ERROR,
						appLogPrefixes.msalAuth
					);
					BrowserStore.setItem(BrowserStorageType.SESSION, "invalid-browser", true)
					break;
			}
		});

		return () => {
			if (callbackId) {
				instance.removeEventCallback(callbackId);
			}
		};
	}, []);

	// Initialize Context
	useEffect(() => {
		const initialize = async (): Promise<void> => {
			if (inProgress === InteractionStatus.None && isAuthenticated) {
				const activeAccount = instance.getActiveAccount();

				if (activeAccount) {
					const tokenClaims = activeAccount.idTokenClaims;
					const user: User = {
						id: tokenClaims ? tokenClaims.sub : null,
						username: activeAccount!.username,
						emails: tokenClaims ? tokenClaims.emails : null,
					};

					const auth: AuthState = {
						idToken: activeAccount!.idToken,
						homeAccountId: activeAccount!.homeAccountId,
						localAccountId: activeAccount!.localAccountId,
						environment: activeAccount!.environment,
						tokenAuthorized: tokenClaims ? tokenClaims.auth_time : null,
						tokenExpiry: tokenClaims ? tokenClaims.exp : null,
					};

					// Check if token needs to be retrieved after reload.
					if (!auth.idToken) {
						useLog(`Retrieving token after reload.`, ColorEnum.INFO, appLogPrefixes.msalAuth);
						instance
							.acquireTokenSilent({
								...loginRequest,
								account: activeAccount,
							})
							.then((response: any) => {
								auth.idToken = response.idToken;
								const tokenClaims = response.idTokenClaims;
								(auth.tokenAuthorized = tokenClaims ? tokenClaims.auth_time : null),
									(auth.tokenExpiry = tokenClaims ? tokenClaims.exp : null),
									// console.log(`auth ${JSON.stringify(auth, null, '\t')}`);
									useLog(`Updating Token to AuthContext.`, ColorEnum.INFO, appLogPrefixes.msalAuth);

								dispatch({
									type: ActionType.INITIALIZE,
									payload: {
										isAuthenticated,
										user: user,
										auth: auth,
									},
								});
							})
							.catch((e) => {
								//TODO: Handle error here
								useLog(`Failure to update Token.`, ColorEnum.ERROR, appLogPrefixes.msalAuth);
							});
						return;
					} else {
						useLog(`Token retrieved from login.`, ColorEnum.SUCCESS, appLogPrefixes.msalAuth);

						dispatch({
							type: ActionType.INITIALIZE,
							payload: {
								isAuthenticated,
								user: user,
								auth: auth,
							},
						});
					}
				} else {
					dispatch({
						type: ActionType.INITIALIZE,
						payload: {
							isAuthenticated,
							user: null,
							auth: null,
						},
					});
				}
			} else {
				dispatch({
					type: ActionType.INITIALIZE,
					payload: {
						isAuthenticated,
						user: null,
						auth: null,
					},
				});
			}
		};
		initialize();
	}, [inProgress, isAuthenticated]);

	// Add User Roles
	useEffect(() => {
		if (userRoles && userRoles.length > 0) {
			useLog(`User Roles added to authorization context`, ColorEnum.SUCCESS, appLogPrefixes.userContext);
			dispatch({
				type: ActionType.USER_ROLES_ADDED,
				payload: {
					roles: userRoles,
				},
			});
		}
	}, [userRoles]);

	/////////////////////////////////////////////////////////////////////
	// Deprecated - Now depending on session variables instead of states 
	// Leaving temporarily for reference
	/////////////////////////////////////////////////////////////////////
	//
	// useEffect(() => {
	// 	if (hasAuthError) {

	// 		// Clear local for any corrupt token information 
	// 		useLog(`Authentication error occurred. Corrupt session detected. Clearing local storage and resetting session.`, ColorEnum.ERROR, appLogPrefixes.startup); 

	// 		// NOTE: Below code not necessary but keep temporarily.
	// 		//authUtils.handleLogoutFromAuthError();

	// 		// const onLoginError = (event: any) => {
	// 		// 	useLog(`Redirecting to logout after corrupt session detected.`, ColorEnum.ERROR, appLogPrefixes.startup);  
	// 		// 	authUtils.handleLogoutFromAuthError();
	// 		// };
 
	// 		// confirm({
	// 		// 	icon: <GppBadIcon color={'error'} sx={{ height: 30, width: 30 }} />,
	// 		// 	variant: 'ok',
	// 		// 	catchOnCancel: true,
	// 		// 	title: 'Oops! Something went wrong.',
	// 		// 	description: `Please try logging in again.`,
	// 		// })
	// 		// 	.then(() => onLoginError(false))
	// 		// 	.catch(() => onLoginError(true));
	// 	}
	// }, [hasAuthError]);

	const logout = async (): Promise<void> => {
		// await auth0Client!.logout();
		// dispatch({
		// 	type: ActionType.LOGOUT,
		// });
	};

	return (
		<AuthContext.Provider
			value={{
				...state,
				platform: 'MSAL',
				// loginWithRedirect,
				// handleRedirectCallback,
				logout,
			}}>
			{children}
		</AuthContext.Provider>
	);
};

 
export const AuthConsumer = AuthContext.Consumer;
