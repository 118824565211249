import { RouteObject } from 'react-router';
import { Suspense, lazy } from 'react';
import DefaultDashboardLayout from 'src/layouts/default-dasbhoard-layout';
import AuthGuard from 'src/components/guards/auth-guard';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/splash-screen';
import StartupGuard from 'src/components/guards/startup-guard';
import { paths } from 'src/paths';

const Loadable = (Component: any) => (props: any) =>
	(
		<Suspense fallback={<SplashScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Pages
const SchoolSummaryDashboard = Loadable(lazy(() => import('../pages/dashboard/school-summary-dashboard')));
const CohortDashboardPage = Loadable(lazy(() => import('../pages/dashboard/cohort-summary-dashboard')));
const ClosureGroupsPage = Loadable(lazy(() => import('../pages/dashboard/closure-groups')));

export const adminDashboardRoutes: RouteObject[] = [
	{
		path: 'dashboard',
		children: [
			{
				path: 'admin',
				element: (
					<AuthGuard>
						<StartupGuard>
							<DefaultDashboardLayout>
								<Outlet />
							</DefaultDashboardLayout>
						</StartupGuard>
					</AuthGuard>
				),
				children: [
					{
						path: '',
						element: <CohortDashboardPage />,
					},
					{
						path: 'cohort-summary',
						element: <CohortDashboardPage />,
					},
					{
						path: 'school-summary',
						element: <SchoolSummaryDashboard />,
					},
					{
						path: 'closure-groups',
						element: <ClosureGroupsPage />,
					},
				],
			},
		],
	},
];
