export enum ColorEnum {
  VERBOSE = "verbose",
  INFO = "info",
  SUCCESS = "success",
  WARNING = "warning",
  ERROR = "error",
  START = "start",
  END = "end",
  CUSTOM = "custom",
}

/**
 *
 */
export interface CustomLogColor {
  color: string;
  bgColor: string;
}

export class ColorLog {
  constructor() {}

  /**
   *
   * @param msg
   * @param color
   * @param prefix
   * @param customColor
   */
  public log(
    msg: string,
    color: string,
    prefix: string = "App",
    customColor?: CustomLogColor
  ): void {
    var color = color || "black";
    var bgc = "White";

    msg = `[${prefix}] ${msg}`;

    switch (color) {
      case "success":
        color = "LimeGreen"; 
        break;
      case "info":
        color = "#33CCFF";
        break;
      case "verbose":
        color = "#CCCCCC";
        break;
      case "error":
        color = "#FF0000"; 
        break;
      case "start":
        color = "OliveDrab";
        // bgc = "PaleGreen";
        break;
      case "warning":
        color = "#FFD400"; 
        break;
      case "end":
        color = "Orchid";
        // bgc = "MediumVioletRed";
        break;
      case "custom":
        color = customColor ? customColor.color : "Green";
        // bgc = customColor ? customColor.color : "White";
        break;
      default:
        color = color;
    }

    if (typeof msg == "object") {
      console.log(msg);
    } else if (typeof color == "object") {
      console.log(
        "%c" + msg,
        "color: PowderBlue;font-weight:bold; background-color: RoyalBlue;"
      );
      console.log(color);
    } else {
      console.log("%c" + msg, "color:" + color + ";");
      // else {
      //     console.log(
      //         '%c' + msg,
      //         'color:' +
      //             color +
      //             ';font-weight:bold; background-color: ' +
      //             bgc +
      //             ';'
      //     );
    }
  }
}
