import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNewAuth } from 'src/hooks/use-auth-new';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { sRSFeatureEntityAdapters } from '@the-mcorp/elevenfiftynine-srs-lib';
import { RootState } from 'src/store';
import { ClosureGroupRoles } from 'src/constants';

const FormSaveAlertDialog = (props) => {
	const submissionDueDate = new Date(
		useSelector((state: any) => state.selectedSchoolDetailedInfo.currentFormData.schInformation.submissionDueDate)
	);
	const schoolDetailSelector = useSelector((state: any) => state.selectedSchoolDetailedInfo);
	const steps = schoolDetailSelector.steps;
	const activeStepIndex = schoolDetailSelector.activeStepIndex;
	const { user, roles } = useNewAuth();
	const [open, setOpen] = React.useState(true);
	const handleProceed = () => {
		props.onProceed();
	};

	const handleClose = (event, reason) => {
		if (reason && (reason === 'backdropClick' || reason === 'escapeKeyDown')) return;
		setOpen(false);
		props.onCancel();
	};

	const closureGroupSelectors = sRSFeatureEntityAdapters.closureGroups.closureGroups.getSelectors<RootState>(
		(state) => state.srs_ClosureGroups.closureGroups
	);
	const closeGroups = useSelector(closureGroupSelectors.selectAll) as any[];

	const isReportPeriodOpen = (data) => {
		const isDisabled = !!closeGroups?.find((group) => group.isArchived && group.isDefault);
		if (isDisabled) {
			return false;
		}

		const userRole = ClosureGroupRoles.find((role) => role.Key === roles[0]?.role);

		const defaultClosureGroup = closeGroups?.find((group) => group.isDefault);

		const currentDate = new Date();
		const defaultGroupCloseDate = new Date(defaultClosureGroup.closedDate);
		const defaultGroupOpenDate = new Date(defaultClosureGroup.openDate);

		if (
			defaultGroupCloseDate > currentDate &&
			submissionDueDate > defaultGroupOpenDate &&
			currentDate > defaultGroupOpenDate
		) {
			for (const item of data) {
				if (item.groupsList.length > 0 && item.cohortsList.length > 0) {
					const isUserRoleContained = !!item.groupsList.find((group) => group === userRole?.Value);
					const isCohortContained = !!item.cohortsList.find(
						(cohort) => cohort === steps[activeStepIndex].secondaryLabel
					);
					const isBeforeClosedDate = new Date(item.closedDate) > currentDate;

					if (isUserRoleContained && isCohortContained && isBeforeClosedDate) {
						return true;
					}
				} else if (item.isDefault) {
					return true;
				}
			}
		}
		return false;
	};

	if (!!!roles?.find((role) => role.role.toLowerCase() === 'helpdesk')) {
		return (
			<div>
				<Dialog
					open={open}
					onClose={handleClose}
					aria-labelledby='alert-dialog-title'
					aria-describedby='alert-dialog-description'>
					<DialogTitle id='alert-dialog-title'>{'Discard entered data?'}</DialogTitle>
					<DialogContent>
						<DialogContentText id='alert-dialog-description'>Entered form details will not be saved.</DialogContentText>
					</DialogContent>
					<DialogActions>
						<Button onClick={() => handleClose(null, null)}>Cancel</Button>
						<Button onClick={handleProceed} autoFocus>
							Proceed
						</Button>
					</DialogActions>
				</Dialog>
			</div>
		);
	} else {
		handleClose(null, 'backdropClick');
		handleProceed();
		return null;
	}
};

export default FormSaveAlertDialog;
