import { AppThunk, RootState } from 'src/store';
import { actions } from './school-cohort-batch.reducer';

import {
	sRSFeatureRequests,
	DashboardApiFactory,
	SchoolsDetailApiFactory,
	SchoolInformationCohortViewModel,
} from '@the-mcorp/elevenfiftynine-srs-lib';
import { hooks } from '@the-mcorp/core-ui-lib';
import { apiUtils } from 'src/utils/api.utils';
import { hideBackdrop } from '../global';

/**
 *
 * @param schoolCode
 * @param debug
 * @returns
 */
export const fetchSchoolCohortBatch =
	(schoolCode: string, academicYearStart?: string, debug: boolean = false): AppThunk =>
	async (dispatch) => {
		try {
			console.log('Retrieving SchoolCohortBatch data...');

			const schoolRequests = sRSFeatureRequests.schoolsDetail;

			// TODO: Make sure we're pulling back all the lookup data; cap is currently at 1000 records.
			const payload = hooks.useRequestBuilder(
				'getAll',
				'post',
				<any>{ schoolCode: schoolCode, academicYearStart: academicYearStart,academicYear: academicYearStart },
				1,
				1000
			);

			dispatch(actions.getSchoolCohortBatch());
			if (debug) {
				console.log(`PAYLOAD: ${JSON.stringify(payload, undefined, '\t')}`);
			}
			try {
				const res = await Promise.all([
					dispatch(
						schoolRequests.fetchGetAll_SchoolInformationCohort_SchoolInformationCohortBySchoolCode<RootState>(
							SchoolsDetailApiFactory,
							payload,
							undefined,
							'',
							true
						)
					),
					dispatch(
						schoolRequests.fetchGetAll_SchoolInformation_GetSchoolInformationBySchoolCode<RootState>(
							SchoolsDetailApiFactory,
							payload,
							undefined,
							'',
							true
						)
					),
					dispatch(
						schoolRequests.fetchGetAll_SchoolInformationDesignatedContact_GetSchoolInformationDesignatedContactBySchoolCode<RootState>(
							SchoolsDetailApiFactory,
							payload,
							undefined,
							'',
							true
						)
					),
				]);

				// if (debug) apiUtils.debugApiParallelResults(res);
				// apiUtils.debugApiParallelResults(res)

				// const result = res.map((res) => res.data);

				//console.log(`FLAT RESULTS ${result.flat()}`);
				dispatch(actions.getSchoolCohortBatchSuccess([]));
			} catch (err) {
				console.log(`Cohort Slice ERROR ${err}`);
				throw Error('Promise failed');
			}
		} catch (err) {
			console.log(`SchoolCohortBatch ERROR ${err}`);
			dispatch(actions.getSchoolCohortBatchFailure(err));
		}
	};
function toggleBackdrop(arg0: boolean, arg1: string, arg2: string): any {
	throw new Error('Function not implemented.');
}
