import { ThemeOptions } from '@mui/material';

// Colors

const neutral = {
  100: '#F3F4F6',
  200: '#E5E7EB',
  300: '#D1D5DB',
  400: '#9CA3AF',
  500: '#6B7280',
  600: '#4B5563',
  700: '#374151',
  800: '#1F2937',
  900: '#111827',
	111: '#5E6C84'
};

const chart_secondary = 'rgba(75, 88, 162, 1)'; // #4B58A2 - generated

const background = {
  default: 'rgba(23, 24, 24, 1)', // '#171818'
  paper: neutral[900],
  highlight: 'rgba(174, 176, 177, 1)', // #AEB0B1 TODO: replace with background.highlight - generated
};

const divider = '#F2F4F7';
const emphasis = 'rgba(8, 62, 104, 1)'; // #083E68 - generated

const primary = {
  main: '#7582EB',
  light: 'rgba(42, 44, 46, 1)', // #2A2C2E
  dark: 'rgba(231, 245, 255, 1)', // #E7F5FF
  contrastText:  'rgba(0, 0, 0, 1)', // #000000
  accent: 'rgba(65, 67, 167, 1)', // #4143A7 - generated
};

const secondary = {
  main: 'rgba(255, 255, 255, 1)', // #FFFFFF
  light: 'rgba(180, 181, 194, 1)', // #B4B5C2
  dark: 'rgba(255, 255, 255, 1)', //  #FFFFFF
  contrastText:  'rgba(0, 0, 0, 1)', // #000000
};

const userGreeting = {
  text: '#344563',
  background: '#F9FAFC'
}

const success = {
  main: '#14B8A6',
  light: '#43C6B7',
  dark: '#0E8074',
  contrastText:  'rgba(0, 0, 0, 1)', // #000000
};

const info = {
  main: '#2196F3',
  light: '#64B6F7',
  dark: '#0B79D0',
  contrastText: 'rgba(0, 0, 0, 1)', // #000000
};

const warning = {
  main: '#FFB020',
  light: '#FFBF4C',
  dark: '#B27B16',
  contrastText:  'rgba(0, 0, 0, 1)', // #000000
};

const error = {
  main: '#D14343',
  light: 'rgba(175, 87, 78, 1)', // #AF574E - generated
  dark: '#922E2E',
  contrastText: 'rgba(0, 0, 0, 1)', // #000000
};

const text = {
  main: 'rgba(255, 255, 255, 1)', // #FFFFFF
  primary: '#EDF2F7',
  secondary: '#A0AEC0',
  disabled: 'rgba(255, 255, 255, 0.48)',
  textinfo: 'rgba(0, 30, 61, 1)', // #001E3D
  contrastText: 'rgba(0, 0, 0, 1)', // #000000
};

export const darkThemeOptions: ThemeOptions = {
  components: {
    MuiAvatar: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[500],
          color: '#FFFFFF'
        }
      }
    },
    MuiChip: {
      styleOverrides: {
        root: {
          '&.MuiChip-filledDefault': {
            backgroundColor: neutral[800],
            '& .MuiChip-deleteIcon': {
              color: neutral[500]
            }
          },
          '&.MuiChip-outlinedDefault': {
            borderColor: neutral[700],
            '& .MuiChip-deleteIcon': {
              color: neutral[700]
            }
          }
        }
      }
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          '&::placeholder': {
            opacity: 1,
            color: text.secondary
          }
        }
      }
    },
    MuiOutlinedInput: {
      styleOverrides: {
        notchedOutline: {
          borderColor: divider
        }
      }
    },
    MuiMenu: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1
        }
      }
    },
    MuiPopover: {
      styleOverrides: {
        paper: {
          borderColor: divider,
          borderStyle: 'solid',
          borderWidth: 1
        }
      }
    },
    MuiSwitch: {
      styleOverrides: {
        switchBase: {
          color: neutral[700]
        },
        track: {
          backgroundColor: neutral[500],
          opacity: 1
        }
      }
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          borderBottom: `1px solid ${divider}`
        }
      }
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          backgroundColor: neutral[800],
          '.MuiTableCell-root': {
            color: neutral[300]
          }
        }
      }
    }
  },
  palette: {
    action: {
      active: neutral[400],
      hover: 'rgba(255, 255, 255, 0.04)',
      selected: 'rgba(255, 255, 255, 0.08)',
      disabledBackground: 'rgba(255, 255, 255, 0.12)',
      disabled: 'rgba(255, 255, 255, 0.26)'
    },
    background,
    divider,
    error,
    info,
    mode: 'dark',
    neutral,
    primary,
    secondary,
    success,
    text,
    warning,
    userGreeting,
    chart_secondary,
    emphasis,
  },
  shadows: [
    'none',
    '0px 1px 2px rgba(0, 0, 0, 0.24)',
    '0px 1px 2px rgba(0, 0, 0, 0.24)',
    '0px 1px 4px rgba(0, 0, 0, 0.24)',
    '0px 1px 5px rgba(0, 0, 0, 0.24)',
    '0px 1px 6px rgba(0, 0, 0, 0.24)',
    '0px 2px 6px rgba(0, 0, 0, 0.24)',
    '0px 3px 6px rgba(0, 0, 0, 0.24)',
    '0px 4px 6px rgba(0, 0, 0, 0.24)',
    '0px 5px 12px rgba(0, 0, 0, 0.24)',
    '0px 5px 14px rgba(0, 0, 0, 0.24)',
    '0px 5px 15px rgba(0, 0, 0, 0.24)',
    '0px 6px 15px rgba(0, 0, 0, 0.24)',
    '0px 7px 15px rgba(0, 0, 0, 0.24)',
    '0px 8px 15px rgba(0, 0, 0, 0.24)',
    '0px 9px 15px rgba(0, 0, 0, 0.24)',
    '0px 10px 15px rgba(0, 0, 0, 0.24)',
    '0px 12px 22px -8px rgba(0, 0, 0, 0.24)',
    '0px 13px 22px -8px rgba(0, 0, 0, 0.24)',
    '0px 14px 24px -8px rgba(0, 0, 0, 0.24)',
    '0px 20px 25px rgba(0, 0, 0, 0.24)',
    '0px 25px 50px rgba(0, 0, 0, 0.24)',
    '0px 25px 50px rgba(0, 0, 0, 0.24)',
    '2px 4px 10px 0px rgba(255, 255, 255, 0.1)',
    '2px 4px 10px 0px rgba(255, 255, 255, 0.2)',
  ]
};
