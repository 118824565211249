import { customLogger } from 'src';
import { ColorEnum, ColorLog, CustomLogColor } from 'src/utils/color-log.util';

/***
 * 
 */
export const useLog = (message: string, color: ColorEnum, prefix: string = 'App',  customLogColor?: CustomLogColor, enabled: boolean = true) => {
  
	if(enabled) {
		customLogger.log(message, color, prefix, customLogColor);
	}
	
};

 