import React, { FC, ReactFragment } from 'react';
import { Box, Backdrop, Fab, CircularProgress, Typography, Avatar } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import DownloadingIcon from '@mui/icons-material/Downloading';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import SaveIcon from '@mui/icons-material/Save';
import DeleteIcon from '@mui/icons-material/Delete';
import { GlobalBackdropProps } from '..';



/**
 *
 * @param props
 * @returns
 */
const GlobalBackdrop: FC<GlobalBackdropProps> = (props) => {
	const theme = useTheme();
	const { title, subTitle, iconType, visible } = props;

	let backdropIcon = <DownloadingIcon fontSize='large'></DownloadingIcon>;

	switch (iconType) {
		case 'authenticate':
			backdropIcon = <AccountCircleIcon fontSize='large'></AccountCircleIcon>;
			break;
		case 'delete':
			backdropIcon = <DeleteIcon fontSize='large'></DeleteIcon>;
			break;
		case 'load':
			backdropIcon = <DownloadingIcon fontSize='large'></DownloadingIcon>;
			break;
		case 'save':
			backdropIcon = <SaveIcon fontSize='large'></SaveIcon>;
			break;
	}
	// if(!backdropProps){
	//   return(<></>);
	// }

	return (
		<Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.snackbar - 1 }} open={visible}>
			<Box
				sx={{
					display: 'flex',
					alignItems: 'center',
					flexDirection: 'column',
				}}>
				<Box sx={{ m: 1, position: 'relative' }}>
					<Fab aria-label='loading' color='secondary' sx={{ fontSize: 'large' }}>
						{backdropIcon}
					</Fab>
					{visible && (
						<CircularProgress
							color='primary'
							size={68}
							sx={{
								// color: "green[500]",
								position: 'absolute',
								top: -6,
								left: -6,
								zIndex: 1,
							}}
						/>
					)}
				</Box>
				<Box sx={{ m: 1, position: 'relative' }}>
					<Typography variant='subtitle1'>{title}</Typography>
					<Typography variant='subtitle2'>{subTitle}</Typography>
				</Box>
			</Box>
		</Backdrop>
	);
};

export default GlobalBackdrop;
