import PropTypes from 'prop-types';
import { styled, useTheme } from '@mui/material/styles';

type Variant = 'light' | 'primary';

interface LogoProps {
	variant?: Variant;
	width?: number;
}

export const SrsLogo = styled((props: LogoProps) => {
	const { variant, width = 80, ...other } = props;

	const theme = useTheme();
	const color = theme.palette.neutral[300];

	return (
		<svg xmlns='http://www.w3.org/2000/svg' width={width} version='1.1' id='Layer_1' x='0' y='0' viewBox='0 0 396 216'>
			<path
				d='M375.7 196.8h-18.2c0-87.9-71.5-159.4-159.4-159.4S38.6 108.9 38.6 196.8H20.3c0-98 79.7-177.7 177.7-177.7 98 .1 177.7 79.8 177.7 177.7z'
				fill={color}></path>
			<path
				d='M114.8 158.1v37h-11.5v-26l-7.9 3.4-4-8.5 14.2-5.9h9.2zm38.6 0v37h-11.5v-26l-7.9 3.4-4-8.5 14.2-5.9h9.2zm23.2 6.5c0-4 2.6-6.2 7.1-6.2s7.3 2.1 7.3 6.2c0 4.2-2.8 6.6-7.3 6.6-4.6 0-7.1-2.4-7.1-6.6zm0 24.4c0-4 2.6-6.2 7.1-6.2s7.3 2.1 7.3 6.2c0 4.2-2.8 6.6-7.3 6.6-4.6 0-7.1-2.4-7.1-6.6zm74.6-6.2c0 8.6-5.8 13.5-20.5 13.5-10 0-14.8-1.1-20.2-4.8l5.6-8.5c3.7 2.7 6.9 2.8 14 2.8 7.9 0 9.6-.9 9.6-2.6 0-1.6-1.3-2-4.7-2h-20.7v-22.9H248v10.5h-22.7v3.1h11.8c9.6-.2 14.1 3.1 14.1 10.9zm57.2-7v1.7c0 11.5-6.1 18.7-22.5 18.7-8.9 0-14.6-1-20.2-6.5l8.2-7.2c2 2.8 6.2 2.9 11.4 2.9 6.6 0 11.4-1.5 11.6-6.6-2.6 2.1-7.2 3.2-14.6 3.2-11 0-16.7-3.5-16.7-12 0-10.5 9.1-13 20.4-13 16.5.1 22.4 7.3 22.4 18.8zm-13.2-5.2c0-2-3.3-2.7-9.2-2.7-5.9 0-9 .8-9 2.7 0 2 3.1 2.8 9 2.8 5.9-.1 9.2-.9 9.2-2.8z'
				fill={color}></path>
		</svg>
		
	);
})``;

SrsLogo.defaultProps = {
	variant: 'primary',
};

SrsLogo.propTypes = {
	variant: PropTypes.oneOf<Variant>(['light', 'primary']),
};
