/**
 *
 */
export enum ImmunizationReportingStep {
	information = 'information',
	childcare = 'childcare',
	kindergarten = 'kindergarten',
	firstGrade = 'first_grade',
	seventhGrade = 'seventh_grade',
	eighthGrade = 'eighth_grade',
	viewPrintReport = 'view_print_report',
}
