import { Box, Chip, Stack, Typography } from '@mui/material';
import { FC, ReactNode } from 'react';
import HomeIcon from '@mui/icons-material/Home';
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { DashboardLayout } from '../components/layouts';
import AccountIcons from 'src/components/layouts/dashboard/navbar-content/account-icons';
import { LayoutProps } from '.';
import { useNewAuth } from 'src/hooks/use-auth-new';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import ExternalLinkIcons from 'src/components/layouts/dashboard/navbar-content/external-link-icons';
import CommonNavbarView from 'src/components/layouts/common/common-navbar-view';

const DefaultDashboardLayout: FC<LayoutProps> = (props) => {
	const { children } = props;
	return (
		<DashboardLayout useAuthHook={useNewAuth} sidebarSections={defaultSections} navbarChildren={<CommonNavbarView />}>
			{children}
		</DashboardLayout>
	);
};

export default DefaultDashboardLayout;

/**
 * Component Data
 */
const defaultSections = [
	{
		title: 'General',
		items: [
			{
				title: 'Dashboard',
				path: '/dashboard',
				icon: <HomeIcon fontSize='small' />,
			},
			{
				title: 'SchoolRegistry',
				path: '/school-registry',
				icon: <HomeIcon fontSize='small' />,
			},
			{
				title: 'Reports',
				path: '/dashboard/Reports',
				icon: <AssessmentIcon fontSize='small' />,
				chip: (
					<Chip
						color='secondary'
						label={
							<Typography
								sx={{
									fontSize: '10px',
									fontWeight: '600',
								}}>
								NEW
							</Typography>
						}
						size='small'
					/>
				),
			},
		],
	},
	{
		title: 'Management',
		items: [
			{
				title: 'Grades',
				path: '/dashboard/grade',
				icon: <AssignmentIndIcon fontSize='small' />,
				children: [
					{
						title: 'All',
						path: '/dashboard/grade/home',
					},
					{
						title: 'Pre K',
						path: '/dashboard/grade/pre-k',
					},
					{
						title: 'Third',
						path: '/dashboard/grade/3',
					},
				],
			},
		],
	},
];
