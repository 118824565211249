import { ThemeOptions } from '@mui/material';

// Colors
const neutral = {
	100: '#F3F4F6',
	200: '#E5E7EB',
	300: '#5E6C84',
	400: '#9CA3AF',
	500: '#6B7280',
	600: '#4B5563',
	700: '#374151',
	800: '#FFFFFF',
	900: '#111827',
	111: '#5E6C84'
};

const chart_secondary = "#6B7EE8";

const background = {
	default: 'rgba(249, 251, 253, 1)', // #F9FBFD
	paper: '#FFFFFF',
	highlight: 'rgba(246, 247,249, 1)', // #F6F7F9
};

const divider = '#A1B3D0';
const emphasis = '#0C5894';

const primary = {
	main: '#0f75bd',
	light: 'rgba(238, 248, 254, 1)', // #EEF8FE
	dark: 'rgba(8, 65, 106, 1)',  // #08416A
	contrastText: 'rgba(255, 255, 255, 1)', // #FFFFFF
	accent: '#5D5FEF'
};

const secondary = {
	main: 'rgba(69, 85, 104, 1)', // #455568 
	light: 'rgba(223, 224, 235, 1)', // #DFE0EB
	dark: 'rgba(52, 69, 99, 1)', // #344563
	contrastText:  'rgba(255, 255, 255, 1)', // #FFFFFF
};

const userGreeting = {
	text: '#344563',
	background: '#F9FAFC'
  }

const success = {
	main: '#2ca55a',
	light: '#43C6B7',
	dark: '#0E8074',
	contrastText:  'rgba(255, 255, 255, 1)', // #FFFFFF
};

const info = {
	main: '#BCE4FA',
	light: '#64B6F7',
	dark: '#0B79D0',
	contrastText: 'rgba(255, 255, 255, 1)', // #FFFFFF
};

const warning = {
	main: '#f56d03',
	light: '#FFBF4C',
	dark: '#B27B16',
	contrastText: '#FFFFFF',
};

const error = {
	main: '#d82f2f',
	light: 'rgba(250, 124,112, 1)', // #FA7C70
	dark: '#972020',
	contrastText: '#ffffff',
};

const text = {
	main: '#343434',
	primary: '#344563',
	secondary: '#4F5D74',
	disabled: '#8195B8',
	textinfo: '#002B57',
	contrastText: '#ffffff',
};

export const lightThemeOptions: ThemeOptions = {
	components: {
		MuiAvatar: {
			styleOverrides: {
				root: {
					backgroundColor: neutral[500],
					color: '#FFFFFF',
				},
			},
		},
		MuiChip: {
			styleOverrides: {
				root: {
					'&.MuiChip-filledDefault': {
						backgroundColor: 'rgb(223, 224, 235)',
						color: neutral[300],
						'& .MuiChip-deleteIcon': {
							color: neutral[400],
						},
					},
					'&.MuiChip-outlinedDefault': {
						'& .MuiChip-deleteIcon': {
							color: neutral[300],
						},
					},
				},
			},
		},
		MuiInputBase: {
			styleOverrides: {
				input: {
					'&::placeholder': {
						opacity: 1,
						color: text.secondary,
					},
				},
			},
		},
		MuiOutlinedInput: {
			styleOverrides: {
				notchedOutline: {
					borderColor: divider,
				},
			},
		},
		MuiMenu: {
			styleOverrides: {
				paper: {
					borderColor: divider,
					borderStyle: 'solid',
					borderWidth: 1,
				},
			},
		},
		MuiPopover: {
			styleOverrides: {
				paper: {
					borderColor: divider,
					borderStyle: 'solid',
					borderWidth: 1,
				},
			},
		},
		MuiSwitch: {
			styleOverrides: {
				switchBase: {
					color: neutral[500],
				},
				track: {
					backgroundColor: neutral[400],
					opacity: 1,
				},
			},
		},
		MuiTableCell: {
			styleOverrides: {
				root: {
					borderBottom: `1px solid ${divider}`,
				},
			},
		},
		MuiTableHead: {
			styleOverrides: {
				root: {
					backgroundColor: neutral[100],
					'.MuiTableCell-root': {
						color: neutral[700],
					},
				},
			},
		},
	},
	palette: {
		action: {
			active: neutral[500],
			focus: 'rgba(55, 65, 81, 0.12)',
			hover: 'rgba(55, 65, 81, 0.04)',
			selected: 'rgba(55, 65, 81, 0.08)',
			disabledBackground: 'rgba(55, 65, 81, 0.12)',
			disabled: 'rgba(55, 65, 81, 0.26)',
		},
		background,
		divider,
		error,
		info,
		mode: 'light',
		neutral,
		primary,
		secondary,
		success,
		text,
		warning,
		emphasis,
		chart_secondary,
		userGreeting
	},
	shadows: [
		'none',
		'0px 1px 1px rgba(100, 116, 139, 0.06), 0px 1px 2px rgba(100, 116, 139, 0.1)',
		'0px 1px 2px rgba(100, 116, 139, 0.12)',
		'0px 1px 4px rgba(100, 116, 139, 0.12)',
		'0px 1px 5px rgba(100, 116, 139, 0.12)',
		'0px 1px 6px rgba(100, 116, 139, 0.12)',
		'0px 2px 6px rgba(100, 116, 139, 0.12)',
		'0px 3px 6px rgba(100, 116, 139, 0.12)',
		'0px 2px 4px rgba(31, 41, 55, 0.06), 0px 4px 6px rgba(100, 116, 139, 0.12)',
		'0px 5px 12px rgba(100, 116, 139, 0.12)',
		'0px 5px 14px rgba(100, 116, 139, 0.12)',
		'0px 5px 15px rgba(100, 116, 139, 0.12)',
		'0px 6px 15px rgba(100, 116, 139, 0.12)',
		'0px 7px 15px rgba(100, 116, 139, 0.12)',
		'0px 8px 15px rgba(100, 116, 139, 0.12)',
		'0px 9px 15px rgba(100, 116, 139, 0.12)',
		'0px 10px 15px rgba(100, 116, 139, 0.12)',
		'0px 12px 22px -8px rgba(100, 116, 139, 0.25)',
		'0px 13px 22px -8px rgba(100, 116, 139, 0.25)',
		'0px 14px 24px -8px rgba(100, 116, 139, 0.25)',
		'0px 10px 10px rgba(31, 41, 55, 0.04), 0px 20px 25px rgba(31, 41, 55, 0.1)',
		'0px 25px 50px rgba(100, 116, 139, 0.25)',
		'0px 25px 50px rgba(100, 116, 139, 0.25)',
		'2px 4px 10px 0px rgba(0, 60, 179, 0.05)', // 23 - top navbar boxShadow
		'2px 4px 10px 0px rgba(0, 60, 179, 0.13)', // 24 - sidebar boxShadow
	],
};
