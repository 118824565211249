import { combineReducers } from '@reduxjs/toolkit';
import { globalReducer } from './slices/global/global-app.slice';
import { reducer as startUpReducer } from './slices/start-up';
import { reducer as schoolUpdatesReducer } from './slices/school-update';
import selectedSchoolReducer from './slices/school-assessment/selected-school.slice';
import schoolInfoReducer from './slices/school-assessment/school-info.slice';
import { reducer as reportingDashboardReducer } from './slices/reporting-dashboard';
import { sRSFeatureReducers } from '@the-mcorp/elevenfiftynine-srs-lib';
import { reducer as schoolCohortBatchReducer } from './slices/school-cohort-batch';
import { reducer as saveSchoolInformationBatchReducer } from './slices/school-information';
import { reducer as addUserSchoolBatchMessageReducer } from './slices/user-school-assignment';
import tempMarkersReducer from './slices/temp-markers/temp-markers.slice';
import userAppCurrentStatesReducer from './slices/user-app-current-states/user-app-current-states.slice';
import { reducer as schoolCohortSaveSelectors } from './slices/school-cohort';
/**
 *
 */
const rootReducer = combineReducers({
	srsApi_SchoolsDetail: sRSFeatureReducers.schoolsDetail,
	srsApi_DataLookup: sRSFeatureReducers.dataLookup,
	srsApi_Dashboard: sRSFeatureReducers.dashboard,
	srsApi_User: sRSFeatureReducers.user,
	startUp: startUpReducer,
	schoolUpdates: schoolUpdatesReducer,
	global: globalReducer,
	reportingDashboard: reportingDashboardReducer,
	selectedSchool: selectedSchoolReducer,
	selectedSchoolDetailedInfo: schoolInfoReducer,
	schoolCohortBatch: schoolCohortBatchReducer,
	schoolInformationSaveBatch: saveSchoolInformationBatchReducer,
	schoolCohortSave: schoolCohortSaveSelectors,
	userSchoolAssignmentBatch: addUserSchoolBatchMessageReducer,
	tempMarkers: tempMarkersReducer,
	userAppCurrentStates: userAppCurrentStatesReducer,
	srs_Announcements: sRSFeatureReducers.annoucements,
	srs_ClosureGroups: sRSFeatureReducers.closureGroups,
});

export default rootReducer;
