import type { FC, ReactNode } from 'react';
import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { AppBar, Box, Button, Chip, Divider, IconButton, Link, Stack, Toolbar } from '@mui/material';
import { Menu } from '../../../icons/menu';
 
import {MainNavbarProps} from '../main-layout.types';
   
const MainNavbar: FC<MainNavbarProps> = (props) => {
	const { onSidebarMobileOpen, children, logo } = props;

	return (
		<AppBar
			elevation={0}
			sx={{
				backgroundColor: 'background.paper',
				color: 'text.secondary',
			}}>
			<Toolbar sx={{ minHeight: 64 }}>
				<Stack direction={'row'} gap={1} alignItems={'flex-start'} sx={{ width: (Number(process.env.REACT_APP_LAYOUT_SIDEBAR_WIDTH)-20)+'px' }}>
					<IconButton
						color='inherit'
						onClick={onSidebarMobileOpen}
						sx={{
							display: {
								md: 'none',
							},
						}}>
						<Menu fontSize='small' />
					</IconButton>
					{logo ? logo : <></>}
				</Stack>
				<Box sx={{ flexGrow: 1 }} />
				<Box
					sx={{
						alignItems: 'center',
						display: {
							md: 'flex',
							xs: 'none',
						},
					}}>
					{children ? children : <></>}
				</Box>
			</Toolbar>
			<Divider />
		</AppBar>
	);
};

MainNavbar.propTypes = {
	onSidebarMobileOpen: PropTypes.func,
};

export default MainNavbar;
