import { FC } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Alert, Box, IconButton, Stack, Toolbar, Typography } from '@mui/material';
import { Menu as MenuIcon } from '../../../icons/menu';
import { AppLogo } from '../../app-logo';
import { DashboardNavbarProps } from '../dashboard-layout.types';
import { DashboardNavbarRoot } from '../dashboard-layout.styles';
import WarningRoundedIcon from '@mui/icons-material/WarningRounded';
import { RootState, useSelector } from 'src/store';
import { AnnoucementViewModel, sRSFeatureEntityAdapters } from '@the-mcorp/elevenfiftynine-srs-lib';
import { useTheme } from '@mui/material/styles';

const DashboardNavbar: FC<DashboardNavbarProps> = (props) => {
	const { onOpenSidebar: onSidebarMobileOpen, children, ...other } = props;
	const sidebarWidth = Number(process.env.REACT_APP_LAYOUT_SIDEBAR_WIDTH);
	const sidebarTopPadding = Number(process.env.REACT_APP_LAYOUT_SIDEBAR_TOP);
	const theme = useTheme();
	const announcementSelectors = sRSFeatureEntityAdapters.annoucements.annoucements.getSelectors<RootState>(
		(state) => state.srs_Announcements.annoucements
	);
	const announcements = useSelector(announcementSelectors.selectAll) as any[];
	return (
		<DashboardNavbarRoot
			sx={{
				display: 'inline-flex',
				flexDirection: 'row',
				justifyContent: 'space-between',
				alignItems: 'center',
				background: 'white',
				borderRadius: 0,
				minWidth: '100%',
				padding: '0 0 0 0',
			}}
			{...other}>
			<Stack gap={0} direction={'column'} sx={{ width: '100%', m: 0, p: 0 }}>
				{announcements.length > 0 ? (
					<Stack>
						<Alert
							icon={false}
							variant='filled'
							severity='error'
							sx={{
								width: '100%',
								borderRadius: 0,
								textAlign: 'center',
								display: 'block',
								backgroundColor: '#B11616',
							}}>
							<Stack direction={'row'} alignItems='center' alignContent={'center'} display={'inline-flex'} gap={2}>
								<WarningRoundedIcon />
								<Box>
									{announcements
										.filter((x) => x)
										.map((announcement: AnnoucementViewModel) => (
											<Typography>
												{' '}
												{announcement.announcementDescription ?? announcement.announcementTitle}{' '}
											</Typography>
										))}
								</Box>
							</Stack>
						</Alert>
					</Stack>
				) : null}

				<Stack
					direction={'row'}
					sx={{
						padding: '0 0 0 1rem',
						borderBottom: '1px solid',
						borderColor: 'secondary.light',
						backgroundColor: 'background.paper',
						boxShadow: theme.shadows[23],
					}}
					justifyContent={'space-between'}>
					<Stack
						direction={'row'}
						gap={1}
						alignItems={'flex-start'}
						sx={{ width: Number(process.env.REACT_APP_LAYOUT_SIDEBAR_WIDTH) - 20 + 'px', pt: '12px' }}>
						<IconButton
							color='primary'
							onClick={onSidebarMobileOpen}
							sx={{
								display: {
									lg: 'none',
								},
							}}>
							<MenuIcon fontSize='small' />
						</IconButton>
						<AppLogo />
					</Stack>
					<Toolbar
						disableGutters
						sx={{
							borderRadius: 4,
							minHeight: sidebarTopPadding,
							left: 0,
							px: 2,
						}}>
						<Box
							sx={{
								flexGrow: 1,
								ml: 2,
							}}
						/>
						{children ? (
							children
						) : (
							<>
								{/*  
					<Box sx={{ ml: 2 }}>
					<SomeDefaultComponent />
					</Box> */}
							</>
						)}
					</Toolbar>
				</Stack>
			</Stack>
		</DashboardNavbarRoot>
	);
};

DashboardNavbar.propTypes = {
	onOpenSidebar: PropTypes.func,
};

export default DashboardNavbar;
