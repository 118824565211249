import { useEffect, FC } from 'react';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { SrsLogo } from 'src/components/srs-logo';
import { default as packageJson } from 'src/../package.json';
import _ from 'lodash';
import { fontSize } from '@mui/system';
import { browserName, browserVersion, isEdge, isChrome, isMobile } from 'react-device-detect';

export interface CommonFooterViewProps {
	showVertical?: boolean;
}

/**
 *
 * @param props
 * @returns
 */
const CommonFooterView: FC<CommonFooterViewProps> = (props) => {
	const { showVertical = false } = props;

	const theme = useTheme();
	const deploymentDateTime = packageJson.buildDate;
	const deploymentEnv = process.env.REACT_APP_ENV.toUpperCase();
	const appVersion = process.env.REACT_APP_VERSION;
	const iconSize = 76;

	return (
		<>
			<Box sx={{ backgroundColor: 'background.default' }}>
				<Container maxWidth='lg'>
					<Typography
						mt={2}
						mb={2}
						color='text.secondary'
						variant='caption'
						textAlign={'center'}
						display={'flex'}
						justifyContent={'center'}>
						<Grid container alignContent={'center'} direction={'column'} justifyContent='flex-start' spacing={1}>
							{showVertical ? (
								<>
									<Grid item>
										<SrsLogo width={iconSize} />
									</Grid>
									<Grid item>
										Powered by <b>1159.ai</b> • California Department of Public Health, Immunization Branch in
										accordance with California Health and Safety Code
									</Grid>{' '}
								</>
							) : (
								<Grid item>
									<Stack
										sx={{ minWidth: 866 }}
										direction={'row'}
										alignContent={'flex-end'}
										alignItems={'flex-end'}
										textAlign={'end'}
										gap={1}>
										<span style={{ fontSize: '11px', fontWeight: 800 }}>Powered by</span>
										<SrsLogo sx={{ p: 0 }} width={iconSize} /> <span style={{ fontWeight: 800 }}>•</span> California
										Department of Public Health, Immunization Branch in accordance with California Health and Safety
										Code.
									</Stack>
								</Grid>
							)}
							<Grid item>
								<Stack direction={'column'} alignContent={'center'} alignItems={'center'} textAlign={'center'}>
									<Box component={'span'} color='primary.main' textAlign={'center'} gap={0}>
										Version: <b>{appVersion}</b>
									</Box>
									<Box component={'span'} color='divider' textAlign={'center'} gap={0}>
										Release Date: {deploymentDateTime} • Environment: {deploymentEnv} • Browser: {browserName}{' '}
										{`v${browserVersion} (${isMobile ? 'Mobile' : 'Desktop'})`}
									</Box>
								</Stack>
							</Grid>
						</Grid>
					</Typography>
				</Container>
			</Box>
		</>
	);
};

export default CommonFooterView;
