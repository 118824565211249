import { ImmunizationReportingStep } from "src/enums/immunization-dashboard.enums";

/**
 * 
 */
export const STEP_STATUSES = {
	IN_PROGRESS: 'In Progress', // blue for saved or in-progress
	COMPLETED: 'Completed', // green
	PRISTINE: 'Not Started', // grey
	SUBMITTED: 'Submitted', // green
};


/**
 * 
 */
export const SRS_APP_CONSTANTS = {
	information: {
		displayName: 'Information',
		secondaryDisplayName: 'Information',
		type: 'information',
		shortCode: null,
		step: ImmunizationReportingStep.information
	},
	childCare: {
		displayName: 'Pre-K/Child Care Report',
		secondaryDisplayName: 'Pre-K/Child Care',
		type: 'childCareReport',
		shortCode: 'C',
		step: ImmunizationReportingStep.childcare
	},
	kindergarten: {
		displayName: 'TK/Kindergarten Report',
		secondaryDisplayName: 'TK/Kindergarten',
		type: 'kindergarten',
		shortCode: 'K',
		step: ImmunizationReportingStep.kindergarten
	},
	firstGrade: {
		displayName: '1st Grade Report',
		secondaryDisplayName: '1st Grade',
		type: 'firstGrade',
		shortCode: 'F',
		step: ImmunizationReportingStep.firstGrade
	},
	seventhGrade: {
		displayName: 'Seventh Grade Report',
		secondaryDisplayName: 'Seventh Grade',
		type: 'seventhGrade',
		shortCode: 'S',
		step: ImmunizationReportingStep.seventhGrade
	},
	eighthGrade: {
		displayName: 'Eighth Grade Report',
		secondaryDisplayName: 'Eighth Grade',
		type: 'eighthGrade',
		shortCode: 'E',
		step: ImmunizationReportingStep.eighthGrade
	},
	viewPrintReport: {
		displayName: 'View & Print Report',
		secondaryDisplayName: 'View & Print Report',
		type: 'viewPrintReport',
		shortCode: null,
		step: ImmunizationReportingStep.viewPrintReport
	},
};
 

/**
 * 
 */
export const VALID_COHORTS = {
	[SRS_APP_CONSTANTS.childCare.shortCode]: SRS_APP_CONSTANTS.childCare,
	[SRS_APP_CONSTANTS.kindergarten.shortCode]: SRS_APP_CONSTANTS.kindergarten,
	[SRS_APP_CONSTANTS.firstGrade.shortCode]: SRS_APP_CONSTANTS.firstGrade,
	[SRS_APP_CONSTANTS.seventhGrade.shortCode]: SRS_APP_CONSTANTS.seventhGrade,
	[SRS_APP_CONSTANTS.eighthGrade.shortCode]: SRS_APP_CONSTANTS.eighthGrade,
};