import { createSvgIcon } from '@mui/material/utils';

export const BubbleIcon = createSvgIcon(
<svg width="24" height="24" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M17.3207 16.7363C17.3985 17.0865 17.0861 17.399 16.7358 17.3212L12.7602 16.4377C11.6049 17.023 10.3239 17.3328 8.99918 17.3328C4.3969 17.3328 0.666016 13.6019 0.666016 8.99967C0.666016 4.39739 4.3969 0.666504 8.99918 0.666504C13.6015 0.666504 17.3323 4.39739 17.3323 8.99967C17.3323 10.3244 17.0226 11.6054 16.4372 12.7607L17.3207 16.7363Z" />
</svg>,
'BubbleIcon'
);




