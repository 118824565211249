import type { FC, ReactNode } from 'react';
import { useIsAuthenticated } from '@azure/msal-react';
import { Navigate, useNavigate } from 'react-router-dom';
import { useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { CircularProgress } from '../controls/feedback/progress/circular-progress';
import UserValidationStatusView from 'src/sections/main/home/user-validation-status-view';
import StatusView from 'src/sections/main/startup/status-view';
import { appLogPrefixes } from 'src/config/app-config';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';

interface AuthGuardProps {
	children: ReactNode;
}

const AuthGuard: FC<AuthGuardProps> = (props) => {
	const { children } = props;
	const { inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();

	const navigate = useNavigate();

	// https://github.com/AzureAD/microsoft-authentication-library-for-js/blob/dev/lib/msal-react/docs/hooks.md#usemsal-hook
	if (inProgress === InteractionStatus.None) {
  
		if (isAuthenticated) {
			return <>{children}</>;
		} else {
			useLog("AUTH Gaurd Activated", ColorEnum.WARNING, appLogPrefixes.guard)
			navigate('/', { replace: true });
			// navigate(process.env.REACT_APP_AUTH_NOT_AUTHORIZED, { replace: true });
			return;
		}
	} else if (inProgress === InteractionStatus.Logout) {
		return <StatusView statusTitle={'Logging Out'} statusDescription={'Logging user out of application...'} />;
	} else {
		return <UserValidationStatusView />;
	}
};

export default AuthGuard;
