import yup from './yupUtil';
import {
	validateZeroEnrollmentReason,
	validateToBeLessThanOrEqualToFieldValue,
	validateToBeExactlyEqualToFieldValue,
	validateVaccineRangeToFieldValue,
	validateForIntValues,
	validateEnrolledField,
} from './commonFormValidation';

// schema declaration..
export const kindergartenStepFormSchema = yup.object().shape({
	studentMissingCount:
		validateVaccineRangeToFieldValue('sumMissingVaccines', 1, true) ||
		validateVaccineRangeToFieldValue('sumMissingVaccines', 5, true),
	sumMissingVaccines: yup.number(),
	sumStudentsEnteredCount: validateToBeExactlyEqualToFieldValue('enrolledStudentCount'),
	enrolledStudentCount: validateEnrolledField(),
	zeroEnrollmentReason: validateZeroEnrollmentReason('enrolledStudentCount'),
	reqVaccineDosesCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	permMedicalExamCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	otherIEPServicesCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	otherIndependentStudyCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	otherHomeBasedPrivateSchoolCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	condMissingCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	tempMedicalExempCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	polioCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	dtapCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	hepBCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	overDueCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	mmrCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	varicellaCount: validateToBeLessThanOrEqualToFieldValue('studentMissingCount'),
	metadata: yup.object().shape({
		submittedDate: yup.string(),
		revisedDate: yup.string(),
	}),
});

export const kindergartenStepIntValues = yup.object().shape({
	enrolledStudentCount: validateForIntValues(),
	reqVaccineDosesCount: validateForIntValues(),
	permMedicalExamCount: validateForIntValues(),
	otherIEPServicesCount: validateForIntValues(),
	otherIndependentStudyCount: validateForIntValues(),
	otherHomeBasedPrivateSchoolCount: validateForIntValues(),
	condMissingCount: validateForIntValues(),
	tempMedicalExempCount: validateForIntValues(),
	polioCount: validateForIntValues(),
	dtapCount: validateForIntValues(),
	hepBCount: validateForIntValues(),
	overDueCount: validateForIntValues(),
	mmrCount: validateForIntValues(),
	varicellaCount: validateForIntValues(),
});

// form initialization..
export const kindergartenStepFormModel = {
	studentMissingCount: 0,
	sumStudentsEnteredCount: 0,
	sumMissingVaccines: 0,
	enrolledStudentCount: '',
	zeroEnrollmentReason: '',
	reqVaccineDosesCount: 0,
	permMedicalExamCount: 0,

	otherIEPServicesCount: 0,
	polioCount: 0,
	dtapCount: 0,
	condMissingCount: 0,
	mmrCount: 0,
	tempMedicalExempCount: 0,
	otherIndependentStudyCount: 0,
	otherHomeBasedPrivateSchoolCount: 0,
	hepBCount: 0,
	overDueCount: 0,
	varicellaCount: 0,
	metadata: {
		submittedDate: '',
		revisedDate: '',
	},
};
