import yup from './yupUtil';

export const validateToBeLessThanOrEqualToFieldValue = (fieldName: string) => {
	return (yup.mixed() as any).validateFieldValueBasedOnValueAtPath(fieldName);
};

export const validateToBeExactlyEqualToFieldValue = (fieldName: string) => {
	return (yup.mixed() as any).validateToBeExactlyEqualToFieldValue(fieldName);
};

export const validateVaccineRangeToFieldValueWithZero = (sumVaccineCount:string,vaccineCountsGreaterThanZero:string,numberOfVaccines:number,isStudent:boolean) => {
	return (yup.mixed() as any).validateVaccineRangeToFieldValue(sumVaccineCount,vaccineCountsGreaterThanZero,numberOfVaccines,isStudent);
};

export const validateVaccineRangeToFieldValue = (sumVaccineCount:string,numberOfVaccines:number,isStudent:boolean) => {
	return (yup.mixed() as any).validateVaccineRangeToFieldValue(sumVaccineCount,numberOfVaccines,isStudent);
};

export const validateZeroEnrollmentReason = (fieldName) => {
	return yup.string().when([fieldName], {
		is: (count) => Number(count) === 0,
		then: yup.string().required('Required'),
	});
};

export const validateForIntValues = () => {
	return (yup.mixed() as any).validateIntValuesOnly();
};

export const validateEnrolledField = () => {
	return (yup.mixed() as any).validateEnrolledField();
};
