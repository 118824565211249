import {
	sRSFeatureRequests,
	DataLookupApiFactory,
	Configuration as sRSConfiguration,
	ApiManager,
} from '@the-mcorp/elevenfiftynine-srs-lib';
import { apiConfig, appLogPrefixes } from 'src/config/app-config';
import { HTTP_OPTIONS } from 'src/constants';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from './color-log.util';

/**
 *
 * @returns
 */
const getSrsApiConfiguration = (): sRSConfiguration => {
	return ApiManager.getInstance().getConfiguration();
};

/**
 *
 */
const setSRSApiConfiguration = (accessToken?: string): void => {
	const srsApiConfiguration = new sRSConfiguration();

	if(!accessToken){
		useLog(`No access token provided.`, ColorEnum.ERROR, appLogPrefixes.startup)
	}
	
	var apiHeaders =  accessToken ? getApiHeaders(accessToken) : HTTP_OPTIONS;

	srsApiConfiguration.baseOptions = apiHeaders;
	srsApiConfiguration.basePath = apiConfig.apiUrl;
	srsApiConfiguration.accessToken = accessToken;
	ApiManager.getInstance().setConfiguration(srsApiConfiguration);
};

/**
 * 
 * @param results 
 */
const debugApiParallelResults = (results: any[]) => {
	results.forEach((result) => { 
		if (result && result.type  ) {
			useLog(`Service Action: ${JSON.stringify(result.type, null, '\t')}`, ColorEnum.INFO, appLogPrefixes.services); 
		}  
	});
};


/**
 * 
 * @param accessToken 
 * @returns 
 */
const getApiHeaders = (accessToken?: string): any => {
 
	const headerOptions = {
		headers: {
			'Content-Type': 'application/json',
			'Access-Control-Allow-Credentials': 'true',
			'Access-Control-Allow-Origin': '*',
			'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
			'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
			'Authorization': `Bearer ${accessToken}`
		},
	};

	return headerOptions;
	
}

export const apiUtils = {
	getSrsApiConfiguration,
	setSRSApiConfiguration,
    debugApiParallelResults
};
