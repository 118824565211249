import { RouteObject } from 'react-router';
import { Suspense, lazy } from 'react';
import DefaultDashboardLayout from 'src/layouts/default-dasbhoard-layout';
import AuthGuard from 'src/components/guards/auth-guard';
import { Outlet } from 'react-router-dom';
import { SplashScreen } from 'src/components/splash-screen';
import StartupGuard from 'src/components/guards/startup-guard';

const Loadable = (Component: any) => (props: any) =>
	(
		<Suspense fallback={<SplashScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Pages
const SchoolRegistryPage = Loadable(lazy(() => import('../pages/school-registry')));
const ImmunizationReportingDashboardPage = Loadable(
	lazy(() => import('../pages/dashboard/immunization-reporting-dashboard'))
);

export const schoolDashboardRoutes: RouteObject[] = [
	{
		path: 'dashboard',
		children: [
			{
				path: 'school',
				element: (
					<AuthGuard>
						<StartupGuard>
							<DefaultDashboardLayout>
								<Outlet />
							</DefaultDashboardLayout>
						</StartupGuard>
					</AuthGuard>
				),
				children: [
					{
						path: '*',
						element: <SchoolRegistryPage />,
					},
					{
						path: 'registry',
						element: <SchoolRegistryPage />,
					},
					{
						path: 'reporting',
						element: <ImmunizationReportingDashboardPage />,
					},
				],
			},
		],
	},
];
