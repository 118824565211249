// Generated with util/create-slice.js

import axios from 'axios';
import { AppThunk, RootState } from '../..';
import { actions } from './startUp.reducer';

import {
	sRSFeatureEntityAdapters,
	sRSFeatureRequests,
	SchoolViewModel,
	UserApiFactory,
	DataLookupApiFactory,
	Configuration as sRSConfiguration,
	ApiManager,
	DashboardApiFactory,
	SchoolsDetailApiFactory,
	AnnoucementsApiFactory,
	ClosureGroupsApiFactory,
} from '@the-mcorp/elevenfiftynine-srs-lib';
import { hooks } from '@the-mcorp/core-ui-lib';
import { appLogPrefixes } from 'src/config/app-config';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';

const clientApplicationId = 'd0a28076-1629-4558-6c33-08d9b8d14a96';
const entityId = '5154433e-f36b-1410-8fff-00f7ca0ad523';
const userId = '4b54433e-f36b-1410-8fff-00f7ca0ad523';

export const HTTP_OPTIONS = {
	headers: {
		'Content-Type': 'application/json',
		'Access-Control-Allow-Credentials': 'true',
		'Access-Control-Allow-Origin': '*',
		'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
		'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
	},
};

/**
 * Fetches the user role data for a given username.
 *
 * @param username - The username for which to fetch the user role data.
 * @param debug - Optional. Specifies whether to enable debug mode. Default is false.
 * @returns A thunk function that dispatches actions to fetch the user role data.
 */
export const fetchUserRole =
	(username: string, debug: boolean = false): AppThunk =>
	async (dispatch) => {
		try {
			useLog(`Fetching user role data for ${username}...`, ColorEnum.INFO, appLogPrefixes.startup);

			dispatch(actions.getStartUps());

			const userRequests = sRSFeatureRequests.user;

			try {
				const res = await Promise.all([
					dispatch(
						userRequests.fetchGetAll_UserRoles_GetUserRolesByEmail<RootState>(
							UserApiFactory,
							{ requestBody: { entity: { emailAddress: username } } },
							undefined,
							'',
							debug
						)
					),
				]);

				if (debug) debugServiceResults(res);

				const result = res.map((res) => res.data);

				useLog(
					`Fetching user role data completed with success for user: ${username}.`,
					ColorEnum.INFO,
					appLogPrefixes.services
				);
			} catch {
				throw Error('Promise failed');
			}
		} catch (err) {
			dispatch(actions.getStartUpsFailure(err));
		}
	};

/**
 * Fetches startup data for a user and dispatches actions based on API responses.
 *
 * @param username - The username of the user.
 * @param debug - Optional. Set to true to enable debug mode. Default is false.
 * @param healthJurisdictionId - Optional. The health jurisdiction ID. Default is null.
 */
export const fetchStartUps =
	(username: string, debug: boolean = false, healthJurisdictionId: string = null): AppThunk =>
	async (dispatch) => {
		try {
			useLog(`Fetching Startup data for user: ${username}`, ColorEnum.INFO, appLogPrefixes.startup);

			dispatch(actions.getStartUps());

			let payload = null; //body;
			// if(debug){console.log(`PAYLOAD: ${JSON.stringify(payload, undefined, '\t')}`)};

			const dataLookupRequests = sRSFeatureRequests.dataLookup;
			const userRequests = sRSFeatureRequests.user;
			const closureGroupRequests = sRSFeatureRequests.closureGroups;
			// TODO: Make sure we're pulling back all the lookup data; cap is currently at 1000 records.
			const defaultWrapper = hooks.useRequestBuilder('getAll', 'post', null, 1, 1000);

			try {
				const res = await Promise.all([
					dispatch(
						dataLookupRequests.fetchGetAll_LookupCounty_GetAll<RootState>(
							DataLookupApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						dataLookupRequests.fetchGetAll_LookupDistrict_GetAll<RootState>(
							DataLookupApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						dataLookupRequests.fetchGetAll_LookupRegion_GetAll<RootState>(
							DataLookupApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						dataLookupRequests.fetchGetAll_LookupCohort_GetAll<RootState>(
							DataLookupApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						dataLookupRequests.fetchGetAll_LookupCohortGroup_GetAll<RootState>(
							DataLookupApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						dataLookupRequests.fetchGetAll_LookupHealthJurisdiction_GetAll<RootState>(
							DataLookupApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						dataLookupRequests.fetchGetAll_AcademicYear_GetAll<RootState>(
							DataLookupApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						userRequests.fetchGetAll_UserSchool_GetSchoolsByUserEmail<RootState>(
							UserApiFactory,
							{ requestBody: { entity: { email: username } } },
							undefined,
							'',
							debug
						)
					),
					// All Cohorts Immunization Reporting Summary
					dispatch(
						sRSFeatureRequests.dashboard.fetchGetAll_ImmunizationSubmissionPercentageByHealthJurisdiction_GetAll<RootState>(
							DashboardApiFactory,
							{ requestBody: { entity: { cohortGroupId: null, regionId: null, year: 0 } } }
						)
					),
					dispatch(
						sRSFeatureRequests.dashboard.fetchGetAll_ReportingCategoriesPercent_GetAll<RootState>(DashboardApiFactory, {
							requestBody: { entity: { cohortGroupId: null, } } }
						)
					),
					// All Cohorts Immunization Rate By Local Health Jurisdiction
					dispatch(
						sRSFeatureRequests.dashboard.fetchGetAll_SchoolImmunizationRate_GetAll<RootState>(DashboardApiFactory, {
							requestBody: {
								entity: {
									cohortGroupId: null,
									healthJurisdictionId: healthJurisdictionId,
									year: 0,
								},
							},
						})
					),
					// All Cohorts Cumulative Percent Reported In Local Health Jurisdiction By Date - Month View
					dispatch(
						sRSFeatureRequests.dashboard.fetchGetAll_CumulativeSubmissionPercentage_GetAll<RootState>(
							DashboardApiFactory,
							{
								requestBody: {
									entity: {
										cohortGroupId: null,
										healthJurisdictionId: healthJurisdictionId,
										academicYearStart: null,
									},
								},
							}
						)
					),
					//Data Refresh Date Related Call
					dispatch(
						sRSFeatureRequests.schoolsDetail.fetchGetAll_SchoolDataRefreshDetail_GetAll<RootState>(
							SchoolsDetailApiFactory,
							undefined,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						sRSFeatureRequests.annoucements.fetchGetAll_Annoucements_GetAllAnnoucements<RootState>(
							AnnoucementsApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
					dispatch(
						sRSFeatureRequests.closureGroups.fetchGetAll_ClosureGroups_GetAll<RootState>(
							ClosureGroupsApiFactory,
							defaultWrapper,
							undefined,
							'',
							debug
						)
					),
				]);

				if (debug) debugServiceResults(res);

				const result = res.map((res) => res.data);

				// console.log(result.flat());
				useLog(
					`Fetching Startup data completed with success for user: ${username}.`,
					ColorEnum.INFO,
					appLogPrefixes.services
				);

				dispatch(actions.getStartUpsSuccess(result));
			} catch {
				throw Error('Promise failed');
			}
		} catch (err) {
			dispatch(actions.getStartUpsFailure(err));
		}
	};

const debugServiceResults = (results: any[]) => {
	results.forEach((result) => {
		if (result.action.error) {
			useLog(
				`On or more startup services failed: ${JSON.stringify(result.action.error, null, '\t')}`,
				ColorEnum.ERROR,
				appLogPrefixes.startup
			);
			// throw result.error
		} else {
			// console.log(`SUCCESS: ${JSON.stringify(result.action, null, '\t')}`);
		}
	});
};
