import { useEffect } from 'react';
import type { FC, ReactNode } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Box, Button, Chip, Drawer, Link, Stack } from '@mui/material';
import type { Theme } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import {AppLogo} from '../../app-logo';

/***
 * Main Sidebar Types
 */
export interface MainSidebarProps {
  logo?: ReactNode;
  children?: ReactNode;
  onClose: () => void;
  open: boolean;
}

const MainSidebar: FC<MainSidebarProps> = (props) => {
  const { onClose, open, logo, children } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'));

  useEffect(() => {
    // if (open && onClose) {
    //   onClose();
    // }
    if (open) {
      onClose?.();
    }
  }, [location.pathname]);

  const sidebarWidth = process.env.REACT_APP_LAYOUT_SIDEBAR_WIDTH + 'px';

	return (
		<Drawer
			anchor='left'
			onClose={onClose}
			open={!lgUp && open}
			PaperProps={{ sx: { width: sidebarWidth } }}
			sx={{
				zIndex: (theme) => theme.zIndex.appBar + 100,
			}}
			variant='temporary'>
			<Box
				sx={{
					alignItems: 'flex-start',
					display: 'flex',
					flexDirection: 'column',
					height: '100%',
					p: 2,
				}}>
				<RouterLink to='/'>
					<Stack sx={{ display: 'flex', pr: '0px', flexDirection: 'row', width: '263px' }}>
						{logo ? logo : <AppLogo />}
					</Stack>
				</RouterLink>
				{children ? children : <></>}
				{/* <Box
          sx={{
            display: 'flex',
            pb: 2,
            pt: 3
          }}
        >
          <Link
            color="textSecondary"
            component={RouterLink}
            to="/browse"
            underline="none"
            variant="body1"
          >
            Browse Components
          </Link>
          <Chip
            color="primary"
            label="NEW"
            size="small"
            sx={{
              maxHeight: 20,
              ml: 1,
              mr: 2
            }}
          />
        </Box>
        <Link
          color="textSecondary"
          component={RouterLink}
          to="/docs"
          underline="none"
          variant="body1"
        >
          Documentation
        </Link>
        <Button
          color="primary"
          component="a"
          href="https://material-ui.com/store/items/devias-kit-pro"
          size="small"
          sx={{ mt: 4 }}
          target="_blank"
          variant="contained"
        >
          Get the kit
        </Button> */}
			</Box>
		</Drawer>
	);
};

MainSidebar.propTypes = {
  onClose: PropTypes.func,
  open: PropTypes.bool,
};

export default MainSidebar;
