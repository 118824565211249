import * as yup from 'yup';
import { get as lodashGet } from 'lodash';
import _ from 'lodash';

import { SRS_ERRORS_CONSTANTS } from '../../../constants';

/*
*** Useful links for Yup complex validations: ***

- https://medium.com/@iamarkadyt/how-does-yup-addmethod-work-creating-custom-validation-functions-with-yup-8fddb71a5470
- https://codesandbox.io/s/yup-custom-methods-rj9x6
- https://stackoverflow.com/questions/60525429/how-to-write-a-custom-schema-validation-using-yup-addmethod-for-country-name-a
- https://github.com/jquense/yup/issues/735#issuecomment-873828710
- https://github.com/jquense/yup/issues/735#issuecomment-574260390
- https://github.com/jquense/yup/issues/398#issuecomment-916693907
- https://github.com/jquense/yup#refpath-string-options--contextprefix-string--ref
- https://stackoverflow.com/a/63157712
*/
const intregExp = /^[+]?\d+?$/;
function validateFieldValueBasedOnValueAtPath(this: any, ...args: any[]) {
	return this.test('validateFieldValue', function (value) {
		const { createError, from, path } = this;
		const [completeStepFormContext] = from;
		const { value: completeStepFormData } = completeStepFormContext;
		const [objectPath] = args;
		const totalEnrolledCount = Number(lodashGet(completeStepFormData, objectPath, 0));

		// cannot perform general check like "if (!value)". Explicity check is helpful so that it doesn't fail when value=0
		if (typeof value == 'undefined' || value === '' || value === null) {
			return createError({ path, message: 'Required. If there are zero in this category, write 0' });
		}
		
	
		// relaxing all validations if the enrolledCount is zero as all the other form controls will be hidden..
		if (totalEnrolledCount === 0) {
			return true;
		}
		const isValidNumber = intregExp.test(value);
		const numericValue = Number(value);
		if(_.isInteger(numericValue) === false || !isValidNumber){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.integerMissingMessage
				} `,
			});
		};
		if(numericValue < 0){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.negativeCountErrorMessage
				} `,
			});
		}
		if (numericValue > totalEnrolledCount) {
			return createError({
				path,
				message: `${
					this.path.startsWith('childCareReport')
						? SRS_ERRORS_CONSTANTS.fieldErrorMessagePreK
						: SRS_ERRORS_CONSTANTS.fieldErrorMessageTKK7th
				} (${totalEnrolledCount})`,
			});
		}
		
		return true;
	});
}

function validateToBeExactlyEqualToFieldValue(this: any, ...args: any[]) {
	return this.test('validateFieldValue', function (value) {
		const { createError, from, path } = this;
		const [completeStepFormContext] = from;
		const { value: completeStepFormData } = completeStepFormContext;
		const [objectPath] = args;
		const totalEnrolledCount = Number(lodashGet(completeStepFormData, objectPath, 0));

		// cannot perform general check like "if (!value)". Explicity check is helpful so that it doesn't fail when value=0
		if (typeof value == 'undefined' || value === '' || value === null) {
			return createError({ path, message: 'Required. If there are zero in this category, write 0' });
		}

	
		// relaxing all validations if the enrolledCount is zero as all the other form controls will be hidden..
		if (totalEnrolledCount === 0) {
			return true;
		}
		const isValidNumber = intregExp.test(value);
		const numericValue = Number(value);
		if(_.isInteger(numericValue) === false || !isValidNumber){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.integerMissingMessage
				} `,
			});
		};
		if(numericValue < 0){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.negativeCountErrorMessage
				} `,
			});
		}
		if (numericValue != totalEnrolledCount) {
			return createError({
				path,
				message: `${
					this.path.startsWith('kindergarten') || this.path.startsWith('seventhGrade')
						? SRS_ERRORS_CONSTANTS.totalStudentsErolledErrorMessage
						: this.path.startsWith('childCareReport')
						? SRS_ERRORS_CONSTANTS.totalChildrenErolledErrorMessage:
						SRS_ERRORS_CONSTANTS.totalErolledErrorMessage
				} (${totalEnrolledCount}) ${SRS_ERRORS_CONSTANTS.totalEnrolled2ndHalfErrorMessage}`,
			});
		}
		
		return true;
	});
}

function validateVaccineRangeToFieldValue(this: any, ...args: any[]) {
	return this.test('validateFieldValue', function (value) {
		const { createError, from, path } = this;
		const [completeStepFormContext] = from;
		const { value: completeStepFormData } = completeStepFormContext;
		const [objectPath] = args;
		const totalEnrolledCount = Number(lodashGet(completeStepFormData, objectPath, 0));
		const numberOfVaccines = args[1];

		// cannot perform general check like "if (!value)". Explicity check is helpful so that it doesn't fail when value=0
		if (typeof value == 'undefined' || value === '' || value === null) {
			return createError({ path, message: 'Required. If there are zero in this category, write 0' });
		}

	
		const isValidNumber = intregExp.test(value);
		const numericValue = Number(value);
		if(_.isInteger(numericValue) === false || !isValidNumber){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.integerMissingMessage
				} `,
			});
		};
		if(numericValue < 0){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.negativeCountErrorMessage
				} `,
			});
		}
		// relaxing all validations if the enrolledCount is zero as all the other form controls will be hidden..
		if (numericValue > totalEnrolledCount * numberOfVaccines) {
			if (args[2] == true) {
				return createError({
					path,
					message: `${SRS_ERRORS_CONSTANTS.missingStudentDosesMessage} (${value})`,
				});
			} else {
				return createError({
					path,
					message: `${SRS_ERRORS_CONSTANTS.missingChildDosesMessage} (${value})`,
				});
			}
		}
		
		return true;
	});
}

function validateIntValuesOnly(this: any, ...args: any[]) {
	return this.test('validateFieldValue', function (value) {
		const { createError, from, path } = this;
		const [completeStepFormContext] = from;
		const { value: completeStepFormData } = completeStepFormContext;
		const [objectPath] = args;
	
		// cannot perform general check like "if (!value)". Explicity check is helpful so that it doesn't fail when value=0
		if (typeof value == 'undefined' || value === '' || value === null) {
			return createError({ path, message: 'Required. If there are zero in this category, write 0' });
		}
		const isValidNumber = intregExp.test(value);
		const numericValue = Number(value);
		if(_.isInteger(numericValue) === false || !isValidNumber){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.integerMissingMessage
				} `,
			});
		};
		
		if(numericValue < 0){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.negativeCountErrorMessage
				} `,
			});
		}
		
		
		
		return true;
	});
}

function validateEnrolledField(this: any, ...args: any[]) {
	return this.test('validateFieldValue', function (value) {
		const { createError, from, path } = this;
		const [completeStepFormContext] = from;
		const { value: completeStepFormData } = completeStepFormContext;
		const [objectPath] = args;
	
		// cannot perform general check like "if (!value)". Explicity check is helpful so that it doesn't fail when value=0
		if (typeof value == 'undefined' || value === '' || value === null) {
			return createError({ path, message: 'Enrollment count is required' });
		}
		const isValidNumber = intregExp.test(value);
		const numericValue = Number(value);
		if(_.isInteger(numericValue) === false || !isValidNumber){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.integerMissingMessage
				} `,
			});
		};
		
		if(numericValue < 0){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.negativeCountErrorMessage
				} `,
			});
		}

		if(numericValue > 9999){
			return createError({
				path,
				message: `${
					SRS_ERRORS_CONSTANTS.maxEnrollmentCountValueErrorMessage
				} `,
			});
		}
		
		
		
		return true;
	});
}

yup.addMethod(yup.mixed, 'validateFieldValueBasedOnValueAtPath', validateFieldValueBasedOnValueAtPath);
yup.addMethod(yup.mixed, 'validateToBeExactlyEqualToFieldValue', validateToBeExactlyEqualToFieldValue);
yup.addMethod(yup.mixed, 'validateVaccineRangeToFieldValue', validateVaccineRangeToFieldValue);
yup.addMethod(yup.mixed, 'validateIntValuesOnly', validateIntValuesOnly);
yup.addMethod(yup.mixed, 'validateEnrolledField', validateEnrolledField);
export default yup;
