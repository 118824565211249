import { useEffect, useState } from 'react';
import { FC } from 'react';
import { useRoutes } from 'react-router-dom';
import { Toaster } from 'react-hot-toast';
import CssBaseline from '@mui/material/CssBaseline';
import { MsalProvider } from '@azure/msal-react';
import { Box } from '@mui/material';
import useScrollReset from './hooks/use-scroll-reset';
import routes from './routes';
import { useDispatch, useSelector } from './store';
import { TimeoutOverlay } from './components/common/timeout-overlay';
import GlobalBackdrop from './components/controls/feedback/backdrop/global-backdrop';
import './locales/i18n';
import GlobalSnackbar from './components/controls/snackbar/global-snackbar';
import { PublicClientApplication } from '@azure/msal-browser';
import { AuthProvider } from './contexts/auth/auth-context';
import ClientSideNavigation from './components/controls/authentication/client-side-navigation';
import { authUtils } from './utils/auth.utils';
import { ConfirmationDialogServiceProvider, ConfirmationOptions } from './components/controls/feedback/dialog';
import useExitPromptBeforeReload from './hooks/use-exit-prompt';
import { SessionStorageData } from './types/session-storage-data';
import { browserStore } from 'src';
import { BrowserStorageType } from './utils/browser-storage.util';

/**
 *
 */
interface AppProps {
	msalInstance: PublicClientApplication;
}

/**
 * Stores the navigation type to the session storage.
 */
function storeNavTypeToSession() {
	const sessionData = browserStore.getItem(BrowserStorageType.SESSION, process.env.REACT_APP_TEMP_SESSION_STORAGE);
	const sessionStore: SessionStorageData = {
		pageReloaded: sessionData?.pageReloaded,
		returnPath: sessionData?.returnPath,
		navType: performance.getEntriesByType('navigation')[0]['type'],
		tempMarkers:sessionData?.tempMarkers
	};
	browserStore.setItem(BrowserStorageType.SESSION, process.env.REACT_APP_TEMP_SESSION_STORAGE, sessionStore);
}

/**
 * Handles the beforeunload event.
 * Stores page reload status and return path to session storage before unloading.
 * @param event - The beforeunload event object.
 */
function handleBeforeUnload(tempMarkers:any,event: any) {
	//const tempMarkers:any;
	const sessionStore: SessionStorageData = {
		pageReloaded: true,
		returnPath: location.pathname + location.search,
		navType: null,
		tempMarkers: tempMarkers
	};

	browserStore.setItem(BrowserStorageType.SESSION, process.env.REACT_APP_TEMP_SESSION_STORAGE, sessionStore);
};

const initBeforeUnLoad = (showExitPrompt,tempMarkers) => {
	window.addEventListener('beforeunload', (evt) => handleBeforeUnload(tempMarkers,evt));
	window.onbeforeunload = (event) => {
		window.removeEventListener('beforeunload', (evt) => handleBeforeUnload(tempMarkers,evt));
		
		// Show prompt based on state
		if (showExitPrompt) {
			const e = event || window.event;
			e.preventDefault();
			if (e) {
				e.returnValue = '';
			}
			return '';
		}
	};
};

const App: FC<AppProps> = (props) => {
	const { msalInstance } = props;
	const content = useRoutes(routes);
	const [displayAlert, setDisplayAlert] = useState(true);
	const backdropProps = useSelector((state) => state.global.backDropProps);
	const [showExitPrompt, setShowExitPrompt] = useState(false);
	const dispatch = useDispatch();
	const tempMarkers = useSelector((state) => state.tempMarkers);
	//NOTE: https://dev.to/eons/detect-page-refresh-tab-close-and-route-change-with-react-router-v5-3pd

	//NOTE: this similar to componentWillUnmount()
	//   useEffect(() => {
	// 	return () => {
	// 	  setShowExitPrompt(false)
	// 	}
	//   }, [])

	// Initialize the beforeunload event listener after the resources are loaded
	window.onload = function () {
		storeNavTypeToSession();
		initBeforeUnLoad(showExitPrompt,tempMarkers);
	};

	// Re-Initialize the onbeforeunload event listener
	useEffect(() => {
		storeNavTypeToSession();
		initBeforeUnLoad(showExitPrompt,tempMarkers);
	}, [showExitPrompt,tempMarkers]);

	useScrollReset();

	return (
		<ClientSideNavigation pca={msalInstance}>
			<MsalProvider instance={msalInstance}>
				<ConfirmationDialogServiceProvider>
					<AuthProvider instance={msalInstance}>
						<CssBaseline />
						<Box>
							{/* <RTL direction={settings.direction}> */}

							<Toaster position='top-center' />
							<GlobalBackdrop
								title={backdropProps ? backdropProps.title : 'Loading...'}
								subTitle={backdropProps ? backdropProps.subTitle : undefined}
								iconType={backdropProps ? 'save' : 'load'}
								visible={backdropProps ? backdropProps.visible : false}
							/>
							{content}
							{/* { showSlashScreen? content : <SplashScreen /> } */}
							{/* </RTL> */}
						</Box>
						<TimeoutOverlay
							handleLogout={() => {
								authUtils.handleLogoutFromTimeout(dispatch);
							}}></TimeoutOverlay>
						<GlobalSnackbar />
					</AuthProvider>
				</ConfirmationDialogServiceProvider>
			</MsalProvider>
		</ClientSideNavigation>
	);
};

export default App;

/** Window Reload Utilities */
// useEffect(() => {
// 	const navigationEntries: any = window.performance.getEntriesByType('navigation');

// 	if (displayAlert) {
// 		window.onbeforeunload = function () {
// 			return true;
// 		};
// 	}

// 	return () => {
// 		if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
// 			console.log('Page was reloaded');
// 		}
// 		window.onbeforeunload = null;
// 	};
// }, [displayAlert]);

// useEffect(() => {
// 	window.addEventListener("beforeunload", alertUser);
// 	return () => {
// 	  window.removeEventListener("beforeunload", alertUser);
// 	};
//   }, []);
//   const alertUser = (e) => {
// 	console.log('HOLA!!!')
// 	// print_nav_timing_data();
// 	  e.preventDefault();

// 	 e.returnValue = "";
//   };

// window.addEventListener('beforeunload', (event) => {
// 	// Cancel the event as stated by the standard.
// 	event.preventDefault();
// 	// Chrome requires returnValue to be set.
// 	event.returnValue = '';

// 	const navigate = useNavigate();
// 	navigate('/dashboard/account');
// 	console.log('beforeunload');

// });

// useEffect(() => {
// 	const navigationEntries: any = window.performance.getEntriesByType('navigation');

// 	if (displayAlert) {
// 		window.onbeforeunload = function () {
// 			// navigate('/');
// 			window.history.pushState('', '', '/');
// 			return null;
// 		};
// 	}

// 	return () => {
// 		if (navigationEntries.length > 0 && navigationEntries[0].type === 'reload') {
// 			console.log('Page was reloaded');
// 			// window.history.pushState("", "", "/");
// 		}

// 		window.onbeforeunload = null;
// 	};
// }, [displayAlert]);
