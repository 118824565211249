import { Suspense, lazy } from 'react';
import type { RouteObject } from 'react-router';
import DefaultMainLayout from '../layouts/default-main-layout';

import { authorizationRoutes } from './authorization';
import { schoolDashboardRoutes as schoolDashboardRoutes } from './school';
import { startupRoutes } from './startup';
import { SplashScreen } from 'src/components/splash-screen';
import { adminDashboardRoutes } from './admin';
import { errorPageRoutes } from './error-pages';

const Loadable = (Component: any) => (props: any) =>
	(
		<Suspense fallback={<SplashScreen />}>
			<Component {...props} />
		</Suspense>
	);

// SRS App pages
const HomePage = Loadable(lazy(() => import('../pages/home'))); 

// Error pages
const AuthorizationRequired = Loadable(lazy(() => import('../pages/error/401')));
const AccessForbidden = Loadable(lazy(() => import('../pages/error/403')));
const NotFound = Loadable(lazy(() => import('../pages/error/404')));
const ServerError = Loadable(lazy(() => import('../pages/error/500')));
const SessionTimeout = Loadable(lazy(() => import('../pages/error/session-timeout'))); 

const routes: RouteObject[] = [
	{
		path: '/',
		element: <DefaultMainLayout />,
		children: [
			{
				index: true,
				element: <HomePage />,
			},
		],
	}, 
	...startupRoutes, 
	...authorizationRoutes,
	...schoolDashboardRoutes,
	...adminDashboardRoutes,
	...errorPageRoutes,
	{
		path: '*',
		element: <DefaultMainLayout />,
		children: [
			{
				path: '',
				element: <HomePage />,
			},
			{
				path: 'home',
				element: <HomePage />,
			}, 
			{
				path: 'session-timeout',
				element: <SessionTimeout />,
			}, 
			
			{
				path: 'landing-page',
				element: <HomePage />,
			}, 
			{
				path: '*',
				element: <NotFound />,
			},
		],
	},
];

export default routes;
 