import { useState } from 'react';
import { FC, ReactNode } from 'react';
import { Outlet } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import { AppBar } from '@mui/material';

const sidebarWidth = Number(process.env.REACT_APP_LAYOUT_SIDEBAR_WIDTH);
const sidebarTopPadding = Number(process.env.REACT_APP_LAYOUT_SIDEBAR_TOP);

/*** * Dashboard Styles */
const DashboardLayoutRoot = styled('div')(({ theme }) => ({

           backgroundColor: '#F9FBFD',
           backgroundRepeat: "no-repeat",
           backgroundAttachment: "fixed",
  display: 'flex',
  flex: '0 1 auto',
  maxWidth: '100%',
  paddingTop: sidebarTopPadding,
  [theme.breakpoints.up('lg')]: {
    paddingLeft: sidebarWidth,
  },
}));

/*** * Dashboard Navbar styles */

//  backgroundColor:  theme.palette.neutral[800],
// borderTop: `1px solid ${theme.palette.divider}`,
const DashboardNavbarRoot = styled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
       backgroundColor: 'white',
     boxShadow: theme.shadows[3],
     padding: 0,
     margin: 0,
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: 'red',
    borderBottomColor: theme.palette.divider,
    borderBottomStyle: 'hidden',
    borderBottomWidth: 1,
    boxShadow: theme.shadows[3],
  }),
  zIndex: theme.zIndex.drawer + 100,
}));

// export {DashboardLayoutRoot, DashboardLayoutWrapper, DashboardLayoutContainer, DashboardLayoutContent, DashboardNavbarRoot}
export { DashboardLayoutRoot, DashboardNavbarRoot };
