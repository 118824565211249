// Generated with util/create-slice.js

import { AppThunk, RootState } from 'src/store';
import { actions } from './reporting-dashboard.reducer';

import {
	sRSFeatureRequests,
	DataLookupApiFactory,
	DashboardApiFactory,
	ApiManager,
} from '@the-mcorp/elevenfiftynine-srs-lib';
import { hooks } from '@the-mcorp/core-ui-lib';
import { HTTP_OPTIONS } from 'src/constants';
import { apiUtils } from 'src/utils/api.utils';
import { appLogPrefixes } from 'src/config/app-config';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';

/**
 *
 * @param cohortGroupId  Cohort Group ID
 * @param healthJurisdictionId   Local Health Jurisdiction ID
 * @param regionId  Region ID
 * @param debug  Debug flag
 * @returns
 */
export const fetchReportingDashboards =
	(academicYearStart:number, cohortGroupId: string, healthJurisdictionId: string, regionId: string, debug: boolean = false, 
		cummalitveSubmissionReportacademicYearStart: number, selectedRegion: string,
		 yearSelected:boolean=false, cohortGroupIdSelected: boolean=false, regionSelected:boolean=false, healthJuristionSelected:boolean=false,cummualiveSubmissionReportYearSelected:boolean=false): AppThunk =>
	async (dispatch) => {
		try {
 
			dispatch(actions.getReportingDashboards());

			useLog(`Retrieving ReportingDashboards data...`, ColorEnum.INFO, appLogPrefixes.services); 
			useLog('fetchReportingDashboards:yearSelected:'+yearSelected, ColorEnum.INFO, appLogPrefixes.services);
			useLog('fetchReportingDashboards:cohortGroupIdSelected:'+cohortGroupIdSelected, ColorEnum.INFO, appLogPrefixes.services);
			useLog('fetchReportingDashboards:regionSelected:'+regionSelected, ColorEnum.INFO, appLogPrefixes.services);
			useLog('fetchReportingDashboards:healthJuristionSelected:'+healthJuristionSelected, ColorEnum.INFO, appLogPrefixes.services);
			useLog('fetchReportingDashboards:cummualiveSubmissionReportYearSelected:'+cummualiveSubmissionReportYearSelected, ColorEnum.INFO, appLogPrefixes.services);
			// TODO: Make sure we're pulling back all the lookup data; cap is currently at 1000 records.
			const defaultWrapper = hooks.useRequestBuilder('getAll', 'post', null, 1, 1000);
			let asynccalls = [];
			if(yearSelected || cohortGroupIdSelected || regionSelected){
			asynccalls.push(					dispatch(
				sRSFeatureRequests.dashboard.fetchGetAll_ReportingCategoriesPercent_GetAll<RootState>(
					DashboardApiFactory, 
					{
						requestBody: { 
							entity: { 
								cohortGroupId: cohortGroupId,
								regionId: selectedRegion,
								year:academicYearStart	
							} 
						},
				})
			));
		}
		if(yearSelected || cohortGroupIdSelected ){
			asynccalls.push(dispatch(
				sRSFeatureRequests.dashboard.fetchGetAll_ImmunizationSubmissionPercentageByHealthJurisdiction_GetAll<RootState>(
					DashboardApiFactory,
					{ requestBody: { entity: { cohortGroupId: cohortGroupId, regionId: regionId, year: academicYearStart } } }
				)
			));
		}
		if(healthJuristionSelected || cohortGroupIdSelected || cummualiveSubmissionReportYearSelected)
		{
			asynccalls.push(dispatch(
				sRSFeatureRequests.dashboard.fetchGetAll_CumulativeSubmissionPercentage_GetAll<RootState>(
					DashboardApiFactory,
					{
						requestBody: {
							entity: {
								cohortGroupId: cohortGroupId,
								healthJurisdictionId: healthJurisdictionId,
								academicYearStart: cummalitveSubmissionReportacademicYearStart,
							},
						},
					}
				)
			));
		}
			if(healthJuristionSelected|| cohortGroupIdSelected){
				
				asynccalls.push(dispatch(
					sRSFeatureRequests.dashboard.fetchGetAll_SchoolImmunizationRate_GetAll<RootState>(DashboardApiFactory, {
						requestBody: {
							entity: {
								cohortGroupId: cohortGroupId,
								healthJurisdictionId: healthJurisdictionId,
								year: 0,
							},
						},
					})
				));
				
			}
			try {
				const res = await Promise.all(asynccalls);

			 	if (debug) apiUtils.debugApiParallelResults(res);

				const result = res.map((res) => res.data); 
		 
				dispatch(actions.getReportingDashboardsSuccess(result));
				
			} catch(e) { 
				throw Error(`Promise Failed: ${e.message}`);
			}
		} catch (err) {
			useLog(`Error fetching Reporting Dashboard(s) data: ${err}`, ColorEnum.ERROR, appLogPrefixes.services); 
			dispatch(actions.getReportingDashboardsFailure(err));
		}
	};
