import { ReactNode, useState } from 'react';
import type { FC } from 'react';
import { Outlet } from 'react-router-dom';
import { MainFooter, MainNavbar, MainSidebar } from './main';
import { styled } from '@mui/material/styles';

export interface MainLayoutProps {
	children?: ReactNode;
	navbarChildren?: ReactNode;
	navbarLogo?: ReactNode;
	sidebarChildren?: ReactNode;
	sidebarLogo?: ReactNode;
	footerLogo?: ReactNode;
	footerSections?: Array<any>;
	footerCopyright?: string;
}

const MainLayoutRoot = styled('div')(({ theme }) => ({
	backgroundColor: theme.palette.background.default,
	height: '100%',
	paddingTop: 64,
}));

const MainLayout: FC<MainLayoutProps> = (props) => {

	const {
		children,
		navbarChildren,
		navbarLogo,
		sidebarChildren,
		sidebarLogo,
		footerLogo,
		footerSections,
		footerCopyright,
	} = props;

	const [isSidebarMobileOpen, setIsSidebarMobileOpen] = useState<boolean>(false);

	return (
		<MainLayoutRoot>
			<MainNavbar
				logo={navbarLogo}
				children={navbarChildren}
				onSidebarMobileOpen={(): void => setIsSidebarMobileOpen(true)}
			/>
			<MainSidebar
				logo={sidebarLogo}
				children={sidebarChildren}
				onClose={(): void => setIsSidebarMobileOpen(false)}
				open={isSidebarMobileOpen}
			/>
			{children || <Outlet />}
			{/* <MainFooter logo={footerLogo} sections={footerSections} copyright={footerCopyright} /> */}
		</MainLayoutRoot>
	);
};

export default MainLayout;
