import { FC } from 'react';
import { MainLayout } from '../components/layouts';
import { AppLogo } from '../components/app-logo';
import { SignInSignOutButton } from '../components/controls/authentication';
import { Divider } from '@mui/material';
import { LayoutProps } from '.';
import { useIsAuthenticated } from '@azure/msal-react';
import CommonNavbarView from 'src/components/layouts/common/common-navbar-view';

const DefaultMainLayout: FC<LayoutProps> = (props) => {
	const { children } = props;

	const isLocalEnvironment =
		process.env.REACT_APP_ENV.trim().toLowerCase() === 'local' ||
		process.env.REACT_APP_ENV.trim().toLowerCase() === 'development';

	const isAuthenticated = useIsAuthenticated();
	return (
		<MainLayout
			navbarLogo={<AppLogo />}
			navbarChildren={
				<>
					{isAuthenticated && <CommonNavbarView />}
					{isLocalEnvironment && <SignInSignOutButton />}
				</>
			}
			sidebarLogo={<AppLogo />}
			sidebarChildren={
				<>
					<Divider
						orientation='horizontal'
						sx={{
							height: 2,
							width: '100%',
							mx: 0,
							mt: 1,
							mb: 2,
						}}
					/>
					{isLocalEnvironment && <SignInSignOutButton />}
				</>
			}
			footerLogo={<AppLogo />}
			footerSections={defaultSections}
			footerCopyright={'MCorp'}>
			{children}
		</MainLayout>
	);
};

export default DefaultMainLayout;

/**
 * Component Data
 */

export const defaultSections = [
	{
		title: 'Menu',
		links: [
			{
				title: 'Browse Components',
				href: '/browse',
			},
			{
				title: 'Documentation',
				href: '/docs',
			},
		],
	},
	{
		title: 'Placeholders',
		links: [
			{
				title: 'Terms & Conditions',
				href: '#',
			},
			{
				title: 'License',
				href: '#',
			},
			{
				title: 'Contact',
				href: '#',
			},
		],
	},
	{
		title: 'Social',
		links: [
			{
				title: 'Instagram',
				href: '#',
			},
			{
				title: 'LinkedIn',
				href: '#',
			},
		],
	},
];
