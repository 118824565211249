import type { FC } from 'react';
import { Box, Divider, LinearProgress, Stack, Typography } from '@mui/material';
import { AppLogo } from './app-logo';
import { SrsLogo } from './srs-logo';

export const SplashScreen: FC = (props) => {
	const showLogo = process.env.REACT_APP_SHOW_1159_LOGO;

	return (
		<Box
			sx={{
				alignItems: 'center',
				backgroundColor: 'background.paper',
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
				justifyContent: 'center',
				left: 0,
				p: 3,
				position: 'fixed',
				top: 0,
				width: '100vw',
				zIndex: 1400,
			}}>
			<Stack direction={'column'} rowGap={2}>
				<AppLogo />
				<Box sx={{ width: '100%' }}>
					<LinearProgress />
				</Box>
				{showLogo === 'true' && (
					<>
						<Stack
							direction={'row'}
							gap={0}
							sx={{ mt: 5 }}
							alignContent={'center'}
							justifyItems={'center'}
							justifyContent={'center'}
							rowGap={0}>
							<Divider sx={{ color: '#F2F4F7' }} />
							<SrsLogo width={80} />
						</Stack>
						<Box component={'span'} color='primary.main' textAlign={'center'}></Box>
						<Typography
							color='textSecondary'
							variant='caption'
							justifyContent={'center'}
							textAlign={'center'}
							display={'flex'}>
							<Box textAlign={'center'}>
								<span style={{ fontSize: '11px', fontWeight: 400 }}>Powered by</span>
								{'  '}
								<span style={{ fontSize: '12px', fontWeight: 800 }}>1159.ai</span>
							</Box>
						</Typography>
					</>
				)}
			</Stack>
		</Box>
	);
};
