import * as React from 'react';
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';

export default function SidebarItem(props: any) {
	const theme = useTheme();
	return (
		<Box
			sx={{
				cursor: props?.clickDisabled ? 'default' : 'pointer',
				pointerEvents: props?.clickDisabled ? 'none' : 'auto',
				backgroundColor: props?.selected ? 'background.highlight' : null,
				border: props?.selected ? '1px solid' : null,
				borderColor: props?.selected ? 'secondary.light' : null,
				borderRight: props?.selected ? '5px Solid' : null,
				borderRightColor: props?.selected ? 'emphasis' : null,
				display: 'flex',
				alignItems: 'center',
				alignContent: 'center',
				color: 'text.secondary',
				width: '90%',
				maxWidth: process.env.REACT_APP_SIDEBAR_WIDTH,
				height: 40,
				borderRadius: '.5rem',
				'&:hover': {
					backgroundColor: '#F6F7F9',
					border: '1px solid #DFE0EB',
				},
				'&:active': {
					backgroundColor: '#F6F7F9',
					border: '1px solid #DFE0EB',
					borderRadius: '.5rem',
					borderRight: '6px Solid #0c5894',
					borderRightColor: '-moz-initial',
					color: '#0c5894',
				'&:p, typography':{
					color: '#0c5894',

				}
				},
				'&:focus': {
					backgroundColor: 'blue',
					border: '1px solid #DFE0EB',
					borderRadius: '.5rem',
					borderRight: '6px Solid #0c5894',
					borderRightColor: '-moz-initial',
					color: '#0c5894'
				},
				 
			}}>
			{props.children}
		</Box>
	);
}
