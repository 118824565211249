import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from '../..'; 
import { AlertColor, AlertProps, SnackbarOrigin } from '@mui/material';
import { GlobalBackdropProps } from 'src/components/controls/feedback/backdrop';

 
 
export const wait = (time: number): Promise<void> => new Promise((res: any) => setTimeout(res, time));

/**
 * Redux & Interfaces
 */
interface GlobalApp {
	isLoaded?: boolean;
	backDropProps?: GlobalBackdropProps;
	snackbarProps?: GlobalSnackbarProps | null;
	appStartup?: AppStartup | null;
	designatedContactSaved?: boolean;
}

type StartupState = "None" | "Loading" | "Complete" | "Failed"

interface AppStartup {
	state: StartupState;
 
}

type GlobalAppState = GlobalApp;

interface GlobalSnackbarProps {
	snackbarOpen: boolean;
	snackbarSeverity: AlertColor;
	snackbarMessage: string;
	snackbarOrigin: SnackbarOrigin;
	autoHide: boolean;
	autoHideDuration: number;
}

const initialState: GlobalAppState = {
	isLoaded: false,
	backDropProps: {
		title: '',
		visible: false,
		subTitle: undefined,
		iconType: 'load',
	},
	snackbarProps: {
		snackbarOpen: false,
		snackbarSeverity: 'info',
		snackbarMessage: '',
		snackbarOrigin: { vertical: 'bottom', horizontal: 'right' },
		autoHide: true,
		autoHideDuration: 4000,
	},
	appStartup:{
		state: "None"
	},
	designatedContactSaved: false,
};

const slice = createSlice({
	name: 'globalApp',
	initialState,
	reducers: {
		toggleBackdropState(state: GlobalAppState, action: PayloadAction<GlobalApp>): void {
			const globalApp = action.payload;
			state.backDropProps = globalApp.backDropProps;
		},
		displaySnackbarMessageState(state: GlobalAppState, action: PayloadAction<GlobalApp>): void {
			const globalApp = action.payload;
			state.snackbarProps = globalApp.snackbarProps;
		},
		appStartupState(state: GlobalAppState, action: PayloadAction<GlobalApp>): void {
			const globalApp = action.payload;
			state.appStartup = globalApp.appStartup;
		},
		designatedContactSave(state: GlobalAppState, action: PayloadAction<boolean>): void {
			const designatedContactSaved = action.payload;
			state.designatedContactSaved = designatedContactSaved;
		},
	},
});

export const { reducer: globalReducer } = slice;
 

/**
 * 
 * @param backdropTitle 
 * @param backdropIcon 
 * @param backdropSubTitle 
 * @returns 
 */
export const updateAppStartupState =
	(state: StartupState): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(
			slice.actions.appStartupState({
				appStartup: {
					state: state
				},
			})
		);
	};

	/**
 * 
 * @param isSaved
 * @returns 
 */
export const updateDesignatedContactSaved =
(isSaved: boolean): AppThunk =>
async (dispatch): Promise<void> => {
	dispatch(
		slice.actions.designatedContactSave(isSaved)
	);
};


/**
 * 
 * @param backdropTitle 
 * @param backdropIcon 
 * @param backdropSubTitle 
 * @returns 
 */
export const displayBackdrop =
	(backdropTitle?: string, backdropIcon?: any, backdropSubTitle?: string): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(
			slice.actions.toggleBackdropState({
				backDropProps: {
					visible: true,
					title: backdropTitle,
					iconType: backdropIcon,
					subTitle: backdropSubTitle,
				},
			})
		);
	};

/**
 *
 * @param closeDelay
 * @returns
 */
export const hideBackdrop =
	(closeDelay?: number): AppThunk =>
	async (dispatch): Promise<void> => {
		const backdropCloseDelay = closeDelay && closeDelay > 0 ? closeDelay : 250;

		wait(backdropCloseDelay).then(() => {
			dispatch(
				slice.actions.toggleBackdropState({
					backDropProps: {
						visible: false,
					},
				})
			);
		});
	};

/**
 * Method
 */
export const displaySnackbar =
	(
		severity: AlertColor = 'info',
		message: string = undefined,
		origin: SnackbarOrigin = { vertical: 'bottom', horizontal: 'right' },
		autoHideDuration = 4000
	): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(
			slice.actions.displaySnackbarMessageState({
				snackbarProps: {
					snackbarOpen: true,
					snackbarSeverity: severity,
					snackbarMessage: message,
					snackbarOrigin: origin,
					autoHideDuration: autoHideDuration,
				},
			})
		);
	};

export const clearSnackbar =
	(): AppThunk =>
	async (dispatch): Promise<void> => {
		dispatch(
			slice.actions.displaySnackbarMessageState({
				snackbarProps: {
					snackbarOpen: false,
					snackbarType: 'info',
					snackbarMessage: undefined,
					autoHideDuration: 4000,
				},
			})
		);
	};

export default slice;