import 'react-perfect-scrollbar/dist/css/styles.css'; 
import { Provider as ReduxProvider } from 'react-redux';
import { HelmetProvider } from 'react-helmet-async';
import { BrowserRouter as Router } from 'react-router-dom';
import { EventType, PublicClientApplication } from '@azure/msal-browser';
import { ThemeProvider } from '@mui/material/styles';
import { msalConfig } from './config/msal-config';
import { createRoot } from 'react-dom/client';
import { store } from './store';
import { createCustomTheme } from './theme';
import { ColorEnum, ColorLog } from './utils/color-log.util';
import App from './app';
import { useLog } from './hooks/use-log';
import { appLogPrefixes } from './config/app-config';
import { ConfirmationOptions } from './components/controls/feedback/dialog';
import React from 'react';
import { BrowserStorageService } from './utils/browser-storage.util';

/**
 * Custom logger to colorize log messages
 */
export const customLogger = new ColorLog();
 
export const confirmationServiceContext = React.createContext<
  (options: ConfirmationOptions) => Promise<void>
>(Promise.reject);

/**
 * Initialize a PublicClientApplication instance which is provided to the MsalProvider component
 * We recommend initializing this outside of your root component to ensure it is not re-initialized on re-renders
 */
export const msalInstance = new PublicClientApplication(msalConfig);

export const browserStore = new BrowserStorageService();

msalInstance.initialize().then(() => {
	// Default to using the first account if no account is active on page load
	if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
		// NOTE: Account selection logic is app dependent. Adjust as needed for different use cases.
		msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
	}

	// Optional - This will update account state if a user signs in from another tab or window
	msalInstance.enableAccountStorageEvents();

	msalInstance.addEventCallback(handleAuthEventCallback);

	const container = document.getElementById('root');
	const root = createRoot(container!); // createRoot(container!) if you use TypeScript
 
	root.render(
		<HelmetProvider>
			<Router>
				<ReduxProvider store={store}>
					<ThemeProvider theme={defaultTheme}>
						<App msalInstance={msalInstance} />
					</ThemeProvider>
				</ReduxProvider>
			</Router>
		</HelmetProvider>
	);
});

/**
 *
 * @param event
 */
const handleAuthEventCallback = (event: any): void => {
	switch (event.eventType) {
		case EventType.LOGIN_SUCCESS:
		case EventType.ACQUIRE_TOKEN_SUCCESS:
		case EventType.SSO_SILENT_SUCCESS:
			useLog(`MSAL Event: ${event.eventType}`, ColorEnum.SUCCESS, appLogPrefixes.msalAuth);
			const account = event.payload.account;
			msalInstance.setActiveAccount(account);
			break;
		case EventType.LOGIN_FAILURE:
		case EventType.ACQUIRE_TOKEN_FAILURE:
		case EventType.SSO_SILENT_FAILURE:
			useLog(`MSAL Event: ${event.eventType}\nEvent Detail: ${JSON.stringify(event, null, '\t')}`, ColorEnum.ERROR, appLogPrefixes.msalAuth);		 
			break; 
		default:
			if (process.env.REACT_APP_AZURE_B2C_ENABLE_LOG === 'true') {
				useLog(`MSAL Event: ${event.eventType}`, ColorEnum.INFO, appLogPrefixes.msalAuth);
			}
			break;
	}
};

/**
 * Default theme for the app
 */
const defaultTheme = createCustomTheme({
	direction: 'ltr',
	responsiveFontSizes: true,
	mode: 'light',
});

/***************************************************************/
/* REFERENCE: List of available events that might be useful in the future */
/***************************************************************/

/* Additional Research Needed */
// case EventType.ACCOUNT_ADDED:
// case EventType.ACCOUNT_REMOVED:
// case EventType.RESTORE_FROM_BFCACHE:
//      break;

/* Process START Events */
// case EventType.ACQUIRE_TOKEN_START:
// case EventType.INITIALIZE_START:
// case EventType.HANDLE_REDIRECT_START:
// case EventType.LOGIN_START:
// case EventType.LOGOUT_START:
// case EventType.SSO_SILENT_START:
// case EventType.ACQUIRE_TOKEN_NETWORK_START:
// case EventType.ACQUIRE_TOKEN_BY_CODE_START:
// 		break;

/* SUCCESS Events */
// case EventType.ACQUIRE_TOKEN_SUCCESS:
// case EventType.LOGIN_SUCCESS:
// case EventType.ACQUIRE_TOKEN_BY_CODE_SUCCESS: // TODO:
// case EventType.SSO_SILENT_SUCCESS: // TODO:
// case EventType.LOGOUT_SUCCESS: // TODO:
// 		break;

/* Process END Events */
// case EventType.LOGOUT_END:
// case EventType.HANDLE_REDIRECT_END: // NOT SURE IF NEEDED... more research
// case EventType.INITIALIZE_END:
// 		break;

/* FAILURE Events */
// case EventType.LOGIN_FAILURE: // TODO:
// case EventType.LOGOUT_FAILURE: // TODO:
// case EventType.ACQUIRE_TOKEN_FAILURE: // TODO:
// case EventType.SSO_SILENT_FAILURE: // TODO:
// case EventType.ACQUIRE_TOKEN_BY_CODE_FAILURE: // TODO:
// 		break;
