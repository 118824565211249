import { Box } from '@mui/system';
import { useState, FC } from 'react';
import { Outlet } from 'react-router-dom';
import DashboardNavbar from './dashboard/dashboard-navbar';
import DashboardSidebar from './dashboard/dashboard-sidebar';

import { DashboardLayoutProps } from './dashboard-layout.types';
import { DashboardLayoutRoot } from './dashboard-layout.styles';
import CommonFooterView from './common/common-footer-view';

const sidebarWidth = Number(process.env.REACT_APP_LAYOUT_SIDEBAR_WIDTH);
const sidebarTopPadding = Number(process.env.REACT_APP_LAYOUT_SIDEBAR_TOP);

 

const DashboardLayout: FC<DashboardLayoutProps> = ({
	// user,
	children,
	navbarChildren,
	sidebarSections,
	sidebarHeader,
	sidebarFooter,
}) => {
	const [isSidebarOpen, setIsSidebarOpen] = useState<boolean>(false);

	return (
		<DashboardLayoutRoot>
			<Box
				sx={{
					display: 'flex',
					flex: '0 1 auto',
					flexDirection: 'column',
					width: '100%',
					backgroundColor: 'background.default',
				}}>
				<Box
					sx={{
						minHeight: '85vh',
					}}>
					<Outlet />
				</Box>

				<CommonFooterView/>
			</Box>

			<DashboardNavbar
				children={navbarChildren ? navbarChildren : <></>}
				onOpenSidebar={(): void => setIsSidebarOpen(true)}
			/>

			<DashboardSidebar
				sections={sidebarSections}
				header={sidebarHeader}
				footer={sidebarFooter}
				onClose={(): void => setIsSidebarOpen(false)}
				open={isSidebarOpen}
			/>
		</DashboardLayoutRoot>
	);
};

export default DashboardLayout;
