import { msalInstance } from "src";
import { appLogPrefixes } from "src/config/app-config"; 
import { useLog } from "src/hooks/use-log";
import { ColorEnum } from "./color-log.util";
import { UserApiFactory, sRSFeatureRequests } from '@the-mcorp/elevenfiftynine-srs-lib';
import { displayBackdrop, displaySnackbar } from 'src/store/slices/global';
import { RootState } from 'src/store';
import { AlertColor } from '@mui/material';

const loggoutRoute = `${process.env.REACT_APP_FRONTEND_CAIR_HUB_URL}${process.env.REACT_APP_AZURE_B2C_POST_LOGOUT_REDIRECT_ROUTE}`;

/**
 *
 * @param message
 * @param alertColor
 * @param duration
 */
const displaySnackbarMessage = (message: string, dispatch: any, alertColor?: AlertColor, duration?: number) => {
	dispatch(
		displaySnackbar(
			alertColor ? alertColor : 'success',
			message,
			{
				vertical: 'bottom',
				horizontal: 'center',
			},
			duration ? duration : 2500
		)
	);
};

/**
 * MSAL Logout
 */
const handleLogoutFromTimeout = async (dispatch: any) => {
	useLog(
		`Logging Out. Session timeout occurred. Logging out and redirecting to: ${loggoutRoute}`,
		ColorEnum.WARNING,
		appLogPrefixes.msalAuth
	);
	const userRequests = sRSFeatureRequests.user;

	dispatch(userRequests.fetchDelete_User_Logout<RootState>(UserApiFactory, { requestBody: null }, undefined, '', true));
	msalInstance.logoutRedirect({
		postLogoutRedirectUri: loggoutRoute,
	});
};

/**
 *
 */
const handleLogoutFromAuthError = async (dispatch: any) => {
	useLog(
		`Logging Out. Authentication error occured. Logging out and redirecting to: ${loggoutRoute}`,
		ColorEnum.ERROR,
		appLogPrefixes.msalAuth
	);

	const userRequests = sRSFeatureRequests.user;

	dispatch(userRequests.fetchDelete_User_Logout<RootState>(UserApiFactory, { requestBody: null }, undefined, '', true)),
		msalInstance.logoutRedirect({
			postLogoutRedirectUri: loggoutRoute,
		});
};

export const authUtils = { handleLogoutFromTimeout, handleLogoutFromAuthError };

