import { createSvgIcon } from '@mui/material/utils';

export const NoteIcon = createSvgIcon(
<svg width="24" height="24" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path d="M1.63227 17C1.30377 17 1.01633 16.877 0.769956 16.6309C0.523579 16.3848 0.400391 16.0977 0.400391 15.7696V3.46562C0.400391 3.13751 0.523579 2.85042 0.769956 2.60434C1.01633 2.35826 1.30377 2.23522 1.63227 2.23522H9.94749L8.71561 3.46562H1.63227V15.7696H13.9511V8.63329L15.183 7.40289V15.7696C15.183 16.0977 15.0598 16.3848 14.8134 16.6309C14.5671 16.877 14.2796 17 13.9511 17H1.63227ZM11.5284 2.39928L12.4113 3.26055L6.55981 9.08444V10.848H8.30498L14.177 4.98311L15.0393 5.84439L8.81826 12.0784H5.32793V8.59228L11.5284 2.39928ZM15.0393 5.84439L11.5284 2.39928L13.5815 0.348613C13.8142 0.116204 14.1051 0 14.4541 0C14.8032 0 15.094 0.12304 15.3267 0.369119L17.0514 2.11218C17.284 2.35826 17.4004 2.64877 17.4004 2.98372C17.4004 3.31866 17.2772 3.60233 17.0308 3.83474L15.0393 5.84439Z" />
</svg>,
'NoteIcon'
);




