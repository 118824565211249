// Generated with util/create-slice.js
import { reducer } from "./school-cohort-batch.reducer";
import * as effects from "./school-cohort-batch.effects";
import { createSelector } from "@reduxjs/toolkit";
 
import { sRSFeatureEntityAdapters } from '@the-mcorp/elevenfiftynine-srs-lib';
import { RootState } from "../..";

export { reducer, effects };

/***
 * Data Message selector for handling data success/failure
 */

const schoolCohortBatchMessageSelector = createSelector(
  [(state: RootState) => state.schoolCohortBatch],
  (stateSlice) => {
    return stateSlice.statusMessage;
  }
);

/***
 * API Selectors
 */

const schoolCohortSelectors = sRSFeatureEntityAdapters.schoolsDetail.school.getSelectors<
  RootState
>((state) => state.srsApi_SchoolsDetail.schoolInformationCohort);

const schoolDesignatedContactsSelectors = sRSFeatureEntityAdapters.schoolsDetail.school.getSelectors<RootState>(
	(state) => state.srsApi_SchoolsDetail.schoolInformationDesignatedContact
);

const schoolInformationSelectors = sRSFeatureEntityAdapters.schoolsDetail.school.getSelectors<
  RootState
>((state) => state.srsApi_SchoolsDetail.schoolInformation);


/**
 * Export all selectors
 */
export const schoolCohortBatchSelectors = {
  dataMessage: {
    schoolCohortBatchMessageSelector, 
  },
  data: {
    schoolCohortSelectors,
    schoolDesignatedContactsSelectors,
    schoolInformationSelectors,
  },
};
