//import { bxUtil } from 'binaryblox-react-core';

import { displaySnackbar, hideBackdrop } from 'src/store/slices/global/global-app.slice';

/**
 *
 * @param dispatch
 * @param statusMessage
 * @param setIsRequestFailure
 * @param waitInterval
 * @param consoleMessage
 * @param errorMessage
 */
const useServiceStatusHandler = (
	dispatch: any,
	statusMessage: string,

	successFunction?: Function,
	failureFunction?: Function,
	snackbarMessage: { showSuccessMessage: boolean; successMessage: string; failureMessage: string, showFailureMessage: boolean } = {
		showSuccessMessage: false,
		showFailureMessage: true,
		successMessage: 'Success',
		failureMessage: 'Failure',
	},
	waitInterval = 500,
	errorMessage = 'Service Failure'
): void => {
	const showSuccessSnackbar = snackbarMessage && snackbarMessage.showSuccessMessage;
	const showFailureSnackbar = snackbarMessage && snackbarMessage.showFailureMessage;
	if (statusMessage) {
		switch (statusMessage) {
			case 'success':
				dispatch(hideBackdrop(waitInterval));

				if (showSuccessSnackbar) {
					dispatch(
						displaySnackbar(
							'success',
							snackbarMessage.successMessage,
							{
								vertical: 'bottom',
								horizontal: 'center',
							},
							3000
						)
					);
				}

				successFunction && successFunction();
				break;
			case 'failure':
				console.log(errorMessage);
				dispatch(hideBackdrop(waitInterval));
				if (showFailureSnackbar) {
					dispatch(
						displaySnackbar(
							'error',
							snackbarMessage.failureMessage,
							{
								vertical: 'bottom',
								horizontal: 'center',
							},
							3000
						)
					);
				}
				failureFunction && failureFunction();
				break;
		}
	}
};

export default useServiceStatusHandler;
