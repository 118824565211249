import { createSvgIcon } from '@mui/material/utils';

export const FlagIcon = createSvgIcon(
<svg width="24" height="24" viewBox="0 0 18 18" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
<path fillRule="evenodd" clipRule="evenodd" d="M1.55652 0.674752C1.59736 0.669312 1.63904 0.666504 1.68137 0.666504H9.65143C10.4278 0.666504 11.0572 1.28833 11.0572 2.05539L11.0305 4.82877L11.0571 7.59774C11.0645 8.36477 10.4411 8.99248 9.66479 8.99977L2.14706 8.99983V16.6387C2.14706 17.0223 1.83432 17.3332 1.44853 17.3332C1.06274 17.3332 0.75 17.0223 0.75 16.6387V8.53687V1.59243V1.36095C0.75 0.977417 1.06274 0.666504 1.44853 0.666504C1.48526 0.666504 1.52132 0.669322 1.55652 0.674752ZM11.981 3.44428H15.373C16.0426 3.44428 16.5833 3.98431 16.5833 4.64799C16.5833 4.87597 16.5182 5.09934 16.3932 5.2957L14.8909 7.60659L16.2988 9.91755C16.6553 10.4773 16.49 11.219 15.9278 11.5761C15.7333 11.6996 15.5072 11.7653 15.2763 11.7653H5.9045C5.6457 11.7653 5.4359 11.5581 5.4359 11.3024V9.92575L9.67222 9.92567C10.9676 9.9135 12.0066 8.86732 11.9943 7.58894L11.9676 4.81996L11.981 3.44428Z" />
</svg>,
'FlagIcon'
);




