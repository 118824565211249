import { createSlice } from '@reduxjs/toolkit';

export const slice = createSlice({
	name: 'tempMarkers',
	initialState: {
		openInfoForm: null,
		openViewReport: null,
		schoolData: null,
	},
	reducers: {
		updateTempMarkers: (state, action) => {
			state.openInfoForm = action.payload.openInfoForm;
			state.openViewReport = action.payload.openViewReport;
			state.schoolData = action.payload.schoolData;
		},
	},
});

// Action creators are generated for each case reducer function
export const { updateTempMarkers } = slice.actions;

export default slice.reducer;
