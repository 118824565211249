import { AppThunk, RootState } from 'src/store';
import { actions } from './school-information.reducer';

import { sRSFeatureRequests, SchoolsDetailApiFactory } from '@the-mcorp/elevenfiftynine-srs-lib';
import { hooks } from '@the-mcorp/core-ui-lib';
import { STEP_STATUSES } from 'src/constants';
import { displayBackdrop, hideBackdrop } from '../global';
import { updateDesignatedContacts } from 'src/store/slices/school-assessment/school-info.slice';
import { useLog } from 'src/hooks/use-log';
import { ColorEnum } from 'src/utils/color-log.util';
import { appLogPrefixes } from 'src/config/app-config';

/**
 *
 * @param schoolCode
 * @param debug
 * @returns
 */
export const saveSchoolInformationBatch =
	(completeReportData, currentFormData, user, isSubmit, debug: boolean = false): AppThunk =>
	async (dispatch) => {
		try {
			console.log('Retrieving SchoolCohortBatch data...');

			const schoolRequests = sRSFeatureRequests.schoolsDetail;

			var designatedCalls = [];
				designatedCalls.push(
					dispatch(
						schoolRequests.fetchUpdate_SchoolInformation_Update<RootState>(
							SchoolsDetailApiFactory,
							{
								requestBody: {
									id: completeReportData.information.id,
									entity: {
										additionalEmail: completeReportData.information.additionalEmail,
									    memo: completeReportData.information.memo,
										updatedBy: user.email,
										status: isSubmit ? STEP_STATUSES.COMPLETED : STEP_STATUSES.IN_PROGRESS,
									},
								},
							},
							undefined,
							'',
							true
						)
					)
				);

				currentFormData.information?.designatedContacts?.forEach((element) => {
					if (completeReportData.information?.designatedContacts?.find((x) => x.id === element.id) === undefined) {
						dispatch(
							schoolRequests.fetchDelete_SchoolInformationDesignatedContact_Delete<RootState>(
								SchoolsDetailApiFactory,
								{
									requestBody: {
										id: element.id,
									},
								},
								undefined,
								'',
								true
							)
						);
					}
				});		

				completeReportData.information?.designatedContacts?.forEach((element) => {
					if (element.id === undefined) {
						element.schoolInformationId = completeReportData.information.id;
						element.createdBy = user.email;
						designatedCalls.push(
							dispatch(
								schoolRequests.fetchCreate_SchoolInformationDesignatedContact_Create<RootState>(
									SchoolsDetailApiFactory,
									{
										requestBody: {
											entity: element,
										},
									},
									undefined,
									'',
									true
								)
							)
						);
					} else {
						if (currentFormData.information?.designatedContacts?.find((x) => x.id === element.id) != element) {
							designatedCalls.push(
								dispatch(
									schoolRequests.fetchUpdate_SchoolInformationDesignatedContact_Update<RootState>(
										SchoolsDetailApiFactory,
										{
											requestBody: {
												id: element.id,
												entity: element,
											},
										},
										undefined,
										'',
										true
									)
								)
							);
						}
					}
				});

				dispatch(actions.saveSchoolInformationBatch());
				dispatch(displayBackdrop('Saving School Information', 'load'));
				
				try {
					const res = await Promise.all(designatedCalls)
						.then(async function(serviceErrors) {

							if(serviceErrors && serviceErrors.length > 0){
								useLog(`Service Errors: ${ JSON.stringify(serviceErrors, null, '\t')}`,ColorEnum.ERROR, appLogPrefixes.services);
		
								serviceErrors.forEach((valueOfResult) => {
									const resultType:string = valueOfResult.type; 
									if(resultType && resultType.toLowerCase().includes('fault')){  
										throw new Error('Service Error'); 
									}  
								});
							} 
		
							const payload = hooks.useRequestBuilder(
								'getAll',
								'post',
								<any>{ schoolCode: currentFormData.school.code },
								1,
								1000
							);
		
		
							const newData = await dispatch(
								schoolRequests.fetchGetAll_SchoolInformationDesignatedContact_GetSchoolInformationDesignatedContactBySchoolCode<RootState>(
									SchoolsDetailApiFactory,
									payload,
									undefined,
									'',
									true
								)
							);

							dispatch(updateDesignatedContacts(newData.payload.responseBody.data));

							if (isSubmit) {
								await dispatch(
									schoolRequests.fetchGetAll_SchoolInformation_GetSchoolInformationBySchoolCode<RootState>(
										SchoolsDetailApiFactory,
										{
											requestBody: {
												entity: { schoolCode: currentFormData.school.code },
											},
										},
										undefined,
										'',
										true
									)
								);
							}
		
							dispatch(actions.saveSchoolInformationBatchSuccess([]));
						})
						.catch((err) => { 
							throw err;  
						});
					 
				} catch (err) {
					throw err;   
				}
		} catch (err) { 
			dispatch(hideBackdrop());
			useLog(`Error saving School Cohort Batch -> ${err}`, ColorEnum.ERROR, appLogPrefixes.services); 
			dispatch(actions.saveSchoolInformationBatchFailure(err));
		}
	};
