import { useEffect, useState } from 'react';
import type { FC } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Divider,
	Stack,
} from '@mui/material';
import { title } from 'process';
import TimerIcon from '@mui/icons-material/Timer';
import { useLog } from 'src/hooks/use-log';
import { appLogPrefixes } from 'src/config/app-config';
import { ColorEnum } from 'src/utils/color-log.util';
import { useMounted } from 'src/hooks/use-mounted';
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { InteractionStatus } from '@azure/msal-browser';
import { timeUtils } from 'src/utils/time.utils';
import { browserStore as BrowserStore } from 'src';
import { BrowserStorageType } from 'src/utils/browser-storage.util';

const appTimeout = process.env.REACT_APP_TIMEOUT ? +process.env.REACT_APP_TIMEOUT.trim() : 900_000; // 15 minutes represented in milli-seconds: 15 * 60 * 1000
const appPromptBeforeIdle = process.env.REACT_APP_PROMPT_BEFORE_IDLE_TIMEOUT
	? +process.env.REACT_APP_PROMPT_BEFORE_IDLE_TIMEOUT.trim()
	: 300_000; // 10 minutes represented in milli-seconds: 5 * 60 * 1000
const enabled = process.env.REACT_APP_TIMEOUT_ENABLE_LOG === 'true';
/**
 * Retrieves the polling interval in milliseconds.
 * If a custom interval is provided, it will be returned.
 * If the custom interval is not valid or not provided, the default interval will be used.
 * The default interval is either the value specified in the environment variable REACT_APP_TIMEOUT_POLLING_INTERVAL,
 * or 5000 milliseconds (5 seconds) if the environment variable is not set or is not a valid number.
 * @param ms - The custom polling interval in milliseconds (optional).
 * @returns The polling interval in milliseconds.
 */
const getPollingInterval = (ms?: number) => {
	if (ms != undefined && ms > 0) {
		return ms;
	} else {
		if (isNaN(Number(process.env.REACT_APP_TIMEOUT_POLLING_INTERVAL))) {
			return 5000;
		} else {
			return Number(process.env.REACT_APP_TIMEOUT_POLLING_INTERVAL);
		}
	}
};
var timerPollingInterval = getPollingInterval();

/**
 *
 */
export interface TimeoutOverlayProps {
	handleLogout: () => void;
}

// String.prototype.toHHMMSS = function () {
//     var sec_num = parseInt(this, 10); // don't forget the second param
//     var hours   = Math.floor(sec_num / 3600);
//     var minutes = Math.floor((sec_num - (hours * 3600)) / 60);
//     var seconds = sec_num - (hours * 3600) - (minutes * 60);

//     if (hours   < 10) {hours   = "0"+hours;}
//     if (minutes < 10) {minutes = "0"+minutes;}
//     if (seconds < 10) {seconds = "0"+seconds;}
//     return hours+':'+minutes+':'+seconds;
// }

/**
 *
 * @param props
 * @returns
 */
export const TimeoutOverlay: FC<TimeoutOverlayProps> = (props) => {
	const [state, setState] = useState<string>('Active');
	const [open, setOpen] = useState<boolean>(false);
	const [remaining, setRemaining] = useState<string>('');
	const isMounted = useMounted();
	const { inProgress } = useMsal();
	const isAuthenticated = useIsAuthenticated();
	const idleTimeoutCookieKey = 'idleTracker';
	const cookieExpirationDays = 7;

	useEffect(() => {
		if (isMounted()) {
			if (!isAuthenticated && inProgress === InteractionStatus.Logout) {
				BrowserStore.removeItem(BrowserStorageType.COOKIE, idleTimeoutCookieKey);
			}

			const idleCookie = BrowserStore.getItem(BrowserStorageType.COOKIE, idleTimeoutCookieKey);
			if (idleCookie != null && new Date().valueOf() - idleCookie > appTimeout) {
				onIdle();
			}

			const interval = setInterval(() => {
				// const formattedRemainingTimeFromPrompt = new Date(Math.ceil(getRemainingTime())).toISOString().substr(15, 4); // refer this: https://stackoverflow.com/a/1322771
				const formattedRemainingTime = timeUtils.formatTime(getRemainingTime() / 1000);

				if (isAuthenticated && inProgress === InteractionStatus.None) {
					setRemaining(formattedRemainingTime);
					useLog(
						`Idler Timer: Remaining time in minutes [${formattedRemainingTime}].`,
						ColorEnum.INFO,
						appLogPrefixes.idleTimer,
						null,
						enabled
					);
					BrowserStore.setItem(
						BrowserStorageType.COOKIE,
						idleTimeoutCookieKey,
						new Date().valueOf(),
						cookieExpirationDays
					);
				}
			}, timerPollingInterval);

			return () => {
				clearInterval(interval);
			};
		}
	}, [isMounted, isAuthenticated, inProgress, timerPollingInterval]);

	/**
	 *
	 */
	const onIdle = () => {
		useLog(`Idler Timer: IDLE Event triggered...`, ColorEnum.WARNING, appLogPrefixes.idleTimer, null, enabled);
		setState('Idle');
		setOpen(false);
		if (isAuthenticated && inProgress === InteractionStatus.None) {
			props.handleLogout();
		}
	};

	/**
	 *
	 */
	const onActive = () => {
		if (isAuthenticated && inProgress === InteractionStatus.None) {
			BrowserStore.setItem(BrowserStorageType.COOKIE, idleTimeoutCookieKey, new Date().valueOf(), cookieExpirationDays);
			useLog(`Idler Timer: ACTIVE Event triggered...`, ColorEnum.INFO, appLogPrefixes.idleTimer, null, enabled);
			setState('Active');
			timerPollingInterval = getPollingInterval();
			setOpen(false);
		}
	};

	/**
	 *
	 */
	const onPrompt = () => {
		if (isAuthenticated && inProgress === InteractionStatus.None) {
			useLog(`Idler Timer: PROMPTED Event triggered...`, ColorEnum.WARNING, appLogPrefixes.idleTimer, null, enabled);
			setState('Prompted');
			timerPollingInterval = getPollingInterval(1000);
			setOpen(true);
		}
	};

	/**
	 *
	 */
	const handleContinueSession = () => {
		useLog(
			`Idler Timer: TIMEOUT CANCELLED: Session continued...`,
			ColorEnum.INFO,
			appLogPrefixes.idleTimer,
			null,
			enabled
		);
		if (isAuthenticated && inProgress === InteractionStatus.None) {
			activate();
		}
	};

	/**
	 *
	 */
	const handleClose = (event, reason) => {
		if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
			event.preventDefault();
			return;
		}
	};

	const { getRemainingTime, activate, getElapsedTime } = useIdleTimer({
		timeout: appTimeout,
		promptBeforeIdle: appPromptBeforeIdle,
		onIdle,
		onActive,
		onPrompt,
		throttle: 500,
	});

	const title: string = 'Inactive Session Reminder';

	return (
		<Dialog id='timeout-dialog' open={open} onClose={handleClose}>
			<DialogTitle id='timeout-dialog-title'>
				<Stack direction={'row'} sx={{ alignContent: 'end' }} gap={0.5}>
					<TimerIcon color={'primary'} sx={{ height: 28, width: 28 }} />
					{title}
				</Stack>
			</DialogTitle>
			<Divider sx={{ width: '100%' }}></Divider>
			<DialogContent id='timeout-dialog-content' sx={{ ml: 1, minWidth: 400, maxWidth: 500, pb: 0, pt: 0 }}>
				<DialogContentText id='timeout-dialog-content-text' sx={{ mt: 2 }}>
					Your session will end in: <b>{remaining ? remaining : '0:00'}</b>. Click <b>OK</b> to keep your session
					active.
				</DialogContentText>
			</DialogContent>
			<DialogActions id='timeout-dialog-actions' sx={{ width: '100%', align: 'center' }}>
				<Stack
					direction={'row'}
					sx={{ width: '100%', alignContent: 'end', justifyItems: 'end', justifyContent: 'end' }}
					gap={1}>
					<Button size='small' color='primary' variant='contained' onClick={handleContinueSession}>
						OK
					</Button>
				</Stack>
			</DialogActions>
		</Dialog>
	);
};

export default TimeoutOverlay;
