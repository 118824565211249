// Generated with util/create-slice.js
import { reducer } from './reporting-dashboard.reducer';
import * as effects from './reporting-dashboard.effects';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { sRSFeatureEntityAdapters } from '@the-mcorp/elevenfiftynine-srs-lib';

export { reducer, effects };

/***
 * Data Message selector for handling data success/failure
 */

const reportingDashboardMessageSelector = createSelector(
	[(state: RootState) => state.reportingDashboard],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

const immSubPerMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.immunizationSubmissionPercentageByHealthJurisdiction],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

const cumSubmissionPercentageMessageSelectors = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.cumulativeSubmissionPercentage],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

const schoolImmunizationRateMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.schoolImmunizationRate],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

const schoolCohortListMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.schoolCohortList],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

const reportingCategoriesPercentMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.reportingCategoriesPercent],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

const seventhGradeDataListMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.seventhGradeData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const seventhGradeSummaryMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.seventhGradeSummary],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const seventhGradeCountySummaryMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.seventhGradeCountySummaryData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const seventhGradeTDAPOverDueMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.seventhGradeTDAPOverDue],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const seventhGradeVaricellaOverDueMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.seventhGradeVaricellaOverDue],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const childCareDataMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.childCareData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const childCareOverDueDataMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.childCareOverDueData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const childCareSummaryMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.childCareSummary],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const childCareCountySummaryMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.childCareCountySummaryData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const kindergartenDataMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.kindergartenData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const kindergartenOverDueDataMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.kindergartenOverDueData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const kindergartenSummaryDataMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.kindergartenSummaryData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);
const kindergartenCountySummaryMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.kindergartenCountySummaryData],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

const dataDownloadDictionaryMessageSelector = createSelector(
	[(state: RootState) => state.srsApi_Dashboard.dataDownloadDictionary],
	(stateSlice) => {
		return stateSlice.statusMessage;
	}
);

/***
 * API Selectors
 */

const immSubPerSelectors =
	sRSFeatureEntityAdapters.dashboard.immunizationSubmissionPercentageByHealthJurisdiction.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.immunizationSubmissionPercentageByHealthJurisdiction
	);

const cumSubmissionPercentageSelectors =
	sRSFeatureEntityAdapters.dashboard.cumulativeSubmissionPercentage.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.cumulativeSubmissionPercentage
	);	

const schoolImmunizationRateSelectors =
	sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.schoolImmunizationRate
	);

const schoolCohortListSelectors = sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
	(state) => state.srsApi_Dashboard.schoolCohortList
);

const reportingCategoriesPercentSelector = sRSFeatureEntityAdapters.dashboard.reportingCategoriesPercent.getSelectors<RootState>(
	(state) => state.srsApi_Dashboard.reportingCategoriesPercent
);

const seventhGradeListSelectors = sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
	// (state) => state.srsApi_Dashboard.seventhEigthGradeData
	(state) => state.srsApi_Dashboard.seventhGradeData
);
const seventhGradeSummarySelectors = sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
	(state) => state.srsApi_Dashboard.seventhGradeSummary
);
const seventhGradeCountySummarySelectors =
	sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.seventhGradeCountySummaryData
	);
const seventhGradeTDAPOverDueSelectors =
	sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.seventhGradeTDAPOverDue
	);
const seventhGradeVaricellaOverDueSelectors =
	sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.seventhGradeVaricellaOverDue
	);
const childCareDataSelectors = sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
	(state) => state.srsApi_Dashboard.childCareData
);
const childCareOverDueDataSelectors = sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
	(state) => state.srsApi_Dashboard.childCareOverDueData
);
const childCareSummarySelectors = sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
	(state) => state.srsApi_Dashboard.childCareSummary
);
const childCareCountySummarySelectors =
	sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.childCareCountySummaryData
	);
const kindergartenDataSelectors = sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
	(state) => state.srsApi_Dashboard.kindergartenData
);
const kindergartenOverDueDataSelectors =
	sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.kindergartenOverDueData
	);
const kindergartenSummaryDataSelectors =
	sRSFeatureEntityAdapters.dashboard.schoolImmunizationRate.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.kindergartenSummaryData
	);
const kindergartenCountySummaryDataSelectors =
	sRSFeatureEntityAdapters.dashboard.kindergartenCountySummaryData.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.kindergartenCountySummaryData
	);

const dataDownloadDictionaryDataSelectors =
	sRSFeatureEntityAdapters.dashboard.kindergartenCountySummaryData.getSelectors<RootState>(
		(state) => state.srsApi_Dashboard.dataDownloadDictionary
	);

/**
 * Export all selectors
 */
export const reportingDashboardSelectors = {
	dataMessage: {
		reportingDashboardMessageSelector,
		immSubPerMessageSelector,
		cumSubmissionPercentageMessageSelectors,
		schoolImmunizationRateMessageSelector,
		schoolCohortListMessageSelector,
		reportingCategoriesPercentMessageSelector,
		seventhGradeDataListMessageSelector,
		seventhGradeSummaryMessageSelector,
		seventhGradeCountySummaryMessageSelector,
		seventhGradeTDAPOverDueMessageSelector,
		seventhGradeVaricellaOverDueMessageSelector,
		childCareDataMessageSelector,
		childCareOverDueDataMessageSelector,
		childCareSummaryMessageSelector,
		childCareCountySummaryMessageSelector,
		kindergartenDataMessageSelector,
		kindergartenOverDueDataMessageSelector,
		kindergartenSummaryDataMessageSelector,
		kindergartenCountySummaryMessageSelector,
		dataDownloadDictionaryMessageSelector,
	},
	data: {
		immSubPerSelectors,
		cumSubmissionPercentageSelectors,
		schoolImmunizationRateSelectors,
		schoolCohortListSelectors,
		reportingCategoriesPercentSelector,
		seventhGradeListSelectors,
		seventhGradeSummarySelectors,
		seventhGradeCountySummarySelectors,
		seventhGradeTDAPOverDueSelectors,
		seventhGradeVaricellaOverDueSelectors,
		childCareDataSelectors,
		childCareOverDueDataSelectors,
		childCareSummarySelectors,
		childCareCountySummarySelectors,
		kindergartenDataSelectors,
		kindergartenOverDueDataSelectors,
		kindergartenSummaryDataSelectors,
		kindergartenCountySummaryDataSelectors,
		dataDownloadDictionaryDataSelectors
	},
};
