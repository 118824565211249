/**
 * Google Tag Manager Config
 */
export const gtmConfig = {
  containerId: process.env.NEXT_PUBLIC_GTM_CONTAINER_ID,
};
 
/**
 * App API Endpoints
 */
export const apiConfig = {
  apiUrl: process.env.REACT_APP_BACKEND_API_URL,
};

/**
 * App Config values
 */
export const appConfig = {
  appTitle: "CAIR HUB SRS: California Immunization Registry",
  defaultPostLoginRoute: "/app/startup",
};

/**
 * 
 */
export const appLogPrefixes = {
	msalAuth: "🔒 MSAL_AUTH",
	startup: "🏄 STARTUP", 
  system: "🌎 SYSTEM", 
	userContext: "👤 USER_CONTEXT", 
  stepCompleted: "🎯 STEP_COMPLETED",
  charts: "📊 CHARTING",
  guard: "🛑 GUARD_VALIDATION",
  fatalError: "🚨 FATAL_ERROR",
  services: "🌀 SERVICES",
  idleTimer: "⏰ IDLE_TIMER", 
  unsupported: "🚫 UNSUPPORTED",
  browserStorage: "📦 BROWSER_STORAGE",
  validation: "🔍 VALIDATION",
}