// Generated with util/create-slice.js
import { reducer } from './school-cohort.reducer';
import * as effects from './school-cohort.effects';
import { createSelector } from '@reduxjs/toolkit';
import { RootState } from 'src/store';
import { sRSFeatureEntityAdapters } from '@the-mcorp/elevenfiftynine-srs-lib';

export { reducer, effects };

const schoolCohortSaveMessageSelector = createSelector([(state: RootState) => state.schoolCohortSave], (stateSlice) => {
	return stateSlice.statusMessage;
});

/**
 * Export all selectors
 */
export const schoolCohortSaveSelectors = {
	dataMessage: {
		schoolCohortSaveMessageSelector,
	},
	data: {},
};
