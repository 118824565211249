import { AppThunk, RootState } from 'src/store';
import { actions } from './user-school-assignment.reducer';

import {
	sRSFeatureRequests,
	DashboardApiFactory,
	SchoolsDetailApiFactory,
	SchoolInformationCohortViewModel,
	UserApiFactory,
} from '@the-mcorp/elevenfiftynine-srs-lib';
import { hooks } from '@the-mcorp/core-ui-lib';
import { apiUtils } from 'src/utils/api.utils';
import { STEP_STATUSES } from 'src/constants';
import { displayBackdrop, hideBackdrop } from '../global';

/**
 *
 * @param schoolCode
 * @param debug
 * @returns
 */
export const addUserSchoolsBatch =
	(schools: any[], email, debug: boolean = false): AppThunk =>
	async (dispatch) => {
		try {
			console.log('Adding Schools to user data...');
 
			const userRequests = sRSFeatureRequests.user;

			var calls = [];
			schools.forEach((element) => {
				calls.push(
					dispatch(
						userRequests.fetchCreate_UserSchool_AddSchoolByUserEmail<RootState>(
							UserApiFactory,
							{
								requestBody: { entity: { email: email, schoolId: element } },
							},
							undefined,
							'',
							false
						)
					)
				);
			});

			dispatch(actions.addUserSchoolBatch());
			dispatch(displayBackdrop(`Adding Selected schools to User ${email}...`, 'load'));
			try {
				const res = await Promise.all(calls);
				dispatch(actions.addUserSchoolsBatchSuccess([]));
			} catch (err) {
				dispatch(actions.addUserSchoolsBatchFailure(err));
				dispatch(hideBackdrop());
				throw Error('Promise failed');
			}
		} catch (err) {
			console.log(`SchoolCohortBatch ERROR ${err}`);
			dispatch(actions.addUserSchoolsBatchFailure(err));
		}
	};
