import yup from './yupUtil';
import {
	validateZeroEnrollmentReason,
	validateToBeLessThanOrEqualToFieldValue,
	validateToBeExactlyEqualToFieldValue,
	validateForIntValues,
	validateEnrolledField,
} from './commonFormValidation';

// schema declaration..
export const seventhGradeStepFormSchemaWithVaricelle = yup.object().shape({
	totalVaricelleCount: validateToBeExactlyEqualToFieldValue('enrolledStudentCount'),
	totalTdapCount: validateToBeExactlyEqualToFieldValue('enrolledStudentCount'),
	enrolledStudentCount: validateEnrolledField(),
	zeroEnrollmentReason: validateZeroEnrollmentReason('enrolledStudentCount'),
	reqMetVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	reqMetTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	permMedicalVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	permMedicalTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	indepStudyVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	indepStudyTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	homeBasedVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	homeBasedTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	condMissingVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	tempMedicalExempVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	tempMedicalExempTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	reqNotMetVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	reqNotMetTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	iepVaricelleCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	iepTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	metadata: yup.object().shape({
		submittedDate: yup.string(),
		revisedDate: yup.string()
	}),
});

export const seventhGradeStepFormSchema = yup.object().shape({
	
	totalTdapCount: validateToBeExactlyEqualToFieldValue('enrolledStudentCount'),
	enrolledStudentCount: validateEnrolledField(),
	zeroEnrollmentReason: validateZeroEnrollmentReason('enrolledStudentCount'),
	reqMetTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	permMedicalTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	indepStudyTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	homeBasedTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	tempMedicalExempTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	reqNotMetTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	iepTdapCount: validateToBeLessThanOrEqualToFieldValue('enrolledStudentCount'),
	metadata: yup.object().shape({
		submittedDate: yup.string(),
		revisedDate: yup.string()
	}),
});

export const seventhGradeStepIntValues = yup.object().shape({

	enrolledStudentCount: validateForIntValues(),
	zeroEnrollmentReason: validateZeroEnrollmentReason('enrolledStudentCount'),
	reqMetVaricelleCount: validateForIntValues(),
	reqMetTdapCount: validateForIntValues(),
	permMedicalVaricelleCount: validateForIntValues(),
	permMedicalTdapCount: validateForIntValues(),
	indepStudyVaricelleCount: validateForIntValues(),
	indepStudyTdapCount: validateForIntValues(),
	homeBasedVaricelleCount: validateForIntValues(),
	homeBasedTdapCount: validateForIntValues(),
	condMissingVaricelleCount: validateForIntValues(),
	tempMedicalExempVaricelleCount: validateForIntValues(),
	tempMedicalExempTdapCount: validateForIntValues(),
	reqNotMetVaricelleCount: validateForIntValues(),
	reqNotMetTdapCount: validateForIntValues(),
	iepVaricelleCount: validateForIntValues(),
	iepTdapCount: validateForIntValues(),
});

// form initialization..
export const seventhGradeStepFormModelWithVaricelle = {
	totalVaricelleCount: 0,
	totalTdapCount: 0,
	enrolledStudentCount: '',
	zeroEnrollmentReason: '',
	reqMetVaricelleCount: 0,
	reqMetTdapCount: 0,
	permMedicalVaricelleCount: 0,
	permMedicalTdapCount: 0,
	iepVaricelleCount: 0,
	iepTdapCount: 0,
	indepStudyVaricelleCount: 0,
	indepStudyTdapCount: 0,
	homeBasedVaricelleCount: 0,
	homeBasedTdapCount: 0,
	condMissingVaricelleCount: 0,
	tempMedicalExempVaricelleCount: 0,
	tempMedicalExempTdapCount: 0,
	reqNotMetVaricelleCount: 0,
	reqNotMetTdapCount: 0,
	metadata: {
		submittedDate: "",
		revisedDate: ""
	}
};

export const seventhGradeStepFormModel = {
	totalTdapCount: 0,
	enrolledStudentCount: '',
	zeroEnrollmentReason: '',
	reqMetTdapCount: 0,
	permMedicalTdapCount: 0,
	iepTdapCount: 0,
	indepStudyTdapCount: 0,
	homeBasedTdapCount: 0,
	tempMedicalExempTdapCount: 0,
	reqNotMetTdapCount: 0,
	metadata: {
		submittedDate: "",
		revisedDate: ""
	}
};
