import * as React from "react";
import { ConfirmationOptions } from "./confirmation-dialog.types";
import ConfirmationDialog from "./confirmation-dialog";
import { confirmationServiceContext as  ConfirmationServiceContext} from "src";

/**
 *
 */
export const useConfirmationDialog = () =>
  React.useContext(ConfirmationServiceContext);
 
/**
 *
 * @param param0
 */
export const ConfirmationDialogServiceProvider = ({ children }) => {
  const [ 
    confirmationState,
    setConfirmationState,
  ] = React.useState<ConfirmationOptions | null>(null);

  const awaitingPromiseRef = React.useRef<{
    resolve: () => void;
    reject: () => void;
  }>();
 

  /**
   *
   * @param options
   */
  const openConfirmation = (options: ConfirmationOptions) => {
    setConfirmationState(options);
    return new Promise<void>((resolve, reject) => {
      awaitingPromiseRef.current = { resolve, reject };
    });
  };

  /**
   *
   */
  const handleClose = () => {
    if (confirmationState.catchOnCancel && awaitingPromiseRef.current) {
      awaitingPromiseRef.current.reject();
    }

    setConfirmationState(null);
  };

  /**
   *
   */
  const handleSubmit = () => {
    if (awaitingPromiseRef.current) {
      awaitingPromiseRef.current.resolve();
    }

    setConfirmationState(null);
  };

  return (
    <>
      <ConfirmationServiceContext.Provider
        value={openConfirmation}
        children={children}
      />

      <ConfirmationDialog 
        open={Boolean(confirmationState)}
        onSubmit={handleSubmit}
        onClose={handleClose}
        {...confirmationState}
      />
    </>
  );
};
