import { RouteObject } from 'react-router'; 
import { Suspense, lazy } from 'react'; 
import AuthGuard from 'src/components/guards/auth-guard';
import { Outlet } from 'react-router-dom';
import DefaultMainLayout from 'src/layouts/default-main-layout';
import { SplashScreen } from 'src/components/splash-screen';

const Loadable = (Component: any) => (props: any) =>
	(
		<Suspense fallback={<SplashScreen />}>
			<Component {...props} />
		</Suspense>
	);

// Pages
const StartupPage = Loadable(lazy(() => import('../pages/startup'))); 

export const startupRoutes: RouteObject[] = [
	{
		path: 'app',
		children: [
			{
				path: 'startup',
				element: (
					<AuthGuard>
						<DefaultMainLayout>
							<Outlet />
						</DefaultMainLayout>
					</AuthGuard>
				),
				children: [
					{
						path: '',
						element: <StartupPage />,
					}  
				],
			},
		],
	},
];
