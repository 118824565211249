/**
 * 
 */
export const errorPageFontSize = {
	mobileTitle: '80px',  
	desktopTitle: '140px',  
    mobileSubtitle: '28px',  
	desktopSubtitle: '42px',  
	  
};

/**
 * Defines the style constants used in the application.
 */
export const styleConstants = {
	typography: {
		color: {
			text:{
				secondary: '#344563',
			}
		},
	},
	background: {
		color: {
			white: {
				primary: '#FFFFFF',
				secondary: '#FAFAFA',
			} 
		},
	},
	icon: {
		color: {
			primary: '#334564',
			white: '#FAFAFA',
		},
	},
};
